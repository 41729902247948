import { TypeTransactionEnum } from "enums/BanqueEnums";
import { client, clientStripe } from "./client";

export const createSession = async (
  price: number,
  devise: string,
  name: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post("/create-checkout-session", {
      price: price,
      devise: devise,
      name: name,
      commandeId: commandeId,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createPaiementItentRechargement = async (
  user: string,
  montantSaisi: number,
  detail: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post(
      "/api/stripe/create/payement-intent/rechargement/",
      {
        user: user,
        motif: TypeTransactionEnum.RECHARGEMENT,
        montantSaisi: montantSaisi,
        detail: detail,
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

// paiement commande app
export const createPaiementItentPaiementCommandeAPI = async (
  commande: string,
  montantSaisi: number,
  detail: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post(
      "/api/stripe/create/payement-intent/paiement/commande",
      {
        commande: commande,
        motif: TypeTransactionEnum.PAYEMENT_COMMANDE,
        montantSaisi: montantSaisi,
        detail: detail,
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createPaiementItentPaiementCommandeDesktopAPI = async (
  commande: string,
  montantSaisi: number,
  detail: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post(
      "/api/stripe/create/payement-intent/paiement/commande/desktop",
      {
        commande: commande,
        motif: TypeTransactionEnum.PAYEMENT_COMMANDE_DESKTOP,
        montantSaisi: montantSaisi,
        detail: detail,
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createPaiementItentPaiementFraisLitigeAPI = async (
  user: string,
  commande: string,
  motif: string,
  montantSaisi: number | undefined
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post(
      "/api/stripe/create/payement-intent/paiement/frais-litige",
      {
        userId: user,
        commandeId: commande,
        motif: motif,
        montantSaisi: montantSaisi,
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createPaiementItentPaiementCommissionCommandeAPI = async (
  commande: string,
  montantSaisi: number
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post(
      "/api/stripe/create/payement-intent/paiement/commission/commande",
      {
        commande: commande,
        motif: TypeTransactionEnum.PAYEMENT_COMMISSION,
        montantSaisi: montantSaisi,
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createPaiementItentEnvoiAPI = async (
  user: string,
  beneficiaire: string,
  motif: string,
  montantSaisi: number | undefined,
  detail: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post(
      "/api/stripe/create/payement-intent/envoi",
      {
        userId: user,
        beneficiaireId: beneficiaire,
        motif: motif,
        montantSaisi: montantSaisi,
        detail: detail,
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const retrievePaymentAPI = async (
  paiementItentId: string
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.get(
      "/api/stripe/retrieve/payement-intent/" + paiementItentId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentAPI = async (
  session_id: string,
  commandeId: string,
  data?: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/stripe/" + session_id + "/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentFraisAnnulationParStripeAPI = async (
  session_id: string,
  commandeId: string,
  data: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/fraisannulation/stripe/" + session_id + "/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentCommissionParStripeAPI = async (
  session_id: string,
  commandeId: string,
  data: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/commission/stripe/" + session_id + "/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentCommandeParStripeAPI = async (
  session_id: string,
  commandeId: string,
  data: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/stripe/" + session_id + "/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentFraisAbsenceParStripeAPI = async (
  session_id: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/fraisabsence/stripe/" + session_id + "/" + commandeId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentFraisAbandonParStripeAPI = async (
  session_id: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/fraisabandon/stripe/" + session_id + "/" + commandeId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const cancelPaymentAPI = async (
  session_id: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/stripe/cancel/" + session_id + "/" + commandeId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const envoiArgentBeneficiaireParCBAPI = async (
  beneficiaireId: string,
  data: any
) => {
  let err = "";

  try {
    const response = await client.post(
      "/stripe/envoie/" + beneficiaireId,
      data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};
