export default function Loader2() {
  return (
    <div className="absolute right-0 top-0 left-0 z-20 flex items-center bg-gray-200 opacity-50 w-full justify-center h-full bottom-0 ">
      <div className="preloader w-full h-full bg-white">
        <div className="preloader-container w-20 h-20 m-auto relative ">
          <span className="animated-preloader"></span>
        </div>
      </div>
    </div>
  );
}
