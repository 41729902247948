import { signUpData } from "api";
import i18n from "i18n";
import { REGEX } from "utils";

const registerValidate = (values: signUpData) => {
  const errors: any = {
    pays: "",
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
    gender: "",
    password: "",
    password_confirmation: "",
  };
  // const {t} = useTranslation()

  if (!values.firstname) {
    errors.firstname = i18n.t("register.firstnameRequired");
  } else if (values.firstname.length < 2) {
    errors.firstname = i18n.t("register.invalidFirstname");
  }

  if (!values.lastname) {
    errors.lastname = i18n.t("register.nameRequired");
  } else if (values.lastname.length < 2) {
    errors.lastname = i18n.t("register.invalidName");
  }

  if (!values.email) {
    errors.email = i18n.t("register.emailRequired");
  } else if (values.email.search(REGEX.EMAIL_REGEXP) === -1) {
    errors.email = i18n.t("register.invalidEmail");
  }

  if (!values.phone) {
    errors.phone = i18n.t("register.phoneRequired");
  } else if (values.phone.length > 15) {
    errors.phone = i18n.t("register.phoneError");
  }

  if (!values.password) {
    errors.password = i18n.t("register.passwordRequired");
  } else if (values.password.length < 8) {
    errors.password = i18n.t("register.invalidPassword");
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = i18n.t("register.confirmPasswordRequired");
  } else if (values.password_confirmation !== values.password) {
    errors.password_confirmation = i18n.t("register.invalidConfirmPassword");
  }

  return errors;
};

export default registerValidate;
