import { ReactNode } from "react";

type Props = {
  title: string;
  children?: ReactNode;
  value?: string;
  noBorder?: boolean;
};
const VehiculeDetailItem = ({
  title,
  children,
  value,
  noBorder = false,
}: Props) => {
  return (
    <div className="grid grid-cols-2 gap-4 text-xs">
      <p className="text-dark-belee font-medium col-span-1 flex bg-gray-300 rounded-lg h-10 text-center justify-center items-center">
        {title}
      </p>

      <div
        className={`col-span-1 flex font-medium ${
          noBorder ? "" : "bg-gray-300 rounded-lg h-10"
        }  justify-center items-center relative text-dark-belee`}
      >
        {value} {children}
      </div>
    </div>
  );
};

export default VehiculeDetailItem;
