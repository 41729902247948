const LineIcon = () => {
  return (
    <svg
      width="18"
      height="3"
      viewBox="0 0 18 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0515625 1.5C0.0515625 1.94183 0.409735 2.3 0.851562 2.3C1.29339 2.3 1.65156 1.94183 1.65156 1.5C1.65156 1.05817 1.29339 0.7 0.851562 0.7C0.409735 0.7 0.0515625 1.05817 0.0515625 1.5ZM16.9576 1.60607C17.0162 1.54749 17.0162 1.45251 16.9576 1.39393L16.003 0.43934C15.9445 0.380761 15.8495 0.380761 15.7909 0.43934C15.7323 0.497918 15.7323 0.592893 15.7909 0.651472L16.6394 1.5L15.7909 2.34853C15.7323 2.40711 15.7323 2.50208 15.7909 2.56066C15.8495 2.61924 15.9445 2.61924 16.003 2.56066L16.9576 1.60607ZM0.851562 1.65H1.35156V1.35H0.851562V1.65ZM2.35156 1.65H3.35156V1.35H2.35156V1.65ZM4.35156 1.65H5.35156V1.35H4.35156V1.65ZM6.35156 1.65H7.35156V1.35H6.35156V1.65ZM8.35156 1.65H9.35156V1.35H8.35156V1.65ZM10.3516 1.65H11.3516V1.35H10.3516V1.65ZM12.3516 1.65H13.3516V1.35H12.3516V1.65ZM14.3516 1.65H15.3516V1.35H14.3516V1.65ZM16.3516 1.65H16.8516V1.35H16.3516V1.65Z"
        fill="white"
      />
    </svg>
  );
};

export default LineIcon;
