import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { CommandeRoutes } from "./CommandeRoutes";

const FeatureCommande = {
  CommandePage: React.lazy(() => import("pages/Commande/views/CommandePage")),
  CommandeDetailsPage: React.lazy(
    () => import("pages/Commande/views/CommandeDetailsPage")
  ),
  CommandeAnnulePage: React.lazy(
    () => import("pages/Commande/views/CommandeAnnuler")
  ),
  CommandeAnnuleReservantPage: React.lazy(
    () => import("pages/Commande/views/CommandeAnnulerReservant")
  ),
  CommandeRembourserPage: React.lazy(
    () => import("pages/Commande/views/CommandeRembourser")
  ),
  CommandeNoteUserAndVehicule: React.lazy(
    () => import("pages/Commande/views/CommandeNoteUserAndVehicule")
  ),
  CommandeFacturePage: React.lazy(
    () => import("pages/Commande/views/FactureCommandePage")
  ),
  DeclarerLitigePage: React.lazy(
    () => import("pages/Commande/views/DeclarerLitigePage")
  ),
  DetailRetraitPage: React.lazy(
    () => import("pages/Commande/views/DetailRetraitPage")
  ),
  DemandeurSurPlacePage: React.lazy(
    () => import("pages/Commande/views/DemandeurSurPlacePage")
  ),
};

export const CommandeAppRoutes = () => (
  <Routes>
    <Route key={"CommandesRoutes"} element={<RequireAuth />}>
      <Route
        path={CommandeRoutes.toCommande()}
        element={<FeatureCommande.CommandePage />}
      />
      <Route
        path={CommandeRoutes.toCommandeDetal()}
        element={<FeatureCommande.CommandeDetailsPage />}
      />
      <Route
        path={CommandeRoutes.toNoteUserAndVehicule()}
        element={<FeatureCommande.CommandeNoteUserAndVehicule />}
      />
      <Route
        path={CommandeRoutes.toCommandeAnnule()}
        element={<FeatureCommande.CommandeAnnulePage />}
      />
      <Route
        path={CommandeRoutes.toCommandeAnnulerReservant()}
        element={<FeatureCommande.CommandeAnnuleReservantPage />}
      />
      <Route
        path={CommandeRoutes.toCommandeRembourser()}
        element={<FeatureCommande.CommandeRembourserPage />}
      />
      <Route
        path={CommandeRoutes.toCommandeFacture()}
        element={<FeatureCommande.CommandeFacturePage />}
      />
      <Route
        path={CommandeRoutes.toDeclarerLitige()}
        element={<FeatureCommande.DeclarerLitigePage />}
      />
      <Route
        path={CommandeRoutes.toDetailRetrait()}
        element={<FeatureCommande.DetailRetraitPage />}
      />
      <Route
        path={CommandeRoutes.toDemandeurSurPlace()}
        element={<FeatureCommande.DemandeurSurPlacePage />}
      />
    </Route>
  </Routes>
);
