import { AccueilRoutes } from "../AccueilRoutes";

export const modeDiffusions = [
  {
    id: "tous_prestataire",
    text: "Tous les prestataires Belee",
    to: "",
  },
  {
    id: "favoris",
    text: "Favoris uniquement",
    to: "",
  },
  // {
  //   id: "whatsapp",
  //   text: "Whatsapp",
  //   to: AccueilRoutes.toChoiceGroupes(),
  // },
  // {
  //   id: "telegram",
  //   text: "Telegram",
  //   to: AccueilRoutes.toChoiceGroupes(),
  // },
];
