export const PaymentRoutes = {
  toStripeSuccessPage: (sessionId?: string, commandeId?: string): string =>
    sessionId && commandeId
      ? "/payment/stripe/success/" + sessionId + "/" + commandeId
      : "/payment/stripe/success/:sessionId/:commandeId",
  toStripeCBSuccessPage: (): string => "/payment/cb/success/",
  toStripeCancelPage: (): string => "/payment/stripe/cancel",
  toChoiceModePayment: (commandeId?: string): string =>
    commandeId ? "/payment/" + commandeId : "/payment/:commandeId",
  toPaymentParSolde: (commandeId?: string): string =>
    commandeId
      ? "/payment/" + commandeId + "/solde"
      : "/payment/:commandeId/solde",
  toPaymentParCarte: (commandeId?: string): string =>
    commandeId
      ? "/payment/" + commandeId + "/carte"
      : "/payment/:commandeId/carte",
  toPaymentParPaypal: (commandeId?: string): string =>
    commandeId
      ? "/payment/" + commandeId + "/paypal"
      : "/payment/:commandeId/paypal",
  toChoiceModePaymentCommission: (commandeId?: string): string =>
    commandeId
      ? "/payment-commission/" + commandeId
      : "/payment-commission/:commandeId",
  toChoiceModePaymentFraisAbandon: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais-abandon/" + commandeId
      : "/payment/frais-abandon/:commandeId",
  toChoiceModePaymentFraisAbsence: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais-absence/" + commandeId
      : "/payment/frais-absence/:commandeId",
  toChoiceModePaymentFraisAnulation: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais-annulation/" + commandeId
      : "/payment/frais-annulation/:commandeId",
  toPaiementCommissionAvecSolde: (commandeId?: string): string =>
    commandeId
      ? "/payment/commission/" + commandeId
      : "/payment/commission/:commandeId",
  toPaiementFraisAnnulationAvecSolde: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais/annulation/solde/" + commandeId
      : "/payment/frais/annulation/solde/:commandeId",
  toPaiementFraisAbsenceAvecSolde: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais/absence/solde/" + commandeId
      : "/payment/frais/absence/solde/:commandeId",

  toPaiementCommissionParCarte: (commandeId?: string): string =>
    commandeId
      ? "/payment/commission/cb/" + commandeId
      : "/payment/commission/cb/:commandeId",
  toPaiementFraisAnnulationParCarte: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais-annulation/cb/" + commandeId
      : "/payment/frais-annulation/cb/:commandeId",
  toPaiementFraisAbsenceParCarte: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais-absence/cb/" + commandeId
      : "/payment/frais-absence/cb/:commandeId",
  toPaiementFraisAbandonParCarte: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais-abandon/cb/" + commandeId
      : "/payment/frais-abandon/cb/:commandeId",
  toPaiementFraisAbadonAvecSolde: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais/abandon/solde/" + commandeId
      : "/payment/frais/abandon/solde/:commandeId",
  toPayFraisAbandon: (commandeId?: string): string =>
    commandeId
      ? "/payment/frais-abandon/solde/" + commandeId
      : "/payment/frais-abandon/solde/:commandeId",
};
