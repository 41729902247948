export const AccountRoutes = {
  toHome: (): string => "/belee",
  toLogin: (): string => "/login",
  toEntrepriseValidated: (): string => "/entreprise/validated",
  toInfoEntrepriseSaved: (): string =>
    "/register/entreprise-enregistrement/info", // page de redirection apres enregistrement entreprise
  toChoiceAccount: (): string => "/choice-account",
  toRegister: (accountType?: string): string =>
    accountType ? "/register/" + accountType : "/register/:accountType",
  toRegisterUser: (): string => "/register/user",
  toInfoEntreprise: (idUser?: string): string =>
    idUser
      ? "/register/info-entreprise/" + idUser
      : "/register/info-entreprise/", // creation d'entreprise
  toUpdateEntreprise: (idEntreprise?: string): string =>
    idEntreprise
      ? "/register/update-entreprise/" + idEntreprise
      : "/register/update-entreprise/", // entreprise creer ou en attente de validation
  toVerify: (idValidation?: string): string =>
    idValidation ? "/verify/" + idValidation : "/verify/:idValidation",
  toCreateEntreprise: (): string => "/creer/entreprise",
  toResendValidation: (idValidation?: string): string =>
    idValidation
      ? "/resend/verify/new/" + idValidation
      : "/resend/verify/new/:idValidation",
  toSuccess: (): string => "/success",
  toResetPassword: (): string => "/reset-password",
  toVerifyResetPass: (idValidation?: string): string =>
    idValidation
      ? "/reset-password/verify/" + idValidation
      : "/reset-password/verify/:idValidation",
  toNewPassword: (idUser?: string): string =>
    idUser ? "/reset-password/new/" + idUser : "/reset-password/new/:idUser",
  toNewPassSuccess: (): string => "/reset-password/success",
  toGuideInstallationAndroid: (): string => "/installation/android",
  toGuideInstallationIPhonePage: (): string => "/installation/ios",
  toConfirmerInvitation: (id?: string): string =>
    id ? "/account/confirm/" + id : "/account/confirm/:id", // tentative d'inscription via mail
  toConfirmationInvitation: (entreprise?: string, role?: string): string =>
    // id ? "/account/confirm/" + id : "/account/confirm/:id",
    entreprise
      ? "/account/confirm/invitation/" + entreprise + "/" + role
      : "/account/confirm/invitation/:entreprise/:role", // tentative d'inscription via qr code
  toConfirmAccount: (id?: string): string =>
    id ? "/confirm/" + id : "/confirm/:id",
  toNotificationActivationInvitation: (id?: string): string =>
    id
      ? "/account/notification/activation/" + id
      : "/account/notification/activation/:id",
  toNotification: (type?: string, idNotification?: string): string =>
    type && idNotification
      ? "/notification/" + type + "/" + idNotification
      : "/notification/:type/:idNotification",
};
