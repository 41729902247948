import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { InterfaceBlobalRoutes } from "./InterfaceBlobalRoutes";

const FeatureInterfaceGlobal = {
  LanguePage: React.lazy(() => import("pages/LanguePage")),
  SupportPage: React.lazy(() => import("pages/SupportPage")),
};

export const InterfaceGlobalAppRoutes = () => (
  <Routes>
    <Route key={"interfaceGlobaleRoutes"} element={<RequireAuth />}>
      <Route
        path={InterfaceBlobalRoutes.toLangue()}
        element={<FeatureInterfaceGlobal.LanguePage />}
      />
      <Route
        path={InterfaceBlobalRoutes.toSupport()}
        element={<FeatureInterfaceGlobal.SupportPage />}
      />
    </Route>
  </Routes>
);
