import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  closeMenu: (open: boolean) => void;
};

const MenuLayout = ({ children, isOpen, closeMenu }: Props) => {
  return (
    <div className="bg-white max-h-screen overflow-auto">
      {/* Mobile menu */}
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          // className="fixed inset-0 flex z-40 lg:hidden"
          className="fixed inset-0 flex z-40"
          onClose={closeMenu}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={`w-[360px] z-10 min-h-screen bg-home text-[#6d6d6d] bg-opacity-[0.96] relavite  shadow overflow-hidden overflow-y-auto scrollbar-thin`}
            >
              {children}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default MenuLayout;
