export const printProfils = ["super_admin", "admin", "prestataire"];

export const EnumToStringArray = [
  {
    key: "VALIDE",
    valeur: "Validé",
  },
  {
    key: "EN_ATTENTE",
    valeur: "En attente",
  },
  {
    key: "REFUSE",
    valeur: "Refusé",
  },
  {
    key: "CREE",
    valeur: "créé",
  },
  {
    key: "BLOQUE",
    valeur: "Bloqué",
  },
];
