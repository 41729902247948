import { ReactNode } from "react";

type Props = {
  title: string;
  horraire?: string;
  icon: ReactNode;
  lieu: string;
  address: string;
  date?: string;
};
const CmdHorraireItem = ({
  title,
  horraire,
  icon,
  lieu,
  address,
  date,
}: Props) => {
  return (
    <div className="flex flex-col">
      <p className="font-semibold text-sm">{title}</p>
      <div className="grid grid-cols-12 gap-2">
        <div
          className={`col-span-7 py-1 grid bg-white grid-cols-12 items-center px-2`}
        >
          <div className="col-span-1">{icon}</div>
          <div className="col-span-11 p-2">
            <p className="text-xs text-dark-belee">{address}</p>
          </div>
        </div>
        <div className="col-span-3 p-2 flex justify-center items-center bg-white text-xs text-dark-belee">
          {typeof date === "string" ? date : ""}
        </div>
        <div className="col-span-2 p-2 flex justify-center items-center bg-white text-xs text-dark-belee">
          {horraire}
        </div>
      </div>
    </div>
    // <div className="flex flex-col">
    //   <p className="font-semibold text-sm">{title}</p>
    //   <div className="grid grid-cols-12 gap-2">
    //     <div
    //       className={`${
    //         horraire ? `col-span-9` : `col-span-12`
    //       } border  py-3 border-[#3A3A3A] bg-[#121212] rounded-lg grid grid-cols-12 gap-4 items-center px-2`}
    //     >
    //       {icon}
    //       <div className="flex col-span-7 text-sm flex-col">
    //         <p className="text-xs">{address}</p>
    //       </div>
    //       <div className="flex col-span-4 text-sm flex-col">
    //         <p className="text-xs">{date}</p>
    //       </div>
    //     </div>
    //     {horraire ? (
    //       <div className="col-span-3 border border-[#3A3A3A] text-sm bg-[#121212] rounded-lg flex justify-center items-center">
    //         {horraire}
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //   </div>
    // </div>
  );
};
export default CmdHorraireItem;
