/* eslint-disable react-hooks/exhaustive-deps */
import {
  getAllGammeAPI,
  getUserVehiculeApi,
  getVehiculeCreatedByUserAPI,
} from "api";
import { GammeType, VehiculeType } from "interfaces";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";

const getValidateVehicule = (vehicules?: VehiculeType[]) => {
  let actives = [];
  if (vehicules) {
    for (const vehicule of vehicules) {
      if (vehicule.etat === "VALIDE") {
        actives.push(vehicule);
      }
    }
  }
  return actives;
};

export const useVehicules = () => {
  const { user } = useAuth();
  const [monVehicule, setMonVehicule] = useState<VehiculeType>();
  const [vehicules, setVehicules] = useState<VehiculeType[]>();
  const [activeVehicules, setActiveVehicules] = useState<VehiculeType[]>();
  const [activeCount, setActiveCount] = useState<number>(0);
  const [gammes, setGammes] = useState<GammeType[]>();
  const [gamme, setGamme] = useState<GammeType>();
  useEffect(() => {
    const getUserVehicule = async () => {
      if (user) {
        const responses = await getVehiculeCreatedByUserAPI(user._id);
        if (typeof responses !== "string") {
          setVehicules(vehicules);
          setActiveVehicules(getValidateVehicule(responses));
          if (getValidateVehicule(responses).length > 1) {
            setActiveCount(getValidateVehicule(responses).length - 1);
          }
        }
      }
    };
    getUserVehicule();
  }, []);

  useEffect(() => {
    const getVehicule = async () => {
      if (user) {
        const response = await getUserVehiculeApi(user._id);
        if (typeof response !== "string" && response._id !== undefined) {
          setMonVehicule(response);
        }
      }
    };
    getVehicule();
  }, []);

  useEffect(() => {
    const getGammes = async () => {
      const response = await getAllGammeAPI();
      if (typeof response !== "string") {
        setGammes(response);
        for (const gamme of response) {
          if (gamme._id === monVehicule?.gamme) {
            setGamme(gamme);
          }
        }
      }else{
        if (response == "No token provided!") {
          window.location.href = "/belee"
        }
        
      }
    };
    getGammes();
  }, [monVehicule]);

  return {
    monVehicule,
    vehicules,
    activeVehicules,
    activeCount,
    gammes,
    gamme,
  };
};
