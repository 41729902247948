import devise from "pages/Banques/utils/deviseSymbols";

export const calculFraisTransfert = (
  montant: number,
  frais: number,
  frais_plus: number,
  valeurConversion?: number
): number => {
  let frais_per = Number(frais) / 100;
  let frais_transfert = Number(montant) * frais_per;
  if (valeurConversion) {
    let frais_plus_converti = Number(frais_plus) * valeurConversion;
    frais_transfert = frais_transfert + frais_plus_converti;
  } else {
    frais_transfert = frais_transfert + Number(frais_plus);
  }
  return frais_transfert;
};

export const getDeviseSymbole = (code: string): string => {
  const symbole = devise.find((d) => d.code === code);

  return symbole ? (symbole.symbol_native as string) : "";
};

export const getDeviseCodeByName = (name: string): string => {
  const symbole = devise.find((d) => d.name === name);

  return symbole ? (symbole.code as string) : "";
};

export const getDeviseCodeBySymbole = (symbol: string): string => {
  const symbole = devise.find((d) => d.symbol_native === symbol);

  return symbole ? (symbole.code as string) : "";
};
