import { client } from "./client";

export const getAllFactureUserAPI = async (): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/facture/liste");
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDetailsFactureAPI = async (
  factureId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/facture/details/" + factureId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};
