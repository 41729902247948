import { ReactNode } from "react";

type Props = {
  title?: string;
  detail?: any;
  align?: string;
};
const FactureItem = ({ align, title, detail }: Props) => {
  return (
    <div className={`py-1 flex items-end justify-${align ? align : "start"}`}>
      <div className="mr-2 text-center">
        <p className="text-sm text-orange-belee font-semibold">{title} : </p>
      </div>
      <div className={``}>
        <p className="text-sm">{detail}</p>
      </div>
    </div>
  );
};
export default FactureItem;
