const MaestroIcon = () => {
  return (
    <svg
      width="33"
      height="20"
      viewBox="0 0 33 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4738 9.84994C19.4738 15.2899 15.1144 19.6999 9.7369 19.6999C4.35936 19.6999 0 15.2899 0 9.84994C0 4.40997 4.35936 0 9.7369 0C15.1144 0 19.4738 4.40997 19.4738 9.84994Z"
        fill="#ED0006"
      />
      <path
        d="M32.1066 9.84994C32.1066 15.2899 27.7473 19.6999 22.3697 19.6999C16.9922 19.6999 12.6328 15.2899 12.6328 9.84994C12.6328 4.40997 16.9922 0 22.3697 0C27.7473 0 32.1066 4.40997 32.1066 9.84994Z"
        fill="#0099DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0538 17.3473C18.1474 15.5406 19.4748 12.8523 19.4748 9.85038C19.4748 6.84845 18.1474 4.16018 16.0538 2.35352C13.9603 4.16018 12.6328 6.84845 12.6328 9.85038C12.6328 12.8523 13.9603 15.5406 16.0538 17.3473Z"
        fill="#6C6BBD"
      />
    </svg>
  );
};

export default MaestroIcon;
