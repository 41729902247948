const MastercardIcon = () => {
  return (
    <svg
      width="33"
      height="20"
      viewBox="0 0 33 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0547 17.3472C14.3542 18.8143 12.1485 19.7 9.73824 19.7C4.35996 19.7 0 15.29 0 9.85C0 4.41 4.35996 0 9.73824 0C12.1485 0 14.3542 0.88566 16.0547 2.35278C17.7551 0.88566 19.9608 0 22.3711 0C27.7493 0 32.1093 4.41 32.1093 9.85C32.1093 15.29 27.7493 19.7 22.3711 19.7C19.9608 19.7 17.7551 18.8143 16.0547 17.3472Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0547 17.3472C18.1487 15.5405 19.4765 12.8521 19.4765 9.85C19.4765 6.84788 18.1487 4.15945 16.0547 2.35278C17.7551 0.88566 19.9609 0 22.3711 0C27.7494 0 32.1093 4.41 32.1093 9.85C32.1093 15.29 27.7494 19.7 22.3711 19.7C19.9609 19.7 17.7551 18.8143 16.0547 17.3472Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0566 17.348C18.1506 15.5413 19.4784 12.8529 19.4784 9.85073C19.4784 6.84862 18.1506 4.16019 16.0566 2.35352C13.9626 4.16019 12.6348 6.84862 12.6348 9.85073C12.6348 12.8529 13.9626 15.5413 16.0566 17.348Z"
        fill="#FF5E00"
      />
    </svg>
  );
};

export default MastercardIcon;
