/* eslint-disable react-hooks/exhaustive-deps */
import { getDeviseConversionAPI, getParametreAPI } from "api";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { Devise, UserTypeEnum } from "enums";
import useLocalStorage from "./useLocalStorage";
import { co } from "@fullcalendar/core/internal-common";
import { useParams } from "react-router-dom";
import { EnumToStringArray } from "utils";
import devise from "pages/Banques/utils/deviseSymbols";
import { calculFraisTransfert } from "helpers";
import { useWallet } from "./useWallet";

export const useTransfert = (
  montantTotalTransfert: number,
  deviseUser?: string,
  deviseBenef?: string
) => {
  const { user } = useAuth();
  const { modePaiement } = useParams();
  const [commission, setCommission] = useState<number>(0);
  const [parametre, setParametre] = useState<any>();
  const [commissionPlafond, setCommissionPlafond] = useState<number>(0);
  const [commissionGeneralPlafond, setCommissionGeneralPlafond] =
    useState<number>(0);
  const [commissionGeneral, setCommissionGeneral] = useState<number>(0);
  const [commissionBelee, setCommissionBelee] = useState<number>(0);
  const [deviseEnvoi, setDevice] = useState<string>("");
  const [TTCMax, setTTCMax] = useState<number>(0);
  const [TVABelee, setTVABelee] = useState<number>(0);
  const [TVA, setTVA] = useState<number>(0);
  const [commissionPer, setCommissionPer] = useState<number>(0);
  const [commissionPlafondPer, setCommissionPlafondPer] = useState<number>(0);
  const [commissionGeneralPer, setGeneralCommissionPer] = useState<number>(0);
  const [error, setError] = useState<string>();
  const [montantBeneficiaire, setMontantBeneficiaire] = useState<number>(0);
  const [montantRecuperable, setMontantRecuperable] = useState<number>(0);
  const [fraisTransfert, setFraisTransfert] = useState<number>(0);
  const [fraisTransfertVersBenef, setFraisTransfertVersBenef] =
    useState<number>(0);
  const [montantEnvoyer, setMontantEnvoyer] = useState<number>(0);
  const [commissionFraisBelee, setCommissionFraisBelee] = useState<number>(0);
  const [deviseCoversion, setDeviseCoversion] = useState<number>(0);
  const [soldeInitial, setSoldeInitial] = useState<number>(0);
  const [soldeApres, setSoldeApres] = useState<number>(0);
  const [decouvert, setDecouvert] = useState(0);
  const { balance, balanceCFA } = useWallet();

  useEffect(() => {
    if (montantTotalTransfert) {
      const getParametre = async () => {
        const response = await getParametreAPI("France");
        if (typeof response !== "string") {
          setError(undefined);
          setParametre(response);

          if (deviseUser === Devise.EUR) {
            setDevice(response.tva.devise_symbole);
            setCommissionPer(
              Number(
                response.commissions_frais_belee_cash.commission_par_defaut
              )
            );
            setCommissionPlafondPer(
              Number(response.commissions_frais_belee_cash.commission_plafond)
            );
            setGeneralCommissionPer(
              Number(response.commissions_frais_belee_cash.commission_belee)
            );
            setCommissionGeneralPlafond(
              Number(
                response.commissions_frais_belee_cash.commission_belee_plafond
              )
            );

            setTVABelee(Number(response.tva.tva_belee));
            setTTCMax(Number(response.ttc_max));
          } else {
            let devise_envoi;
            let devise_benef;
            if (!deviseBenef) {
              // c'est une demande de retrait
              devise_envoi = Devise.EUR;
              devise_benef = deviseUser;
            } else {
              // c'est un envoi
              devise_envoi = deviseUser;
              devise_benef = deviseBenef;
            }

            let data = {
              envoi: devise_envoi,
              reception: devise_benef,
            };
            const conversion = await getDeviseConversionAPI(data);
            if (typeof conversion !== "string") {
              let valeurConversion = Number(conversion.valeur);
              setDeviseCoversion(valeurConversion);
              setDevice("CFA");
              // Commission point belee
              setCommissionPer(
                Number(
                  response.commissions_frais_belee_cash.commission_par_defaut
                )
              );
              // setCommissionPlafondPer(
              //   Number(
              //     response.commissions_frais_belee_cash.commission_plafond
              //   ) * valeurConversion
              // );
              // Commission général
              setGeneralCommissionPer(
                Number(response.commissions_frais_belee_cash.commission_belee)
              );
              setCommissionGeneralPlafond(
                Number(
                  response.commissions_frais_belee_cash.commission_belee_plafond
                ) * valeurConversion
              );

              setTVABelee(Number(response.tva.tva_belee));
              setTTCMax(Number(response.ttc_max));
            }
          }
        } else {
          setError(response);
          return;
        }
      };
      getParametre();
      getAllPrice(montantTotalTransfert);
    }
  }, [montantTotalTransfert]);

  const getAllPrice = (totalPrice: number) => {
    // let commission_plafond = (commissionPlafondPer / 100) * totalPrice;
    let commission_general =
      (commissionGeneralPer / 100) * totalPrice < commissionGeneralPlafond
        ? (commissionGeneralPer / 100) * totalPrice
        : commissionGeneralPlafond;
    let commission = (commissionPer / 100) * commission_general;
    let commission_belee = commission_general - commission;
    let TVA = (totalPrice * Number(TVABelee)) / 100;

    setCommission(Number(commission.toFixed(2)));
    setCommissionBelee(Number(commission_belee.toFixed(2)));
    // setCommissionPlafond(commission_plafond);
    setCommissionGeneral(Number(commission_general.toFixed(2)));
    setTVA(TVA);
  };

  useEffect(() => {
    const getPriceItemEnvoi = async () => {
      if (user && montantTotalTransfert && parametre) {
        let frais_bancaire = 0;
        let frais_interne = 0;
        let frais_paypal = 0;
        let frais_virement = 0;
        let montantBeneficiaire = 0;
        let transfertFrais = 0;
        let typePayment = modePaiement;
        let frais_transfert_vers_benef = 0;
        if (deviseUser === Devise.EUR) {
          if (typePayment === "paypal") {
            frais_paypal = calculFraisTransfert(
              Number(montantTotalTransfert),
              Number(
                parametre.commissions_frais_belee_cash.frais_transfert_paypal
              ),
              Number(
                parametre.commissions_frais_belee_cash
                  .frais_transfert_paypal_plus
              )
            );

            montantBeneficiaire = Number(montantTotalTransfert) - frais_paypal;
            transfertFrais = Number(frais_paypal.toFixed(2));
            setFraisTransfert(transfertFrais);
          } else if (typePayment === "carte_bancaire") {
            frais_bancaire = calculFraisTransfert(
              Number(montantTotalTransfert),
              Number(parametre.commissions_frais_belee_cash.frais_bancaire_per),
              Number(parametre.commissions_frais_belee_cash.frais_bancaire_plus)
            );

            montantBeneficiaire =
              Number(montantTotalTransfert) - frais_bancaire;
            transfertFrais = Number(frais_bancaire.toFixed(2));
            setFraisTransfert(transfertFrais);
          } else if (typePayment === "solde") {
            // frais_interne = calculFraisTransfert(
            //   Number(montantTotalTransfert),
            //   Number(
            //     parametre.commissions_frais_belee_cash.frais_transaction_interne
            //   ),
            //   Number(
            //     parametre.commissions_frais_belee_cash
            //       .frais_transaction_interne_plus
            //   )
            // );

            montantBeneficiaire = Number(montantTotalTransfert) - frais_interne;
            transfertFrais = Number(frais_interne.toFixed(2));
            setFraisTransfert(transfertFrais);
          } else if (typePayment === "virement") {
            frais_virement = calculFraisTransfert(
              Number(montantTotalTransfert),
              Number(parametre.commissions_frais_belee_cash.frais_virement),
              Number(parametre.commissions_frais_belee_cash.frais_virement_plus)
            );

            montantBeneficiaire =
              Number(montantTotalTransfert) - frais_virement;
            transfertFrais = Number(frais_virement.toFixed(2));
            setFraisTransfert(transfertFrais);
          }
          frais_transfert_vers_benef = calculFraisTransfert(
            montantBeneficiaire,
            Number(
              parametre.commissions_frais_belee_cash.frais_transaction_interne
            ),
            Number(
              parametre.commissions_frais_belee_cash
                .frais_transaction_interne_plus
            )
          );
        } else {
          let data;
          if (deviseBenef === deviseUser) {
            data = {
              envoi: Devise.EUR,
              reception: deviseUser?.replaceAll(" ", "_"),
            };
          } else {
            data = {
              envoi: deviseBenef?.replaceAll(" ", "_"),
              reception: deviseUser?.replaceAll(" ", "_"),
            };
          }
          const conversion = await getDeviseConversionAPI(data);
          if (typeof conversion !== "string") {
            let valeurConversion = Number(conversion.valeur);
            if (typePayment === "paypal") {
              frais_paypal = calculFraisTransfert(
                Number(montantTotalTransfert),
                Number(
                  parametre.commissions_frais_belee_cash.frais_transfert_paypal
                ),
                Number(
                  parametre.commissions_frais_belee_cash
                    .frais_transfert_paypal_plus
                ),
                valeurConversion
              );

              montantBeneficiaire =
                Number(montantTotalTransfert) - frais_paypal;
              transfertFrais = Number(frais_paypal.toFixed(2));
              setFraisTransfert(transfertFrais);
            } else if (typePayment === "carte_bancaire") {
              frais_bancaire = calculFraisTransfert(
                Number(montantTotalTransfert),
                Number(
                  parametre.commissions_frais_belee_cash.frais_bancaire_per
                ),
                Number(
                  parametre.commissions_frais_belee_cash.frais_bancaire_plus
                ),
                valeurConversion
              );

              montantBeneficiaire =
                Number(montantTotalTransfert) - frais_bancaire;
              transfertFrais = Number(frais_bancaire.toFixed(2));
              setFraisTransfert(transfertFrais);
            } else if (typePayment === "solde") {
              // frais_interne = calculFraisTransfert(
              //   Number(montantTotalTransfert),
              //   Number(
              //     parametre.commissions_frais_belee_cash
              //       .frais_transaction_interne
              //   ),
              //   Number(
              //     parametre.commissions_frais_belee_cash
              //       .frais_transaction_interne_plus
              //   ),
              //   valeurConversion
              // );

              montantBeneficiaire =
                Number(montantTotalTransfert) - frais_interne;
              transfertFrais = Number(frais_interne.toFixed(2));
              setFraisTransfert(transfertFrais);
            } else if (typePayment === "virement") {
              frais_virement = calculFraisTransfert(
                Number(montantTotalTransfert),
                Number(parametre.commissions_frais_belee_cash.frais_virement),
                Number(
                  parametre.commissions_frais_belee_cash.frais_virement_plus
                ),
                valeurConversion
              );

              montantBeneficiaire =
                Number(montantTotalTransfert) - frais_virement;
              transfertFrais = Number(frais_virement.toFixed(2));
              setFraisTransfert(transfertFrais);
            }
            frais_transfert_vers_benef = calculFraisTransfert(
              montantBeneficiaire,
              Number(
                parametre.commissions_frais_belee_cash.frais_transaction_interne
              ),
              Number(
                parametre.commissions_frais_belee_cash
                  .frais_transaction_interne_plus
              )
            );
          }
        }
        setMontantEnvoyer(montantBeneficiaire);
        montantBeneficiaire = montantBeneficiaire - frais_transfert_vers_benef;
        setFraisTransfertVersBenef(frais_transfert_vers_benef);
        setMontantBeneficiaire(Number(montantBeneficiaire.toFixed(2)));
      }
    };
    getPriceItemEnvoi();
  }, [montantTotalTransfert, parametre]);

  useEffect(() => {
    if (
      montantTotalTransfert &&
      commissionGeneral &&
      commissionBelee &&
      commission
    ) {
      let montant_recuperable =
        Number(montantTotalTransfert) - commissionGeneral;
      if (deviseUser === Devise.EUR) {
        setMontantRecuperable(Number(montant_recuperable.toFixed(2)));
      } else {
        setMontantRecuperable(Number(montant_recuperable.toFixed(0)));
      }
    }
  }, [montantTotalTransfert, commissionGeneral, commissionBelee, commission]);

  useEffect(() => {
    if (fraisTransfert && commissionGeneral) {
      let commissionEtFrais = Number(commissionGeneral) + fraisTransfert;
      setCommissionFraisBelee(commissionEtFrais);
    }
  }, [fraisTransfert, commissionGeneral]);

  useEffect(() => {
    if (balance && balanceCFA) {
      if (deviseUser === Devise.EUR) {
        setSoldeInitial(balance);
        setSoldeApres(balance);
      } else if (deviseUser === Devise.XOF) {
        setSoldeApres(Number(Number(balanceCFA).toFixed(0)));
        setSoldeInitial(Number(Number(balanceCFA).toFixed(0)));
      }
    }
  }, [balance, balanceCFA]);

  useEffect(() => {
    if (soldeInitial && soldeApres && montantTotalTransfert) {
      let new_solde = Number(soldeInitial) - Number(montantTotalTransfert);
      setSoldeApres(Number(new_solde.toFixed(2)));
      if (soldeApres && soldeApres < 0) {
        setDecouvert(new_solde);
        setSoldeApres(0);
      }
    }
  }, [soldeInitial, soldeApres, montantTotalTransfert]);

  useEffect(() => {
    if (montantTotalTransfert === 0) {
      setCommission(0);
      setCommissionGeneral(0);
      setMontantRecuperable(0);
      setCommissionFraisBelee(0);
      setMontantBeneficiaire(0);
      setMontantEnvoyer(0);
      setFraisTransfert(0);
      setFraisTransfertVersBenef(0);
      setSoldeApres(soldeInitial);
      setDecouvert(0);
    }
  }, [montantTotalTransfert]);

  return {
    deviseEnvoi,
    commission,
    commissionPlafond,
    commissionGeneral,
    commissionBelee,
    montantRecuperable,
    soldeInitial,
    soldeApres,
    decouvert,
    TVA,
    error,
    setError,
    montantBeneficiaire,
    montantEnvoyer,
    fraisTransfert,
    fraisTransfertVersBenef,
    commissionFraisBelee,
  };
};
