/* eslint-disable react-hooks/exhaustive-deps */
import { AlertKind } from "components";
import Alert from "components/elements/alerts/Alert";
import BackButton from "components/elements/buttons/BackButton";
import AppLayout from "components/layouts/AppLayout";
import { ReactNode, useEffect, useState } from "react";
import { Check, Info } from "react-feather";
import { tabs } from "../utils";
import FooterContent from "components/layouts/FooterContent";
import Modal from "components/elements/modals/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { AccountRoutes } from "../AccountRoutes";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line no-sparse-arrays
let pos = 0;
type Props = {
  title: string;
  subTitle: string | ReactNode;
  setCode: (codes: string[]) => void;
  error?: string;
  isSuccess?: boolean;
  isLoading?: boolean;
  action?: string;
  data?: any;
  setError?: (error: any) => void;
  resendCode?: () => void;
};
const ResetContent = ({
  title,
  subTitle,
  setCode,
  error,
  setError,
  isSuccess,
  isLoading,
  resendCode,
  action,
  data,
}: Props) => {
  const initValues = ["", "", "", ""];
  const [values, setValues] = useState<any>(initValues);
  const [position, setPosition] = useState<number>(0);
  const [isShow, setIsShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const { idValidation } = useParams();
  const { t } = useTranslation();

  const handleTab = (tab: any) => {
    if (tab.id === 12) {
      setValues(initValues);
      setPosition(0);
      if (setError) setError(undefined);
    } else {
      if (position > 3) {
        pos = 0;
        setValues(initValues);
        setPosition(pos);
      } else {
        let newValues = values;
        newValues[position] = tab.text;
        setValues(newValues);
        pos = position + 1;
        setPosition(pos);
      }
    }
    setCode(values);
  };

  useEffect(() => {
    if (error) {
      setValues(initValues);
      setPosition(0);
      return;
    }
    if (isSuccess) setValues(initValues);
  }, [isSuccess, error]);

  const handleKeyPress = (event: any) => {
    if (event.key >= "0" && event.key <= "9") {
      console.log("Touche tapée sur le pavé numérique :", event.key);

      if (position > 3) {
        pos = 0;
        setValues(initValues);
        setPosition(pos);
      } else {
        let newValues = values;
        newValues[position] = event.key;
        setValues(newValues);
        pos = position + 1;
        setPosition(pos);
      }
      setCode(values);
    }
  };

  const handleModifier = async () => {
    navigate(AccountRoutes.toResendValidation(idValidation));
  };

  return (
    <AppLayout bgColor="bg-home font-sans">
      <div className="bg-opacity-80 bg-white lg:mt-36 md:mt-20 mt-28 lg:px-14 md:px-14 px-5 lg:py-12 md:py-8 py-5 lg:w-[600px] md:w-[600px] lg:mx-auto md:mx-auto mx-3 shadow-xl rounded-3xl ">
        <h1 className="text-center text-xl font-medium  text-orange-belee">
          {title}
        </h1>

        <p className="lg:text-base text-center text-sm text-gray-500 font-light lg:mb-8 mt-2">
          {subTitle}
          <br />

          {action && action === "inscription" && (
            <ul className="list-disc text-sm pl-3 space-y-2">
              <li>
                {t("validationCode.codeSendByEmail")}{" "}
                <span className="font-medium">'{data?.email}'</span>{" "}
                {t("validationCode.orOnWhatsapp")}{" "}
                <span className="font-medium">'{data?.whatsapp}'</span>
              </li>

              <li>{t("validationCode.codeNotReceived")}</li>
              <li>
                {t("validationCode.ifCaseError")}
                <span
                  className="text-red-500 cursor-pointer font-medium"
                  onClick={handleModifier}
                >
                  {" "}
                  {t("validationCode.edit")}
                </span>
              </li>
            </ul>
          )}
        </p>

        <div className="mt-5">
          {isSuccess && <Alert kind={AlertKind.success}>{isSuccess}</Alert>}
          {error && (
            <div className="flex justify-center items-center space-x-4 text-red-500 pb-6">
              <Info />
              <p className="text-sm center">{error}</p>
            </div>
          )}
        </div>

        <div
          className="w-full flex justify-center items-center outline-none"
          tabIndex={0}
          onKeyDown={handleKeyPress}
        >
          <div className="grid grid-cols-4 gap-3">
            {values.map((value: any, index: number) => (
              <div
                key={index}
                className={`w-12 h-12 text-orange-belee border-2 border-orange-belee rounded-full flex justify-center items-center  text-2xl ${
                  index === position ? "border border-green-belee" : ""
                }`}
              >
                {value}
              </div>
            ))}
          </div>
        </div>

        <div className="bg-orange-belee py-3 px-5 rounded-3xl border border-gray-500 grid grid-cols-3 gap-6 my-3 lg:hidden md:hidden block">
          {tabs.map((tab, index: number) => (
            <div
              key={index}
              className={`flex justify-center items-center text-xl cursor-pointer w-14 h-14 rounded-full mx-auto text-black border border-gray-500 ${
                tab.id === 10 ? `border-none` : ""
              }  ${tab.id === 12 ? `border-none` : ""}`}
              onClick={() => handleTab(tab)}
            >
              {tab && tab.id === 10 ? "" : tab.text}
            </div>
          ))}
        </div>
        <p
          className="text-center text-orange-belee font-medium cursor-pointer text-sm my-3 hover:underline"
          onClick={resendCode}
        >
          {t("validationCode.askNewCode")}
        </p>
      </div>
      {/* <div className="hidden lg:block md:hidden text-black">
        <FooterContent></FooterContent>
      </div> */}
    </AppLayout>
  );
};

export default ResetContent;
