export const CompteRoutes = {
  toProfile: (): string => "/compte/profile",
  toModifierProfile: (): string => "/compte/profile/edit",
  toModifierAdresse: (): string => "/compte/profile/edit/address",
  toInfoEntreprise: (): string => "/compte/entreprise/create",
  toMesService: (): string => "/compte/service",
  toMoyenEncaissement: (): string => "/compte/moyen-encaissement",
  toActiverService: (type_service?: string): string =>
    type_service
      ? "/compte/service/" + type_service
      : "/compte/service/:type_service",
  toActiverServiceBeleeCash: (): string => "/activer/service/belee-cash",
  toServiceBeleeCashAddedSucess: (): string => "/service/belee-cash/added",
  toCompteNotValide: (): string => "/compte/to-validate",
  toPlaning: (): string => "/compte/planing",
};
