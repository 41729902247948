import { useEffect, useState, Fragment } from "react";
import {
  Navbar,
  Typography,
  IconButton,
  Collapse,
} from "@material-tailwind/react";
import Button from "components/elements/buttons/Button";

import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import MenuItem from "components/elements/menus/MenuItem";
import { MenuType } from "interfaces";
import { menus } from "utils";
import { X } from "react-feather";
import { menuNavList } from "../utils/navList";
import { serviceItem } from "../utils/ServiceItem";
import { AccueilRoutes } from "../AccueilRoutes";
import { useAuth } from "hooks";

type Props = {
  onclick?: React.MouseEventHandler<HTMLButtonElement>;
  addClass?: any;
};

const MenuNavBar = ({ addClass, onclick }: Props) => {
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();
  const [updatedMenu, setUpdatedMenu] = useState(menuNavList);
  const location = useLocation();
  const [menu, setMenu] = useState<MenuType[]>(menus);
  const [activeMenu, setActiveMenu] = useState<string>("");
  const { logout, user } = useAuth();

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  useEffect(() => {
    const updatedMenuNavList = menuNavList.map((item: any) => ({
      ...item,
      isActive: item.link === location.pathname,
    }));

    setUpdatedMenu(updatedMenuNavList);
    const menuActif = updatedMenuNavList.find(
      (item: any) => item.isActive === true
    );
    setActiveMenu(menuActif?.name);
  }, [location.pathname]);

  useEffect(() => {
    if (activeMenu === "Services") {
      const targetSection = document.getElementById("service-belee");
      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop,
          behavior: "smooth",
        });
      }
      setActiveMenu("");
    }
  });

  const handlelogout = async () => {
    logout();
  };

  return (
    <Navbar
      placeholder={""}
      className={
        "cursor-default mx-auto rounded-none w-full bg-gray-300 bg-opacity-80 shadow-none md:shadow-lg lg:shadow-lg xl:shadow-lg lg:h-[80px] md:h-[60px] xl:px-12 lg:px-12 md:px-10 px-5 py-1 lg:py-2 " +
        addClass
      }
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <div className=" mx-auto h-[50px] flex items-center justify-between lg:mt-2 md:mt-0">
        <img
          src="/belee-logo-transparent.png"
          className="cursor-pointer lg:w-[150px] md:w-[100px] w-[100px] mb-3"
          onClick={() => navigate(AccueilRoutes.toHomePage())}
          alt=""
        />

        <Typography
          placeholder={""}
          as="a"
          href="#"
          className="flex items-center"
        >
          <div className="pl-5 hidden lg:block">
            <ul className="mb-4 mt-2 flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center ">
              {updatedMenu.map((navList: any) => (
                <Typography
                  placeholder={""}
                  key={navList.id}
                  as="li"
                  variant="small"
                  className={`menu-item font-semibold text-[#000] mr-6 cursor-pointer hover:text-orange-belee transition duration-100 ease-in-out ${
                    navList.isActive ? "text-orange-belee" : ""
                  }`}
                  onClick={() => {
                    navigate(navList.link);
                  }}
                >
                  {navList.name}
                </Typography>
              ))}
            </ul>
          </div>
        </Typography>

        <Typography
          placeholder={""}
          as="a"
          href="#"
          className="flex justify-between xl:ml-0 lg:ml-0 md:ml-32 items-center cursor-default"
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="flex items-center">
            <p className="text-black font-medium mr-2">LANGUES</p>
            <img
              src="/images/icons/Icone langue FR.png"
              className="cursor-pointer flag-icon mr-1"
              alt="flag"
            />
            <img
              src="/images/icons/Icone langue ESP.png"
              className="cursor-pointer flag-icon mr-1"
              alt="flag"
            />
            <img
              src="/images/icons/Icone langue ENG.png"
              className="cursor-pointer flag-icon"
              alt="flag"
            />

            {user && (
              <button
                className="button-animate ml-3 hidden md:block lg:block xl:block font-medium rounded-full bg-[#FA9124] text-white py-2 px-2 shadow-xl hover:shadow-none"
                onClick={handlelogout}
              >
                Se deconnecter
              </button>
            )}
          </div>
        </Typography>

        <IconButton
          placeholder={""}
          variant="text"
          className="flex justify-center items-center border-2 border-orange-belee px-7 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden text-orange-belee"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {openNav ? (
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6 "
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          ) : (
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </div>
          )}
        </IconButton>
      </div>

      <Collapse open={openNav}>
        <div
          className={`${
            openNav
              ? "absolute bg-gray-300 bg-opacity-75 left-0 top-0 right-16 w-full mt-[58px]"
              : ""
          }  mx-auto`}
        >
          <ul className="mx-4 mb-4 mt-2 flexflex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            {updatedMenu.map((navList: any) => (
              <Typography
                placeholder={""}
                key={navList.id}
                as="li"
                variant="small"
                className={`p-1 text-sm text-gray-600 font-medium  cursor-pointer ${
                  navList.isActive === true ? "text-orange-belee" : ""
                }`}
                onClick={() => {
                  window.location.href = navList.link;
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {navList.name}
              </Typography>
            ))}
          </ul>
        </div>
      </Collapse>
    </Navbar>
  );
};

export default MenuNavBar;
