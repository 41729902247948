import StartIcon from "../icons/StartIcon";

const Rating = () => {
  return (
    <div className="flex space-x-1 mt-0.5">
      {[1, 2, 3, 4, 5].map((item) => (
        <StartIcon key={item} />
      ))}
    </div>
  );
};

export default Rating;
