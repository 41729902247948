export const VehiculeRoutes = {
  toVehicule: (): string => "/vehicule",
  toAddVehicule: (type_action?: string): string =>
    type_action
      ? "/vehicule/choice/" + type_action
      : "/vehicule/choice/:type_action",
  toAddActionUsageVehicule: (): string => "/vehicule/action",
  toSuccessVehicule: (): string => "/vehicule/add/success",
  toAddNewVehicule: (type_usage?: string): string =>
    type_usage ? "/vehicule/add/" + type_usage : "/vehicule/add/:type_usage",
  toUpdateVehicule: (id?: string): string =>
    id ? "/vehicule/update/" + id : "/vehicule/update/:id",
  toVehiculeDisponible: (): string => "/vehicule/disponible",
  toUsagerVehicule: (id?: string): string =>
    id ? "/vehicule/usager/" + id : "/vehicule/usager/:id",
  toAttribuerVehicule: (id?: string): string =>
    id ? "/vehicule/usager/add/" + id : "/vehicule/usager/add/:id",
};
