export const commandeEtat = [
  { id: "EN_COURS", value: "En cours" },
  { id: "A_VERIFIER", value: "A vérifier" },
  { id: "TERMINE", value: "Terminé" },
];

export const mode_payement = [
  { id: "SOLDE", value: "Solde" },
  { id: "PAYPAL", value: "Paypal" },
  { id: "CARTE_BANCAIRE", value: "Carte bancaire" },
  { id: "PAYEMENT_PRESTATAIRE", value: "Paiement au prestataire" },
  { id: "PAB_CARTE", value: "Paiement à bord par carte" },
  { id: "PAB_ESPECE", value: "Paiement à bord par espèce" },
  { id: "PAB_NON_PRECISE", value: "Paiement à bord non précisé" },
  {
    id: "DIFFERES_VIREMENT_FIN_COURSE",
    value: "Paiement par virement en fin course",
  },
  { id: "DIFFERES_VIREMENT_UN_JOUR", value: "Paiement par virement sous 24h" },
  {
    id: "DIFFERES_VIREMENT_DEUX_JOUR",
    value: "Paiement par virement sous 48h",
  },
  {
    id: "DIFFERES_VIREMENT_DIX_JOUR",
    value: "Paiement par virement sous 10 jours",
  },
  {
    id: "DIFFERES_PAYPAL_FIN_COURSE",
    value: "Paiement par paypal en fin de course",
  },
  { id: "DIFFERES_PAYPAL_UN_JOUR", value: "Paiement par paypal sous 24h" },
  { id: "DIFFERES_PAYPAL_DEUX_JOUR", value: "Paiement par paypal sous 48h" },
  {
    id: "DIFFERES_PAYPAL_DIX_JOUR",
    value: "Paiement par paypal sous 10 jours",
  },
  { id: "DIFFERES_PAY_LINK", value: "Lien de paiement" },
];
