import { refreshAuthApi, signIn, signInPhone, SignupDataInt } from "api";
import { UserTypeEnum } from "enums";
import { deleteCookie, getCookie, isValidateUser } from "helpers/auth-helpers";
import { EntrepriseType, User } from "interfaces";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type AuthContext = {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  isAdmin: boolean;
  isPro: boolean;
  signupData: SignupDataInt | null;
  authEntreprise: EntrepriseType | undefined;
  userEntreprise: EntrepriseType | undefined;
  login: (credentials: {
    email: string;
    password: string;
  }) => Promise<any | User>;
  logout: () => void;
  refreshAuth: () => void;
};

const authContext = createContext({
  user: {},
} as AuthContext);
const { Provider } = authContext;

export function AuthProvider(props: { children: ReactNode }): JSX.Element {
  const auth = useAuthProvider();
  return <Provider value={auth}>{props.children}</Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

const useAuthProvider = () => {
  const [user, setUser] = useState<User | null>(null);
  // this is a flag to check if update is needed
  const [signupData, setSignupData] = useState<SignupDataInt | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isPro, setIsPro] = useState<boolean>(false);
  const [authEntreprise, setHaveEntreprise] = useState<EntrepriseType>();
  const [userEntreprise, setUserEntreprise] = useState<EntrepriseType>();

  /**
   * Fonction de connexion de l'utilisateur
   * @param credentials Identifiants de connexion (email, password)
   * @returns Promise résolue avec les informations de l'utilisateur ou une chaîne de caractères contenant l'erreur
   */
  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const userData: User | string = await signIn(email, password);
    let loggedUser = userData as User;
    if (loggedUser?.id !== undefined) {
      setUser(loggedUser);
      if (isValidateUser(loggedUser)) {
        localStorage.setItem("bappauth", JSON.stringify(loggedUser));
        setIsAuthenticated(true);
        setIsPro(loggedUser.type === UserTypeEnum.PRO);
        setHaveEntreprise(loggedUser.entreprise);
        setIsAdmin(loggedUser.role.includes("ADMIN"));
      }
      setIsLoading(false);
    } else {
      let error = userData as string;
      return error;
    }

    return loggedUser;
  };

  /**
   * Fonction de déconnexion de l'utilisateur
   */
  const logout = () => {
    setIsLoading(true);
    deleteCookie("beleeJwt");
    localStorage.removeItem("bappauth");
    setUser(null);
    setIsAuthenticated(false);
  };

  /**
   * Fonction pour rafraîchir les informations de l'utilisateur
   * @returns Promise résolue avec les informations de l'utilisateur ou une chaîne de caractères contenant l'erreur
   */
  const refreshAuth = async () => {
    const updatedUser = await refreshAuthApi();
    if (typeof updatedUser !== "string") {
      localStorage.setItem("bappauth", JSON.stringify(updatedUser));
      setUser(updatedUser);
      setHaveEntreprise(updatedUser.entreprise);
      setIsAdmin(updatedUser.role.includes("ADMIN"));
      setIsPro(updatedUser.type === UserTypeEnum.PRO);
      return updatedUser;
    }
    return updatedUser;
  };

  const parseJwt = (token: any) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    //check validation token

    const beleeJwt = getCookie("beleeJwt");
    // si le token n'est pas encore expiré

    if (beleeJwt) {
      let expired = parseJwt(beleeJwt).exp * 1000 < Date.now();
      if (!expired) {
        let userStringify = localStorage.getItem("bappauth");
        if (userStringify !== null) {
          let user = JSON.parse(userStringify);
          // if (user.id !== undefined) {
          if (user.id !== undefined) {
            setUser(user);
            setIsAdmin(
              user.role.includes("admin") || user.role.includes("ADMIN")
            );
            setIsAuthenticated(true);
            // setIsPro(true);
            setIsPro(user.type === UserTypeEnum.PRO);
            setHaveEntreprise(user.entreprise);
            setUserEntreprise(user.entreprise);

            setIsLoading(false);
          }
        }
      } else {
        localStorage.removeItem("bappauth");
        setIsAuthenticated(false);
        setIsLoading(false);
        setIsPro(false);
        setHaveEntreprise(undefined);
      }
    }
    // let storeAuth = localStorage.getItem("bappauth");
    // if (storeAuth) {
    //   let parseAuth = storeAuth ? JSON.parse(storeAuth) : null;
    //   setUserEntreprise(parseAuth.entreprise);
    // }
  }, []);

  return {
    user,
    isAuthenticated,
    isLoading,
    signupData,
    login,
    logout,
    refreshAuth,
    isAdmin,
    isPro,
    authEntreprise,
    userEntreprise,
  };
};
