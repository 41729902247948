type Props = {
  title: string;
  values: any;
  currency?: string;
};
const PaymentItem = ({ title, values, currency }: Props) => {
  return (
    <div className="flex flex-col mb-2">
      <div
        className={`col-span-12  py-1 grid grid-cols-12 gap-4 items-center px-2`}
      >
        <div className="flex col-span-6 flex-col text-sm">{title}</div>
        <div className="flex col-span-6 flex-col text-sm">
          {values} {currency ? currency : "€"}
        </div>
      </div>
    </div>
  );
};

export default PaymentItem;
