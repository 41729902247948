export const REGEX = {
  MOT_DE_PASSE_REGEXP: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}/,
  EMAIL_REGEXP: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,60}$/i,
  PHONE_REGEXP: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
  CODE_POSTAL_REGEXP: /^[0-9]{5}$/,
  URL_REGEXP:
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i,
  IMMATRICULATION_REGEXP:
    /[A-HJ-NP-TV-Z]{2}[\s-]{0,1}[0-9]{3}[\s-]{0,1}[A-HJ-NP-TV-Z]{2}|[0-9]{2,4}[\s-]{0,1}[A-Z]{1,3}[\s-]{0,1}[0-9]{2}/gm,
  NUMBER_REGEXP: /^\d+$/,
};

export function validSIRENFormat(siren: any): boolean {
  var estValide;
  if (siren.length !== 9 || isNaN(siren)) estValide = false;
  else {
    // Donc le SIREN est un numérique à 9 chiffres
    var somme = 0;
    var tmp;
    for (var cpt = 0; cpt < siren.length; cpt++) {
      if (cpt % 2 === 1) {
        // Les positions paires : 2ème, 4ème, 6ème et 8ème chiffre
        tmp = siren.charAt(cpt) * 2; // On le multiplie par 2
        if (tmp > 9) tmp -= 9; // Si le résultat est supérieur à 9, on lui soustrait 9
      } else tmp = siren.charAt(cpt);
      somme += parseInt(tmp);
    }
    if (somme % 10 === 0) estValide = true;
    // Si la somme est un multiple de 10 alors le SIREN est valide
    else estValide = false;

    estValide = true; // TODO : verify this
  }
  return estValide;
}
