import { InputProps } from "interfaces";
import React, { useState } from "react";

const TextArea = ({
  icon,
  rightIcon,
  label,
  bgColor = "bg-gray-300",
  borderColor = "border-0",
  addClass,
  labelColor,
  ...inputProps
}: InputProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="flex flex-col justify-start">
      {label && (
        <label
          className={`mb-2 text-sm ${labelColor ? labelColor : " font-light"}`}
        >
          {label}
        </label>
      )}
      <div
        className={`w-full h-auto border ${addClass}  ${bgColor} ${
          focused ? " border-orange-belee" : borderColor
        } flex justify-start items-center space-x-4 rounded-md `}
      >
        {icon && <div className="pl-4">{icon}</div>}
        <textarea
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          className={`w-full pl-4 h-full overflow-hidden bg-transparent border-none outline-none text-md ${
            rightIcon ? "" : "rounded-r-md"
          } `}
          {...inputProps}
        />
        {rightIcon && <div className="pr-4">{rightIcon}</div>}
      </div>
    </div>
  );
};

export default TextArea;
