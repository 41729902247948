import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className="w-10 flex justify-center items-center h-10 text-white cursor-pointer rounded-full hover:text-green-belee"
      onClick={() => {
        navigate(-1);
      }}
    >
      <ChevronLeftIcon />
    </div>
  );
};

export default BackButton;
