export const reduceFormatedAddress = (address: string | null) => {
  if (address && address?.length >= 28) {
    return address.substring(0, 25) + "...";
  }
  return address;
};

export const getHours = (hours_minutes: string | null) => {
  if (hours_minutes) {
    let hour = hours_minutes.split(":")[0];
    if (hour.length == 1) {
      hour = "0" + hour;
    }
    return hour;
  }

  return false;
};
//

export const getMin = (hours_minutes: string | null) => {
  if (hours_minutes) {
    let min = hours_minutes.split(":")[1];
    if (min.length == 1) {
      min = "0" + min;
    }
    return min;
  }

  return false;
};

export const stringToDate = (dateString: any) => {
  const date = new Date(dateString);

  const options = {
    timeZone: "local",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const localDate = date.toLocaleDateString("fr-FR");
  const localTime = date.toLocaleTimeString("fr-FR");

  let response = {
    date: localDate.replaceAll("/", " "),
    time: localTime,
  };
  return response;
};

export const determinerStatutDate = (dateStr: Date | string | number) => {
  // Crée un objet Date à partir de la chaîne de caractères

  const date = new Date(dateStr);

  // Obtient la date actuelle
  const dateActuelle = new Date();

  // Compare les composantes de la date
  const anneeDate = date.getFullYear();
  const moisDate = date.getMonth();
  const jourDate = date.getDate();

  const anneeActuelle = dateActuelle.getFullYear();
  const moisActuel = dateActuelle.getMonth();
  const jourActuel = dateActuelle.getDate();

  // Calcule la différence en jours entre la date actuelle et la date donnée
  const differenceJours = Math.floor(
    (Date.UTC(anneeDate, moisDate, jourDate) -
      Date.UTC(anneeActuelle, moisActuel, jourActuel)) /
      (1000 * 60 * 60 * 24)
  );

  // Formatte la date au format JJ/MM/AA
  const formatteDate = `${jourDate}/${moisDate + 1}/${anneeDate
    .toString()
    .slice(-2)}`;
  // Détermine le statut de la date
  if (differenceJours === 0) {
    return "Aujourd'hui ";
  } else if (differenceJours === 1) {
    return "Demain ";
  } else if (differenceJours === 2) {
    return "Après-demain ";
  } else if (differenceJours < 0) {
    return "Expiré ";
  } else {
    return "";
  }
};

export const etatPaiementCommande = (mode: string) => {
  let modeString = "";
  if (mode === "SOLDE" || mode === "PAYPAL" || mode === "CARTE_BANCAIRE") {
    modeString = "Déjà payée";
  } else if (mode === "SUR_PLACE_CB") {
    modeString = "Sur place par carte bancaire";
  } else if (mode === "SUR_PLACE_ESPECE") {
    modeString = "Sur place par espèce";
  } else if (mode === "VIREMENT_APRES_NETTOYAGE") {
    modeString = "Virement après nettoyage";
  } else if (mode === "PAB_CARTE") {
    modeString = "Paiement à bord par carte bancaire";
  } else if (mode === "PAB_ESPECE") {
    modeString = "Paiement à bord par espèce";
  } else if (mode === "PAB_NON_PRECISE") {
    modeString = "Paiement à bord non précisé";
  } else if (mode === "DIFFERES_VIREMENT_FIN_COURSE") {
    modeString = "Virement en fin de course";
  } else if (mode === "DIFFERES_VIREMENT_UN_JOUR") {
    modeString = "Virement sous 24h";
  } else if (mode === "DIFFERES_VIREMENT_DEUX_JOUR") {
    modeString = "Virement sous 48h";
  } else if (mode === "DIFFERES_VIREMENT_DIX_JOUR") {
    modeString = "Virement sous 10j";
  } else if (mode === "DIFFERES_PAYPAL_FIN_COURSE") {
    modeString = "Paypal en fin de course";
  } else if (mode === "DIFFERES_PAYPAL_UN_JOUR") {
    modeString = "Paypal sous 24h";
  } else if (mode === "DIFFERES_PAYPAL_DEUX_JOUR") {
    modeString = "Paypal sous 48h";
  } else if (mode === "DIFFERES_PAYPAL_DEUX_JOUR") {
    modeString = "Paypal sous 48h";
  } else if (mode === "DIFFERES_PAYPAL_DIX_JOUR") {
    modeString = "Paypal sous 10 jours";
  } else if (mode === "DIFFERES_PAY_LINK") {
    modeString = "Lien de paiement en fin de course";
  } else if (mode === "PAYEMENT_PRESTATAIRE") {
    modeString = "Paiement à bord";
  } else {
    modeString = "Paiement inconnu";
  }
  return modeString;
};

export const getSign = (number: number) => {
  if (number > 0) {
    return "+"; // Si le nombre est positif, renvoyer 1 pour le signe positif.
  } else if (number < 0) {
    return "-"; // Si le nombre est négatif, renvoyer -1 pour le signe négatif.
  } else {
    return "+"; // Si le nombre est égal à zéro, renvoyer 0 (ou le signe est considéré comme positif par convention).
  }
};

// Fonction pour formater la date au format JJ/MM/AA
export const formatDate = (date: Date) => {
  const jour = date.getUTCDate();
  const mois = date.getUTCMonth() + 1;
  const annee = date.getUTCFullYear().toString().slice(-2);
  return `${jour}/${mois}/${annee}`;
};

export const formattedDate = (dateStr: any) => {
  const date = new Date(dateStr);
  const jour = date.getUTCDate();
  const mois = date.getUTCMonth() + 1;
  const annee = date.getUTCFullYear().toString().slice(-2);
  return `${jour < 10 ? `0${jour}` : jour}/${
    mois < 10 ? `0${mois}` : mois
  }/${annee}`;
};

export const heureEnMillisecondes = (heure: string): number => {
  const [heures, minutes] = heure.split(":");
  const date = new Date();
  date.setHours(Number(heures));
  date.setMinutes(Number(minutes));
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
};

export const calculerSecondesRestantes = (dateStrCible: any): number => {
  // let hour = dateStrCible.getHours();
  let dateStrCibleUTC0 =
    dateStrCible.substr(0, dateStrCible.length - 1) + "+02:00";
  const dateCible = new Date(dateStrCibleUTC0);

  const dateActuelle = new Date();
  const differenceEnSecondes = Math.floor(
    (dateCible.getTime() - dateActuelle.getTime()) / 1000
  );

  return differenceEnSecondes;
};

export const calculerSecondesRestantesEntreDeuxTemps = (
  tempsDebut: Date,
  tempsFin: Date
): number => {
  const differenceEnMillisecondes = tempsFin.getTime() - tempsDebut.getTime();
  const differenceEnSecondes = Math.floor(differenceEnMillisecondes / 1000);
  return differenceEnSecondes;
};

export const calculerSecondesEcoule = (dateStrCible: any): number => {
  let dateStrCibleUTC0 =
    dateStrCible.substr(0, dateStrCible.length - 1) + "+02:00";
  const dateCible = new Date(dateStrCibleUTC0);

  const dateActuelle = new Date();
  const secondeEcouler = Math.floor(
    (dateActuelle.getTime() - dateCible.getTime()) / 1000
  );
  return secondeEcouler;
};

export const convertDateTimeToOffset = (
  dateTimeString: any,
  offset: number
) => {
  const utcDateTime = new Date(dateTimeString);
  const convertedDateTimeString = utcDateTime
    .toISOString()
    .replace("Z", `+${Math.abs(offset).toString().padStart(2, "0")}:00`);
  return convertedDateTimeString;
};

// export const getCurrentLocationReservant = () => {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const latitude = position.coords.latitude;
//         const longitude = position.coords.longitude;

//         const latLng = new google.maps.LatLng(latitude, longitude);
//         const geocoder = new google.maps.Geocoder();

//         geocoder.geocode({ location: latLng }, (results, status) => {
//           if (
//             status === google.maps.GeocoderStatus.OK &&
//             results &&
//             results.length > 0
//           ) {
//             const place = results[0];
//             let point = { ...place, name: place.formatted_address };
//             console.log("point", point);
//             return point;
//           } else {
//             console.log("Aucun résultat trouvé pour les coordonnées fournies.");
//           }
//         });
//       },
//       (error) => {
//         console.error("Erreur de géolocalisation :", error);
//       }
//     );
//   } else {
//     console.error(
//       "La géolocalisation n'est pas prise en charge par ce navigateur."
//     );
//   }
// };

export const getCurrentLocationReservant = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const latLng = new google.maps.LatLng(latitude, longitude);
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode({ location: latLng }, (results, status) => {
            if (
              status === google.maps.GeocoderStatus.OK &&
              results &&
              results.length > 0
            ) {
              const place = results[0];
              let point = { ...place, name: place.formatted_address };
              resolve(point);
            } else {
              reject("Aucun résultat trouvé pour les coordonnées fournies.");
            }
          });
        },
        (error) => {
          reject("Erreur de géolocalisation : " + error.message);
        }
      );
    } else {
      reject("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }
  });
};

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ latitude, longitude });
        },
        (error) => {
          console.error("Erreur de géolocalisation :", error);
          reject(error);
        }
      );
    } else {
      const errorMessage =
        "La géolocalisation n'est pas prise en charge par ce navigateur.";
      console.error(errorMessage);
      reject(new Error(errorMessage));
    }
  });
};

export const reverseGeocode = (
  latitude: any,
  longitude: any,
  callback: any
) => {
  const geocoder = new google.maps.Geocoder();
  const latLng = new google.maps.LatLng(latitude, longitude);

  geocoder.geocode({ location: latLng }, (results, status) => {
    if (results) {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        const address = results[0].formatted_address;
        callback(address);
      } else {
        console.error("Impossible de traduire les coordonnées en adresse.");
        callback("");
      }
    }
  });
};

export const isLogged = () => {
  let auth = localStorage.getItem("bappauth");
  if (auth) {
  } else {
    window.location.href = "/login";
  }
};

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export const urlB64ToUint8Array = (base64String: any) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
