export const EnvoyerRoutes = {
  toEnvoyer: (): string => "/banque/envoi",
  toAjoutBeneficiaire: (): string => "/banque/beneficiaire/ajout",
  toChoiceModeEnvoi: (): string => "/banque/envoi/choix/mode-paiement",
  toSaisirEnvoiSomme: (
    beneficiaireId?: string,
    modePaiement?: string
  ): string =>
    beneficiaireId && modePaiement
      ? "/banque/envoi/saisir/" + beneficiaireId + "/" + modePaiement
      : "/banque/envoi/saisir/:beneficiaireId/:modePaiement",
  toEnvoieAvecCarte: (beneficiaireId?: string): string =>
    beneficiaireId
      ? "/banque/envoi/paiement/" + beneficiaireId + "/carte_bancaire"
      : "/banque/envoi/paiement/:beneficiaireId/carte_bancaire",
};
