import { AuthProvider } from "hooks";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { AppRoutes } from "./routes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <AuthProvider>
      <ToastContainer />
      <AppRoutes />
    </AuthProvider>
  );
}

export default App;
