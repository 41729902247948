/* eslint-disable react-hooks/exhaustive-deps */
import { InputProps } from "interfaces";
import { useEffect, useRef, useState } from "react";

const Icon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20" fill="#FFFFFF">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20" fill="#FFFFFF">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

type InputSelectProps = {
  placeHolder?: string;
  label?: string;
  options: any[];
  isMulti?: boolean;
  addClass?: string;
  isSearchable: boolean;
  onChange: (value: any) => void;
  selected?: any;
};

const InputSelect = ({
  placeHolder,
  options,
  isMulti,
  label,
  addClass,
  isSearchable,
  onChange,
  bgColor = "bg-[#292929]",
  borderColor = "border-[#444444]",
  error,
  noShadow,
  shadow = "shadow-sm shadow-dark-belee",
  touched,
  selected,
  inputSize = "h-12",
}: InputSelectProps &
  InputProps &
  React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(
    isMulti ? [selected] : selected
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const searchRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e: any) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });
  const handleInputClick = (e: any) => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    setSelectedValue(isMulti ? [selected] : selected);
  }, [selected]);

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder ? placeHolder : "";
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {selectedValue.map((option: any) => (
            <div key={option.value} className="dropdown-tag-item text-white">
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="dropdown-tag-close cursor-pointer"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return selectedValue.label;
  };

  const removeOption = (option: any) => {
    return selectedValue.filter((o: any) => o.value !== option.value);
  };

  const onTagRemove = (e: any, option: any) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option: any) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o: any) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const isSelected = (option: any) => {
    if (isMulti) {
      return (
        selectedValue.filter((o: any) => o.value === option.value).length > 0
      );
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option: any) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  useEffect(() => {
    if (error && touched) {
      setFocused(false);
    }
  }, [error, touched]);
  return (
    <div className={"flex flex-col justify-start " + addClass}>
      {label && (
        <label className="mb-2 text-sm text-white font-light">{label}</label>
      )}
      <div className="dropdown-container border relative">
        <div
          ref={inputRef}
          onClick={handleInputClick}
          className={`dropdown-input w-full ${inputSize} border  ${bgColor} ${
            error && touched
              ? "border-red-500"
              : focused
              ? " border-green-belee"
              : borderColor
          } flex justify-start items-center space-x-4 ${
            noShadow ? "" : shadow
          } `}
        >
          <div className="dropdown-tools cursor-pointer ml-3">
            <div className="dropdown-tool">
              {/* <Icon /> */}
              <img
                src="/images/assets/arrow-down-HD.png"
                className="w-[40px]"
                alt="icone person"
              />
            </div>
          </div>
          <div className="dropdown-selected-value text-black">
            {getDisplay()}
          </div>
          {/* indicatif téléphonique (droite) */}
          <div className="absolute right-4 font-semibold text-orange-belee">
            {selectedValue && selectedValue.indicatif}
          </div>
        </div>
        {showMenu && (
          <div
            className={
              "dropdown-menu border z-10 " + borderColor + " " + bgColor
            }
            style={{
              position: "absolute",
              top: "100%",
              left: "0",
              width: "100%",
              maxHeight: "350px",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            {isSearchable && (
              <div className="search-box bg-gray-700">
                <input
                  className="bg-gray-200"
                  onChange={onSearch}
                  value={searchValue}
                  ref={searchRef}
                />
              </div>
            )}
            {getOptions().map((option: any) => (
              <div
                onClick={() => onItemClick(option)}
                key={option.value}
                className={`dropdown-item text-black text-center border-t-2 border-[#888888] cursor-pointer py-2
                 ${isSelected(option) && "selected"}`}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputSelect;
