export enum TypeFactureEnum {
  RECUE = "RECUE",
  ENVOYEE = "ENVOYEE",
}

export enum ActeurRoleEnum {
  RESERVANT = "User reservant",
  PRESTATAIRE = "User prestataire",
  SOURCE = "User source",
  DESTINATAIRE = "User destinataire",
}

export enum MotifEnum {
  PAYCMD = "Facture paiement commande",
  PAYCOM = "Facture paiement commissions",
  REVCOMAFF = "Facture commission affiliation",
  RMBCMD = "Facture remboursement commande",
  FRMBCMD = "Frais remboursement commande",
}
