import { ButtonType } from "interfaces";
import React, { ReactElement } from "react";

const AutoHiddenButton = ({
  isLoading = false,
  addClass,
  children,
  title,
  size,
  isShow = false,
  icon,
  ...buttonProps
}: ButtonType &
  React.ButtonHTMLAttributes<HTMLButtonElement>): ReactElement => {
  return (
    <button
      className={` p-0 ${size ? size : "w-14 h-14"} rounded-full border-0 ${
        addClass ? addClass : "text-white"
      } font-semibold  hover:bg-green-m-t flex justify-center items-center mouse transition ease-in duration-200 focus:outline-none`}
      {...buttonProps}
    >
      {icon}
    </button>
  );
};

export default AutoHiddenButton;
