import { EntrepriseData } from "api";
import { REGEX, validSIRENFormat } from "utils";

const infoEntrepriseValidate = (values: EntrepriseData) => {
  const errors: EntrepriseData = {
    siren: "",
    name: "",
    address: "",
    code_postale: "",
    ville: "",
    kbis: "",
  };

  let maxfilesize = 3000000;

  if (!values.siren) {
    errors.siren = "Le siren est obligatoire";
  } else if (!validSIRENFormat(values.siren || ""))
    errors.siren = "Veuillez entrer un siren valide, minimum 9 chiffres";

  if (!values.name) {
    errors.name = "Le nom est obligatoire";
  } else if (values.name.length < 4) {
    errors.name = "Le de l'entreprise est invalide, minimum 4 caractères";
  }

  if (!values.address) {
    errors.address = "L'adresse est obligatoire";
  }

  if (!values.code_postale) {
    errors.code_postale = "Le Code postale est obligatoire";
  } else if (values.code_postale.search(REGEX.CODE_POSTAL_REGEXP) === -1) {
    errors.code_postale = "Veuillez entrer un code postale valide";
  }

  if (!values.ville) {
    errors.ville = "La ville est obligatoire";
  } else if (values.ville.length < 4) {
    errors.ville = "Le nom de la ville est invalide, minimum 4 caractères";
  }

  if (!values.kbis) {
    errors.kbis = "Ce champ est obligatoire";
  } else {
    if (values.kbis.size > maxfilesize) {
      errors.kbis =
        "La taille du fichier est supérieur à 3 mo. Choisissez un fichier de plus petite taille";
    }
  }

  return errors;
};

export default infoEntrepriseValidate;
