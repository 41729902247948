import { UserTypeEnum } from "enums";
import { AddressType, SupplementType, User } from "interfaces";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
export function getCookie(name: string): string {
  if (typeof Cookies.get === "function") {
    const beleeJwt = Cookies.get(name);
    return beleeJwt ?? "";
  }

  return "";
}
/**
 *
 * @returns
 */
export const isValidToken = (): boolean => {
  const beleeJwt = Cookies.get("jwt");

  if (beleeJwt === null || beleeJwt === undefined) return false;

  try {
    const decodedJwt: { exp: number } = jwtDecode(beleeJwt ?? "");
    // check if beleeJwt has expired (1h)
    return decodedJwt.exp > Date.now() / 1000;
  } catch (error) {
    return false;
  }
};
export function deleteCookie(name: string) {
  const date = new Date();

  // set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
  document.cookie =
    "name=" + name + "; expires=" + date.toUTCString() + "; path=/";
  // store the beleeJwt in the cookie shell
  Cookies.set(name, "", { expires: new Date(0), path: "/" });
}

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isValidateUser = (user: User) => {
  if ((user && user.etat === "VALIDE") || (user && user.etat === "BLOQUE")) {
    return true;
  }
  return false;
};

export const getAddress = (address_components: any[] | undefined) => {
  let resultat: AddressType = {
    code_postale: "",
    adresse_1: "",
    numero: "",
  };
  if (address_components) {
    for (const address of address_components) {
      if (address.types.includes("postal_code")) {
        resultat.code_postale = address.long_name;
      }
      if (address.types.includes("route")) {
        resultat.adresse_1 = address.long_name;
      }
      if (address.types.includes("street_number")) {
        resultat.numero = address.long_name;
      }
    }
  }

  let code_postale = resultat.code_postale;
  let numero = resultat.numero;
  let address_1 = resultat.adresse_1;
  let address = numero
    ? numero + (address_1 ? ", " + address_1 : "")
    : address_1
    ? address_1
    : "";
  return address;
};

export const toTimeString = (totalSeconds: number) => {
  const totalMs = totalSeconds * 1000;
  const result = new Date(totalMs).toISOString().slice(11, 16);

  return result;
};

export const clearLocalStorage = (user: User | null) => {
  if (user?.type === UserTypeEnum.PRO) {
    localStorage.removeItem("depart_" + (user ? user.id : ""));
  }

  localStorage.removeItem("totalPriceMin");
  localStorage.removeItem("detailPaiementCommande");
  localStorage.removeItem("my_commande");
  localStorage.removeItem("isDesktop");
  localStorage.removeItem("arrivee_" + (user ? user.id : ""));
  localStorage.removeItem("date_arrivee_" + (user ? user.id : ""));
  // localStorage.removeItem("date_" + (user ? user.id : ""));
  // localStorage.removeItem("hour_" + (user ? user.id : ""));
  clearSupplementCache(user);
  localStorage.removeItem("supplements_" + (user ? user.id : ""));
  localStorage.removeItem("gammes_" + (user ? user.id : ""));
  localStorage.removeItem("gamme_" + (user ? user.id : ""));
  localStorage.removeItem("commande_details_" + (user ? user.id : ""));
  localStorage.removeItem("commande_info_client_" + (user ? user.id : ""));
  localStorage.removeItem("amountPay");
  localStorage.removeItem("__paypal_storage__");
  localStorage.removeItem("etape");
};

export const clearSupplementCache = (user: User | null) => {
  let supplements = window.localStorage.getItem(
    "supplements_" + (user ? user.id : "")
  );
  if (supplements) {
    let supps = JSON.parse(supplements) as SupplementType[];
    for (const supplement of supps) {
      localStorage.removeItem("nb_" + supplement._id);
    }
  }
};
