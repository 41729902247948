import MaestroIcon from "components/elements/icons/MaestroIcon";
import MastercardIcon from "components/elements/icons/MastercardIcon";
import PaypalIcon from "components/elements/icons/PaypalIcon";
import VisaIcon from "components/elements/icons/VisaIcon";
import { PaymentRoutes } from "../PaymentRoutes";

export const typePayments = (commandeId?: string) => {
  return [
    {
      id: "solde",
      text: "Mon Solde",
      to: PaymentRoutes.toPaymentParSolde(commandeId),
    },
    {
      id: "carte_bancaire",
      text: "Carte bancaire",
      to: PaymentRoutes.toPaymentParCarte(commandeId),
    },
    {
      id: "paypal",
      text: "Paypal",
      to: PaymentRoutes.toPaymentParPaypal(commandeId),
      icon: <PaypalIcon />,
    },
    {
      id: "payement_prestataire",
      text: "Paiement à bord",
      to: "",
    },
  ];
};

export const typePaymentCollaborateur = (commandeId?: string) => {
  return [
    {
      id: "solde",
      text: "Mon Solde",
      to: PaymentRoutes.toPaymentParSolde(commandeId),
    },
    {
      id: "payement_prestataire",
      text: "Paiement au prestataire",
      to: "",
    },
    {
      id: "carte_bancaire",
      text: "Carte bancaire",
      to: PaymentRoutes.toPaymentParCarte(commandeId),
    },
    {
      id: "paypal",
      text: "Paypal",
      to: PaymentRoutes.toPaymentParPaypal(commandeId),
      icon: <PaypalIcon />,
    },
  ];
};

export const typePABPro = (commandeId?: string) => {
  return [
    {
      id: "pab_carte",
      text: "Par carte",
      to: "",
    },
    {
      id: "pab_espece",
      text: "Espèces",
      to: "",
    },
    {
      id: "pab_non_precise",
      text: "non précisé (carte ou espèces)",
      to: "",
    },
  ];
};

export const typePayementDiffere = (commandeId?: string) => {
  return [
    {
      id: "differes_virement_fin_course",
      text: "Virement en fin de course",
      to: "",
    },
    {
      id: "differes_virement_un_jour",
      text: "Virement sous 24h",
      to: "",
    },
    {
      id: "differes_virement_deux_jour",
      text: "Virement sous 48h",
      to: "",
    },
    {
      id: "differes_virement_dix_jour",
      text: "Virement sous 10j",
      to: "",
    },
  ];
};

export const typePayementDifferePaypal = (commandeId?: string) => {
  return [
    {
      id: "differes_paypal_fin_course",
      text: "Paypal en fin de course",
      to: "",
    },
    {
      id: "differes_paypal_un_jour",
      text: "Paypal sous 24h",
      to: "",
    },
    {
      id: "differes_paypal_deux_jour",
      text: "Paypal sous 48h",
      to: "",
    },
    {
      id: "differes_paypal_dix_jour",
      text: "Paypal sous 10 jours",
      to: "",
    },
  ];
};

export const typePaymentLink = (commandeId?: string) => {
  return [
    {
      id: "differes_pay_link",
      text: "Lien de paiement en fin de course",
      to: "",
    },
  ];
};

export const typePaymentsCommission = (commandeId?: string) => {
  return [
    {
      id: "carte_bancaire",
      text: "Carte bancaire",
      to: PaymentRoutes.toPaymentParCarte(commandeId),
    },
    {
      id: "paypal",
      text: "Paypal",
      to: PaymentRoutes.toPaymentParPaypal(commandeId),
      icon: <PaypalIcon />,
    },
    {
      id: "solde",
      text: "Mon Solde",
      to: PaymentRoutes.toPaiementCommissionAvecSolde(commandeId),
    },
  ];
};

export const typePaymentsFraisAbsence = (commandeId?: string) => {
  return [
    {
      id: "carte_bancaire",
      text: "Carte bancaire",
      to: PaymentRoutes.toPaiementFraisAbsenceParCarte(commandeId),
    },
    {
      id: "paypal",
      text: "Paypal",
      to: "",
      icon: <PaypalIcon />,
    },
    {
      id: "solde",
      text: "Mon Solde",
      to: PaymentRoutes.toPaiementFraisAbsenceAvecSolde(commandeId),
    },
  ];
};

export const typePaymentsFraisAbandon = (commandeId?: string) => {
  return [
    {
      id: "carte_bancaire",
      text: "Carte bancaire",
      to: PaymentRoutes.toPaymentParCarte(commandeId),
    },
    {
      id: "paypal",
      text: "Paypal",
      to: PaymentRoutes.toPaymentParPaypal(commandeId),
      icon: <PaypalIcon />,
    },
    {
      id: "solde",
      text: "Mon Solde",
      to: PaymentRoutes.toPayFraisAbandon(commandeId),
    },
  ];
};

export const typeCartes = [
  { id: "visa", icon: <VisaIcon />, text: "Visa" },
  { id: "MasterCard", icon: <MastercardIcon />, text: "MasterCard" },
  { id: "Maestro", icon: <MaestroIcon />, text: "Maestro" },
];
