export const notificationData = [
  {
    key: "prestation-expire",
    title: "Prestation expirée!",
    body: "Désolé! La prestation a expirée et nous n'avons pas pu retrouver un chauffeur pour vous! Nous vous remercions pour votre confiance.",
  },
  {
    key: "premiere-connexion-activer-entreprise",
    title: "Enregistrement d'entreprise!",
    body: "Faites valider votre entreprise pour avoir accès aux fonctionnalités réservées aux professionnels",
  },
  {
    key: "votre-entreprise-a-ete-valider-activer-un-service",
    title: "Activer un service!",
    body: "Vous devez activer un service si vous souhaitez effectuer des courses ou répondre à d'autres prestations",
  },
];
