export const RetirerRoutes = {
  toRetirer: (): string => "/banque/retirer",
  toRetirerParPaypal: (): string => "/banque/retirer/paypal",
  toRetirerParVirement: (): string => "/banque/retirer/virement",
  toRetirerParEspece: (): string => "/banque/retirer/espece",
  toSaveMessageRetirer: (transactionId?: string): string =>
    transactionId
      ? "/banque/retirer/details/" + transactionId
      : "/banque/retirer/details/:transactionId",
  toSaisirSommeRetrait: (devise?: string): string =>
    devise
      ? "/banque/retirer/saisir/" + devise
      : "/banque/retirer/saisir/:devise",
  toConfirmRetrait: (): string => "/banque/retirer/confirm",
  toScanneQRCode: (): string => "/banque/retirer/scanne",
  toSuccessCash: (): string => "/banque/retirer/success",
};
