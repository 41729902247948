const UserRectIcon = () => {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83377 10.1382C10.2758 10.1382 11.4449 8.9692 11.4449 7.52713C11.4449 6.08505 10.2758 4.91602 8.83377 4.91602C7.39169 4.91602 6.22266 6.08505 6.22266 7.52713C6.22266 8.9692 7.39169 10.1382 8.83377 10.1382Z"
        stroke="#B8B8B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1944 1H2.30556C1.58452 1 1 1.58452 1 2.30556V15.3611C1 16.0821 1.58452 16.6667 2.30556 16.6667H23.1944C23.9155 16.6667 24.5 16.0822 24.5 15.3611V2.30556C24.5 1.58452 23.9155 1 23.1944 1Z"
        stroke="#B8B8B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.91699 16.6658C4.91699 15.2237 6.67054 14.0547 8.83366 14.0547C10.9968 14.0547 12.7503 15.2237 12.7503 16.6658"
        stroke="#B8B8B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5836 11.4453L15.3613 11.4453"
        stroke="#B8B8B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5837 7.52734L16.667 7.52734"
        stroke="#B8B8B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserRectIcon;
