import { ServiceResponseAPI } from "interfaces/ServiceResponseAPI";
import { client, clientFile } from "./client";

export const activateService = async (
  userId: string,
  entrepriseSlug: string,
  data: any
) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/" + entrepriseSlug + "/service/create?userId=" + userId,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const activateServiceBeleeCash = async (userId: string, data: any) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/activate/belee/cash/" + userId,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getUsersServices = async (
  userId: string
): Promise<ServiceResponseAPI[] | string> => {
  let err = "";
  try {
    const response = await client.get("/services/" + userId);
    if (response.status === 200) {
      return response.data as ServiceResponseAPI[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getUsersServicesByName = async (
  type_service: string
): Promise<ServiceResponseAPI[] | string> => {
  let err = "";
  try {
    const response = await client.get("/service/" + type_service);
    if (response.status === 200) {
      return response.data as ServiceResponseAPI[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
