export const PrestationRoutes = {
  toAllPrestation: (): string => "/prestation/all",
  toAllPrestationDisponible: (): string => "/allPrestations",
  toChoixGuidePage: (prestationId?: string): string =>
    prestationId
      ? "/prestation/choixGuide/" + prestationId
      : "/prestation/choixGuide/:prestationId",
  toPrestationDisponible: (): string => "/prestations",
  toPrestationDetails: (prestationId?: string): string =>
    prestationId
      ? "/prestation/details/" + prestationId
      : "/prestation/details/:prestationId",
  toPrestationPublicDetail: (prestationId?: string): string =>
    prestationId
      ? "/prestation/detail/public/" + prestationId
      : "/prestation/detail/public/:prestationId",
  toGetPrestation: (prestationId?: string): string =>
    prestationId ? "/prestation/" + prestationId : "/prestation/:prestationId",
  toMesPrestations: (): string => "/mes-prestations/",
  toClientABord: (prestationId?: string): string =>
    prestationId
      ? "/prestation/client-a-bord/" + prestationId
      : "/prestation/client-a-bord/:prestationId",
  toNotificationCommande: (
    prestationId?: string,
    notificationId?: string
  ): string =>
    prestationId && notificationId
      ? "/notification-commande/" + prestationId + "/" + notificationId
      : "/notification-commande/:prestationId/:notificationId",
  toPrestationIgnoree: (): string => "/prestation/ignoree",
  toNotificationPrestationAnnuler: (
    prestationId?: string,
    notificationId?: string
  ): string =>
    prestationId && notificationId
      ? "/prestation/annuler/" + prestationId + "/" + notificationId
      : "/prestation/annuler/:prestationId/:notificationId",
  toPrestataireSurPlace: (prestationId?: string): string =>
    prestationId
      ? "/prestation/prestataire-sur-place/" + prestationId
      : "/prestation/prestataire-sur-place/:prestationId",
  toClientAbsent: (prestationId?: string): string =>
    prestationId
      ? "/prestation/client-absent/" + prestationId
      : "/prestation/client-absent/:prestationId",
  toFraisClientAbsent: (prestationId?: string): string =>
    prestationId
      ? "/prestation/notification/mustpay/client-absent/" + prestationId
      : "/prestation/notification/mustpay/client-absent/:prestationId/",
  toPrestataireEnRoute: (prestationId?: string): string =>
    prestationId
      ? "/prestation/prestataire-en-route/" + prestationId
      : "/prestation/prestataire-en-route/:prestationId",
  toPrestationAbandonnee: (prestationId?: string): string =>
    prestationId
      ? "/prestation/abandonnee/" + prestationId
      : "/prestation/abandonnee/:prestationId",
  toPrestationInterrompue: (prestationId?: string): string =>
    prestationId
      ? "/prestation/interrompue/" + prestationId
      : "/prestation/interrompue/:prestationId",
  toPrestationAnnuleeFrais: (prestationId?: string, frais?: string): string =>
    prestationId && frais
      ? "/prestation/paiement/annulee/" + prestationId + "/" + frais
      : "/prestation/paiement/annulee/:prestationId",
  toPrestationAnnulee: (prestationId?: string): string =>
    prestationId
      ? "/prestation/annulee/" + prestationId
      : "/prestation/annulee/:prestationId",
  toPrestationAnnuleeParReservant: (prestationId?: string): string =>
    prestationId
      ? "/prestation/annulee/reservant/" + prestationId
      : "/prestation/annulee/reservant/:prestationId",
  toSuccessPrestationPage: (prestationId?: string): string =>
    prestationId
      ? "/prestation/terminee/" + prestationId
      : "/prestation/terminee/:prestationId",
  toPrestataireMuet: (prestationId?: string): string =>
    prestationId
      ? "/prestataire/muet/" + prestationId
      : "/prestataire/muet/:prestationId",
  toCommentairePrestataireAboutLitige: (
    prestationId?: string,
    litigeId?: string
  ): string =>
    prestationId && litigeId
      ? "/prestation/commentaire-about-litige/" + prestationId + "/" + litigeId
      : "/prestation/commentaire-about-litige/:prestationId/:litigeId",
  toChoixGuidTrajetCommande: (prestationId?: string): string =>
    prestationId
      ? "/prestation/choix-guide-trajet/" + prestationId
      : "/prestation/choix-guide-trajet/:prestationId",
};
