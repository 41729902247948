import { ReactNode } from "react";

type AddressType = {
  placeId: string;
  lat: string;
  lng: string;
  route: string;
  country: string;
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  locality: string;
};

type PositionType = {
  lng: number;
  lat: number;
};

export const getAddressDetails = (point: any | undefined) => {
  let addresses: AddressType = {
    placeId: "",
    lat: "",
    lng: "",
    route: "",
    country: "",
    administrative_area_level_1: "",
    administrative_area_level_2: "",
    locality: "",
  };
  if (point) {
    addresses.placeId = point.place_id;
    addresses.lat = point.geometry.location.lat;
    addresses.lng = point.geometry.location.lng;
    if (point.address_components) {
      for (const address of point.address_components) {
        if (address.types.includes("country")) {
          addresses.country = address.short_name;
        }
        if (address.types.includes("administrative_area_level_1")) {
          addresses.administrative_area_level_1 = address.short_name;
        }
        if (address.types.includes("administrative_area_level_2")) {
          addresses.administrative_area_level_2 = address.short_name;
        }
        if (address.types.includes("locality")) {
          addresses.locality = address.short_name;
        }
        if (address.types.includes("route")) {
          addresses.route = address.long_name;
        }
      }
    }
  }

  return addresses;
};

export const getAddressFormatVilleWithCodePostale = (
  address: any | ReactNode,
  value: any
) => {
  let address_formated = { postal_code: "", ville: "" };
  if (typeof address !== "undefined") {
    for (let index = 0; index < address.length; index++) {
      const element = address[index];
      if (element.types[0] === "postal_code") {
        address_formated.postal_code = element.short_name;
      }
    }

    for (let index = 0; index < address.length; index++) {
      const element = address[index];
      if (element.types[0] === "locality") {
        address_formated.ville = element.short_name;
      }
    }
    if (value == "") {
      return address_formated;
    } else if (value == "ville") {
      return address_formated.ville;
    } else if (value == "code_postale") {
      return address_formated.postal_code;
    }
  } else {
    return null;
  }
};

export const getDistanceAandB = (
  pointA: PositionType,
  pointB: PositionType
) => {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(pointB.lat - pointA.lat);
  var dLong = rad(pointB.lng - pointA.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(pointA.lat)) *
      Math.cos(rad(pointB.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return Math.ceil(d); // returns the distance in meter
};

export const rad = (x: number) => {
  return (x * Math.PI) / 180;
};