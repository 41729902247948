type Props = {
  title: string;
  value?: string;
};
const UserDetailsItem = ({ title, value }: Props) => {
  return (
    <div className="grid grid-cols-2 gap-4 text-sm">
      <div className="text-dark-belee font-medium col-span-1 flex bg-gray-300 rounded-lg h-10 text-center justify-center items-center">
        {title}
      </div>
      <div className="col-span-1 flex bg-gray-300 text-dark-belee rounded-lg border border-orange-belee h-10 justify-center items-center">
        {value}
      </div>
    </div>
  );
};

export default UserDetailsItem;
