import { isValidateUser } from "helpers";
import { User, VehiculeType } from "interfaces";
import Cookies from "js-cookie";
import { REGEX } from "utils";
import {
  InvitationAPIResponse,
  signUpData,
  UpdateAddressData,
  UpdateAuthData,
  UserByRoleAPIResponse,
} from "./ApiInterfaces";
import { client, clientFile } from "./client";

export const checkPassWordAPI = async (
  email: string,
  password: string
): Promise<any> => {
  let errorResponse = {};
  try {
    const response = await client.post("/auth/checkpassword", {
      email: email,
      password: password,
    });

    if (response.status === 200 && response) {
      return response;
    }
  } catch (error: any) {
    errorResponse = error.response.data.message;
  }
  return errorResponse as string;
};
