import { RequireAuth } from "routes";
import { FactureRoutes } from "./FactureRoutes";
import { Route, Routes } from "react-router-dom";
import React from "react";

const FeatureFacture = {
  FacturePage: React.lazy(() => import("pages/Factures/views/FacturePage")),
  FactureFraisCommandePdfPage: React.lazy(
    () => import("pages/Factures/views/FactureFraisCommandePdfPage")
  ),
  FactureCommandePdfPage: React.lazy(
    () => import("pages/Factures/views/FactureCommandePdfPage")
  ),
  FactureCommissionsPdfPage: React.lazy(
    () => import("pages/Factures/views/FactureCommissionsPdfPage")
  ),
  FactureCommissionDispatcherPdf: React.lazy(
    () => import("pages/Factures/views/FactureCommissionDispatcherPdfPage")
  ),
  FactureGainPrestatairePdfPage: React.lazy(
    () => import("pages/Factures/views/FactureGainPrestatairePdfPage")
  ),
  FactureRemboursementCommandePdfPage: React.lazy(
    () => import("pages/Factures/views/FactureRemboursementCommandePdfPage")
  ),
  FactureFraisRemoursementCommandePdfPage: React.lazy(
    () =>
      import("pages/Factures/views/FactureFraisRemboursementCommandePdfPage")
  ),
  FactureFraisCommissionsPdfPage: React.lazy(
    () => import("pages/Factures/views/FactureFraisCommissionsPdfPage")
  ),
};

export const FactureAppRoutes = () => (
  <Routes>
    <Route key={"FactureRoutes"} element={<RequireAuth />}>
      <Route
        path={FactureRoutes.toFacturePage()}
        element={<FeatureFacture.FacturePage />}
      />
      <Route
        path={FactureRoutes.toFactureFraisCommandePdf()}
        element={<FeatureFacture.FactureFraisCommandePdfPage />}
      />
      <Route
        path={FactureRoutes.toFactureCommandePdf()}
        element={<FeatureFacture.FactureCommandePdfPage />}
      />
      <Route
        path={FactureRoutes.toFactureCommissionsPdf()}
        element={<FeatureFacture.FactureCommissionsPdfPage />}
      />
      <Route
        path={FactureRoutes.toFactureCommissionDispatcherPdf()}
        element={<FeatureFacture.FactureCommissionDispatcherPdf />}
      />
      <Route
        path={FactureRoutes.toFactureGainPrestatairePdf()}
        element={<FeatureFacture.FactureGainPrestatairePdfPage />}
      />
      <Route
        path={FactureRoutes.toFactureRemboursementCommandePdf()}
        element={<FeatureFacture.FactureRemboursementCommandePdfPage />}
      />
      <Route
        path={FactureRoutes.toFactureFraisRemoursementCommandePdf()}
        element={<FeatureFacture.FactureFraisRemoursementCommandePdfPage />}
      />
      <Route
        path={FactureRoutes.toFactureFraisCommissionsPdf()}
        element={<FeatureFacture.FactureFraisCommissionsPdfPage />}
      />
    </Route>
  </Routes>
);
