import { Delete } from "react-feather";

export const arrayNumber = [
  { id: 1, text: 1 },
  { id: 2, text: 2 },
  { id: 3, text: 3 },
  { id: 4, text: 4 },
  { id: 5, text: 5 },
  { id: 6, text: 6 },
  { id: 7, text: 7 },
  { id: 8, text: 8 },
  { id: 9, text: 9 },
  { id: 10, text: "vide" },
  { id: 11, text: 0 },
  { id: 12, text: <Delete /> },
];
