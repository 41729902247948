import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { VehiculeRoutes } from "./VehiculeRoutes";

const FeatureVehicule = {
  VehiculePage: React.lazy(() => import("pages/Vehicule/views/VehiculePage")),
  AjoutUsageVehiculePage: React.lazy(
    () => import("pages/Vehicule/views/AjoutUsageVehiculePage")
  ),
  VehiculeDisponiblePage: React.lazy(
    () => import("pages/Vehicule/views/VehiculeDisponiblePage")
  ),
  UsagerVehiculPage: React.lazy(
    () => import("pages/Vehicule/views/UsagerVehiculPage")
  ),
  SuccessVehiculePage: React.lazy(
    () => import("pages/Vehicule/views/SuccessVehiculePage")
  ),
  AjoutActionVehiculePage: React.lazy(
    () => import("pages/Vehicule/views/ActionUsageVehiculePage")
  ),
  AjoutVehiculePage: React.lazy(
    () => import("pages/Vehicule/views/AjoutVehiculePage")
  ),
  ModifierVehiculePage: React.lazy(
    () => import("pages/Vehicule/views/ModifierVehiculePage")
  ),
  AttribuerVehiculePage: React.lazy(
    () => import("pages/Vehicule/views/AttribuerVehiculePage")
  ),
};

export const VehiculeAppRoutes = () => (
  <Routes>
    <Route key={"VehiculeRoutes"} element={<RequireAuth />}>
      <Route
        path={VehiculeRoutes.toVehicule()}
        element={<FeatureVehicule.VehiculePage />}
      />
      <Route
        path={VehiculeRoutes.toAddVehicule()}
        element={<FeatureVehicule.AjoutUsageVehiculePage />}
      />
      <Route
        path={VehiculeRoutes.toVehiculeDisponible()}
        element={<FeatureVehicule.VehiculeDisponiblePage />}
      />
      <Route
        path={VehiculeRoutes.toUsagerVehicule()}
        element={<FeatureVehicule.UsagerVehiculPage />}
      />
      <Route
        path={VehiculeRoutes.toAddActionUsageVehicule()}
        element={<FeatureVehicule.AjoutActionVehiculePage />}
      />
      <Route
        path={VehiculeRoutes.toSuccessVehicule()}
        element={<FeatureVehicule.SuccessVehiculePage />}
      />
      <Route
        path={VehiculeRoutes.toAddNewVehicule()}
        element={<FeatureVehicule.AjoutVehiculePage />}
      />
      <Route
        path={VehiculeRoutes.toUpdateVehicule()}
        element={<FeatureVehicule.ModifierVehiculePage />}
      />
      <Route
        path={VehiculeRoutes.toAttribuerVehicule()}
        element={<FeatureVehicule.AttribuerVehiculePage />}
      />
    </Route>
  </Routes>
);
