import DepannageIcon from "components/elements/icons/DepannageIcon";
import LavageIcon from "components/elements/icons/LavageIcon";
import LocationIcon from "components/elements/icons/LocationIcon";
import TaxiIcon from "components/elements/icons/TaxiIcon";
import { Service } from "enums";
import { ServiceType } from "interfaces";
import { AccueilRoutes } from "pages";

export const mesServices = [
  {
    id: 1,
    text: "VTC",
    icon: <LocationIcon />,
    type: Service.VTC,
    status: "Activer",
  },
  {
    id: 2,
    text: "Taxi",
    icon: <TaxiIcon />,
    type: Service.TAXI,
    status: "Activer",
  },
  {
    id: 3,
    text: "Belee cash",
    type: Service.BELEE_CASH,
    icon: (
      <img src="/images/services/cash.png" className="w-[80px]" alt="test" />
    ),
    status: "Activer",
  },
  // {
  //   id: 4,
  //   text: "Dépannage",
  //   icon: <DepannageIcon />,
  //   type: Service.DEPANNAGE,
  //   status: "Non disponible",
  // },
];

export const typeServices: ServiceType[] = [
  {
    id: 2,
    description: "Commander un vtc",
    icon: (
      <img src="./images/services/Beee-VTC.png" className="w-[80px]" alt="" />
    ),
    type: Service.LOCATION_VEHICULE_AVEC_CHAUFFEUR,
    link: AccueilRoutes.toSaisirAdresse("VTC"),
  },
  {
    id: 1,
    description: "Commander un taxi",
    icon: (
      <img src="/images/services/Belee-Taxi.png" className="w-[84px]" alt="" />
    ),
    more: "Service indisponible",
    // link: AccueilRoutes.toSaisirAdresse("TAXI"),
    type: Service.TAXI,
  },
  {
    id: 3,
    description: "Service d'hygiène",
    icon: (
      <img
        src="/images/services/Belee-cleanL.png"
        className="w-[80px]"
        alt=""
      />
    ),
    more: "Service indisponible",
    type: Service.LOCATION_VEHICULE_SANS_CHAUFFEUR,
  },
  {
    id: 4,
    description: "Transport de Colis",
    icon: (
      <img src="/images/services/BeleeClub.png" className="w-[80px]" alt="" />
    ),
    more: "Service indisponible",
    type: Service.LAVAGE_AUTO,
  },
  {
    id: 5,
    description: "Location d'outils",
    icon: (
      <img src="/images/services/BeleeLoc.png" className="w-[80px]" alt="" />
    ),
    more: "Service indisponible",
    type: Service.LAVAGE_AUTO,
  },
  {
    id: 6,
    description: "Convoyage dépannage de véhicules",
    icon: (
      <img src="/images/services/BeleeAuto.png" className="w-[80px]" alt="" />
    ),
    more: "Service indisponible",
    type: Service.LAVAGE_AUTO,
  },

  // {
  //   id: 5,
  //   description: "Faire dépanner mon véhicule",
  //   icon: <DepannageIcon />,
  //   type: Service.DEPANNAGE,
  // },
];
