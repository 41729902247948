const Logo = () => {
  return (
    <svg
      width="164"
      height="68"
      viewBox="0 0 164 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <path
        d="M0.367188 8.86963H7.55086C18.3264 8.86963 28.7753 11.8262 28.7753 24.6377C28.7753 28.9083 26.4896 33.8358 22.5713 35.4783C29.4284 36.7923 33.9998 43.3624 33.9998 50.2609C33.9998 61.7585 24.2039 66.3575 14.0815 66.3575H0.693719V8.86963H0.367188ZM7.55086 34.4928H9.18351C16.0407 34.4928 21.5917 33.1788 21.5917 24.9662C21.5917 16.7537 16.0407 15.4397 9.51005 15.4397H7.55086V34.4928ZM7.55086 60.4445H13.1019C19.6325 60.4445 26.8162 58.4735 26.8162 50.2609C26.8162 42.0484 18.6529 40.4059 12.1223 40.4059H7.55086V60.4445Z"
        fill="white"
      />
      <path
        d="M45.1032 49.9326C45.1032 56.1741 49.3481 61.7587 55.8788 61.7587C61.1032 61.7587 64.042 59.1306 66.6543 54.8601L72.5318 58.1451C69.2665 64.3867 63.0624 68.0002 55.8788 68.0002C45.1032 68.0002 38.2461 59.7877 38.2461 48.9471C38.2461 37.778 44.1236 29.5654 55.5522 29.5654C66.9808 29.5654 72.2053 38.1065 72.2053 48.6186V49.6041H45.1032V49.9326ZM65.3481 44.6765C64.3685 39.4205 61.1032 35.807 55.5522 35.807C50.3277 35.807 46.0828 39.749 45.4298 44.6765H65.3481Z"
        fill="white"
      />
      <path d="M85.2634 66.686H78.4062V0H85.2634V66.686Z" fill="white" />
      <path
        d="M99.3064 49.9326C99.3064 56.1741 103.551 61.7587 110.082 61.7587C115.306 61.7587 118.245 59.1306 120.857 54.8601L126.735 58.1451C123.47 64.3867 117.266 68.0002 110.082 68.0002C99.3064 68.0002 92.4492 59.7877 92.4492 48.9471C92.4492 37.778 98.3268 29.5654 109.755 29.5654C121.184 29.5654 126.408 38.1065 126.408 48.6186V49.6041H99.3064V49.9326ZM119.551 44.6765C118.572 39.4205 115.306 35.807 109.755 35.807C104.531 35.807 100.286 39.749 99.6329 44.6765H119.551Z"
        fill="white"
      />
      <path
        d="M136.529 49.9326C136.529 56.1741 140.774 61.7587 147.305 61.7587C152.529 61.7587 155.468 59.1306 158.08 54.8601L163.958 58.1451C160.692 64.3867 154.488 68.0002 147.305 68.0002C136.529 68.0002 129.672 59.7877 129.672 48.9471C129.672 37.778 135.549 29.5654 146.978 29.5654C158.407 29.5654 163.631 38.1065 163.631 48.6186V49.6041H136.529V49.9326ZM156.774 44.6765C155.794 39.4205 152.529 35.807 146.978 35.807C141.754 35.807 137.509 39.749 136.856 44.6765H156.774Z"
        fill="white"
      />
    </svg>
  );
};

export default Logo;
