import { AddressDataType, AddressType } from "interfaces";
import { REGEX, validSIRENFormat } from "utils";

const adresseEntreprisevalidate = (values: AddressDataType) => {
  const errors: AddressDataType = {
    address: "",
    code_postale: "",
    ville: "",
  };

  if (!values.address) {
    errors.address = "L'adresse est obligatoire";
  }

  if (!values.code_postale) {
    errors.code_postale = "Le Code postale est obligatoire";
  } else if (values.code_postale.search(REGEX.CODE_POSTAL_REGEXP) === -1) {
    errors.code_postale = "Veuillez entrer un code postale valide";
  }

  if (!values.ville) {
    errors.ville = "La ville est obligatoire";
  } else if (values.ville.length < 4) {
    errors.ville = "Le nom de la ville est invalide, minimum 4 caractères";
  }

  return errors;
};

export default adresseEntreprisevalidate;
