import { InputProps } from "interfaces";
import React, { useState } from "react";

const IconeFlag = ({
  positionClass,
  src,
  width,
  ...inputProps
}: InputProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  any): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="flex flex-col justify-start">
      <div className="">
        {" "}
        <img
          className={` ${positionClass} ${width ? width : "w-[40px]"}`}
          src={src}
          alt=""
        />{" "}
      </div>
    </div>
  );
};

export default IconeFlag;
