import { InputProps } from "interfaces";
import React, { useState } from "react";

const InputFile = ({
  icon,
  inputSize = "h-12",
  rightIcon,
  label,
  bgColor = "bg-[#292929]",
  borderColor = "border-[#444444]",
  addClass,
  rounded = "rounded-md ",
  noShadow = false,
  setTouched,
  error,
  name,
  value,
  onChange,
  textRight,
  id,
  touched,
  ...inputProps
}: InputProps & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="flex flex-col">
      {label && (
        <label className={"mb-2 text-sm text-black font-light " + textRight}>
          {label}
        </label>
      )}
      <div className="relative rounded-md">
        <input
          {...inputProps}
          type="file"
          id={id}
          className="hidden"
          name={name}
          onChange={onChange}
        />
        <label
          htmlFor={id}
          onClick={() => {
            setFocused(true);
            // if (setTouched && name)
            //   setTouched((prevTouched?: any) => ({
            //     ...prevTouched,
            //     [name]: true,
            //   }));
          }}
        >
          <span
            id="file-name"
            className={`w-full pl-4 ${inputSize} overflow-hidden mr-8 text-gray-700 text-md ${addClass}  ${bgColor}  ${
              error && touched
                ? " border border-red-500 "
                : focused
                ? " border border-orange-belee "
                : borderColor
            } flex justify-start items-center space-x-4 ${rounded} ${
              noShadow ? "" : "shadow-sm shadow-dark-belee "
            } `}
          >
            {value}
          </span>
          <span className="absolute p-4 top-0 right-0 cursor-pointer">
            {rightIcon && rightIcon}
          </span>
        </label>
      </div>
      {error && touched && <p className="text-xs text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default InputFile;
