/* eslint-disable react-hooks/exhaustive-deps */
import {
  createEntreprise,
  EntrepriseData,
  getEntrepriseDetailByIdAPI,
  updateEntreprise,
} from "api";
import { getEntrepriseDetails } from "api/pappers";
import { AlertKind } from "components";
import Alert from "components/elements/alerts/Alert";
import Button from "components/elements/buttons/Button";
import Input from "components/elements/inputs/Input";
import InputFile from "components/elements/inputs/InputFile";
import { isEmpty, useAuth, useForm, useLocalStorage } from "hooks";
import infoEntrepriseValidate from "pages/Accounts/utils/infoEntrepriseValidate";
import updateEntrepriseValidate from "pages/Accounts/utils/updateEntrepriseValidate";
import { AccueilRoutes } from "pages/Accueil";
import TVAItem from "../../../pages/Banques/Transfert/components/TVAItem";
import { tvas } from "../../../pages/Banques/Transfert/utils";
import {
  getParametreAPI,
  getParametreTvaEntrepriseAPI,
  setParamettreTvaEntrepriseAPI,
} from "api";
import { useEffect, useState } from "react";
import { Check, Edit2, Info } from "react-feather";
import Modal from "../modals/Modal";
import AppLayout from "components/layouts/AppLayout";
import BackButton from "../buttons/BackButton";
import { useNavigate } from "react-router-dom";

type Props = {
  idUser?: string;
  actionDone: (response: any) => void;
  showSuccess?: boolean;
};

const InforEntrepriseForm = ({
  idUser,
  actionDone,
  showSuccess = true,
}: Props) => {
  const { user, isAdmin, isAuthenticated, authEntreprise, refreshAuth } =
    useAuth();
  const [error, setError] = useState<string>();
  // const [isSuccess, setIsSuccess] = useState<any>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isEntrepriseEnAttenteValide, setEntrepriseEnATttenteValide] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<string>(tvas[0].value);
  const [mesTva, setMesTva] = useState<any[]>([]);
  const { userEntreprise } = useAuth();
  const [messageModal, setMessageModal] = useState<string>();
  const navigate = useNavigate();
  const [notEditable, setNotEditable] = useState<boolean>(false);
  const [isShow, setIshow] = useState<boolean>(false);
  let initialisations = {
    siren: "",
    name: "",
    address: "",
    code_postale: "",
    ville: "",
    kbis: "",
  };

  const [defaultValues, setDefaultValues] = useLocalStorage<EntrepriseData>(
    "entreprise_option" + (user ? user.id : ""),
    initialisations
  );

  let initialValues: EntrepriseData = {
    siren: "",
    name: "",
    address: "",
    code_postale: "",
    ville: "",
    kbis: "",
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setTouched,
    setValues,
    valid,
    pristine,
    submitting,
  } = useForm<any>({
    initialValues,
    validate: authEntreprise
      ? updateEntrepriseValidate
      : infoEntrepriseValidate,
  });

  const getEntrepriseById = async (idEntreprise: any) => {
    const response = await getEntrepriseDetailByIdAPI(idEntreprise);

    if (typeof response !== "string" && response != undefined) {
      console.log("resp", response);
      console.log("resp.etat", response.etat);
      if (response.etat == "EN_ATTENTE") {
        setEntrepriseEnATttenteValide(true);
      }

      setValues((prevValues: any) => ({
        ...prevValues,
        name: response.name,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        siren: response.siren,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        address: response.address,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        code_postale: response.code_postale,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        ville: response.ville,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        kbis: response.kbis.substring(response.kbis.lastIndexOf("/") + 1),
      }));

      setNotEditable(true);
      // setValues(newValues);
      // setDefaultValues(initialisations);
      return;
    }
  };

  const onsubmit = async (values: EntrepriseData) => {
    if (idUser) {
      let response: any = {};

      let data = { ...values, tvaEntreprise: selected };

      if (authEntreprise) {
        response = await updateEntreprise(authEntreprise.slug, data);
        console.log(response);

        if (typeof response !== "string") {
          setTouched({});
          // await refreshAuth();
          actionDone(response);
          setMessageModal(`Les paramètres ont été mis à jour avec succès.
          Nous allons les prendre en compte sur vos prochaines factures.`);
          setIshow(false);
          setIsSuccess(true);
          setTimeout(() => {
            window.location.href = "/";
          }, 3000);
          return;
        } else {
          setValues(initialValues);
          setError(response);
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (authEntreprise) {
      getEntrepriseById(authEntreprise._id);
    }
  }, [values.siren, errors.siren]);

  useEffect(() => {
    let auth = localStorage.getItem("bappauth");
    if (auth) {
      // console.log(auth);
      //   let data = JSON.parse(auth);
      //   getEntrepriseById(data.entreprise._id);
    }
  }, []);

  const getFileName = (value: any, fieldName: string) => {
    if (value) {
      console.log("value", value);
      if (typeof value === "string") {
        return value;
      } else {
        return value.name;
      }
    } else {
      if (authEntreprise && user?.entreprise && user?.entreprise?.[fieldName]) {
        let filename = user?.entreprise?.[fieldName] as string;
        return filename.replace(
          "/uploads/entreprises/" + authEntreprise.slug + "/",
          ""
        );
      } else {
        return "";
      }
    }
  };

  const getTva = async () => {
    const response = await getParametreAPI("France");
    if (typeof response != "string") {
      const tvaArray = [
        { id: "tva_1", value: response.tva.tva_1 },
        { id: "tva_2", value: response.tva.tva_2 },
        { id: "tva_3", value: response.tva.tva_3 },
        { id: "tva_4", value: response.tva.tva_4 },
        { id: "tva_5", value: response.tva.tva_5 },
      ];
      setMesTva(tvaArray);
    }
  };

  const getTvaSelected = async () => {
    if (userEntreprise) {
      const response = await getParametreTvaEntrepriseAPI(userEntreprise?._id);
      if (response.message && response.message == "Resultat trouvé") {
        setSelected(response.tvaEntreprise.tvaEntreprise);
      }
    }
  };

  const onsubmitTva = async () => {
    if (selected != "0") {
      if (userEntreprise) {
        const response = await setParamettreTvaEntrepriseAPI(
          userEntreprise._id,
          selected
        );
        if (response.message) {
          setMessageModal(`Les paramètres ont été mis à jour avec succès.
          Nous allons les prendre en compte sur vos prochaines factures.`);
          setIshow(false);
          setIsSuccess(true);
          setTimeout(() => {
            navigate(AccueilRoutes.toHomePage());
          }, 3000);
        }
      }
    }
  };

  useEffect(() => {
    getTva();
    getTvaSelected();
  }, []);

  return (
    <>
      <div className="mx-3 lg:w-[1000px] md:w-[700px] lg:mx-auto md:mx-auto bg-white lg:mt-32 md:mt-20 mt-28 lg:mb-48 md:mb-10 mb-5 py-10 rounded-[30px] lg:px-10 md:px-10 px-5 shadow-xl bg-opacity-80">
        <h2 className="text-center text-2xl font-semibold text-orange-belee">
          Votre entreprise
        </h2>

        {isSuccess && showSuccess && (
          <Alert kind={AlertKind.success}>{isSuccess}</Alert>
        )}
        {error && (
          <div className="flex justify-start items-center space-x-4 text-red-500 pb-6">
            <Info />
            <p className="text-sm ">{error}</p>
          </div>
        )}

        <div className="lg:grid md:grid grid-cols-2 gap-6 pt-3">
          <div className="col-span-1 space-y-2">
            <Input
              label="Siren"
              type="text"
              name="siren"
              onChange={handleChange}
              value={values.siren}
              required
              error={errors.siren}
              touched={touched.siren}
              onBlur={handleBlur}
              disabled={isAuthenticated && !isAdmin}
              bgColor="bg-gray-300 shadow-lg"
              noBorder
            />
            <Input
              label="Nom de l'entreprise"
              type="text"
              name="name"
              onChange={handleChange}
              value={values.name}
              required
              error={errors.name}
              touched={touched.name}
              disabled={(isAuthenticated && !isAdmin) || notEditable}
              onBlur={handleBlur}
              bgColor="bg-gray-300 shadow-lg"
              noBorder
            />
            <Input
              label="Adresse"
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address}
              required
              error={errors.address}
              touched={touched.address}
              disabled={(isAuthenticated && !isAdmin) || notEditable}
              onBlur={handleBlur}
              bgColor="bg-gray-300 shadow-lg"
              noBorder
            />
            <Input
              label="Code postale"
              type="text"
              name="code_postale"
              onChange={handleChange}
              value={values.code_postale}
              required
              error={errors.code_postale}
              touched={touched.code_postale}
              disabled={(isAuthenticated && !isAdmin) || notEditable}
              onBlur={handleBlur}
              bgColor="bg-gray-300 shadow-lg"
              noBorder
            />
            <Input
              label="Ville"
              name="ville"
              type="text"
              onChange={handleChange}
              value={values.ville}
              required
              error={errors.ville}
              touched={touched.ville}
              onBlur={handleBlur}
              disabled={(isAuthenticated && !isAdmin) || notEditable}
              bgColor="bg-gray-300 shadow-lg"
              noBorder
            />
          </div>

          <div className="col-span-1  space-y-2">
            <InputFile
              label="Kbis de moins de 3 mois"
              name="kbis"
              id="kbis"
              onChange={handleChange}
              error={errors.kbis}
              touched={touched.kbis}
              // value={values.kbis}
              value={getFileName(values.kbis, "kbis")}
              onBlur={handleBlur}
              setTouched={setTouched}
              rightIcon={<Edit2 size={18} className="cursor-pointer" />}
              bgColor="bg-gray-300 shadow-lg"
              noBorder
              noShadow
            />

            <div className="flex flex-col space-y-5 pt-2">
              <p className="text-orange-belee font-medium ">
                Votre taux de TVA
              </p>
              <div className="flex flex-col space-y-3">
                {mesTva &&
                  mesTva.map((tva: any) => (
                    <TVAItem
                      text={tva.value}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ))}
              </div>
            </div>

            <div className="flex justify-center">
              {isEntrepriseEnAttenteValide === true ? (
                <button className="mt-8 lg:mt-14 px-10 text-center text-sm md:text-lg lg:text-lg font-base md:font-medium lg:font-medium text-white rounded-full bg-orange-belee py-3 shadow-xl hover:shadow-none">
                  Entreprise en attente de validation
                </button>
              ) : (
                <button
                  disabled
                  className="mt-8 lg:mt-14 px-10 text-center text-sm md:text-lg lg:text-lg font-base md:font-medium lg:font-medium text-white rounded-full bg-orange-belee py-3 shadow-xl hover:shadow-none"
                  onClick={handleSubmit(onsubmit)}
                >
                  Valider
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isShow={isSuccess}
        closeModal={() => setIsSuccess(false)}
        onClose={setIsSuccess}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-16 h-16 bg-white text-green-belee rounded-full">
            <Check size={35} />
          </div>
          <p className="text-xl mt-2">{messageModal}</p>
        </div>
      </Modal>

      {/* <Modal
        isShow={isEntrepriseEnAttenteValide}
        closeModal={() => navigate(AccueilRoutes.toHomePage())}
        onClose={() => navigate(AccueilRoutes.toHomePage())}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-16 h-16 bg-white text-green-belee rounded-full">
            <Check size={35} />
          </div>
          <p className="text-xl mt-2">Entreprise en attente de validation</p>
        </div>
      </Modal> */}
    </>
  );
};

export default InforEntrepriseForm;
