import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotRequireAuth, RequireAuth } from "routes";
import { PrestationRoutes } from "./PrestationRoutes";

const FeatureUser = {
  PrestationDisponible: React.lazy(
    () => import("pages/Prestation/views/PrestationPage")
  ),
  AllPrestation: React.lazy(
    () => import("pages/Prestation/views/AllPrestationAvailablePage")
  ),
  PrestationDetails: React.lazy(
    () => import("pages/Prestation/views/PrestationDetailsPage")
  ),
  PrestationDetailsPublic: React.lazy(
    () => import("pages/Prestation/views/PrestationDetailPublicPage")
  ),
  AllPrestationDetails: React.lazy(
    () => import("pages/Prestation/views/AllPrestationPage")
  ),
  GetPrestation: React.lazy(
    () => import("pages/Prestation/views/getPrestationPage")
  ),
  MesPrestations: React.lazy(
    () => import("pages/Prestation/views/MesPrestationsPage")
  ),
  ChoixGuidePage: React.lazy(
    () => import("pages/Prestation/views/ChoixGuidePage")
  ),
  NotificationCommande: React.lazy(
    () => import("pages/Prestation/views/NotificationCommandeImminantPage")
  ),
  PrestationIgnoree: React.lazy(
    () => import("pages/Prestation/views/NotificationPrestationIGnoreePage")
  ),
  PrestataireSurPlace: React.lazy(
    () => import("pages/Prestation/views/PrestataireSurPlacePage")
  ),
  PrestataireEnPlace: React.lazy(
    () => import("pages/Prestation/views/PrestataireEnRoutePage")
  ),
  PrestationAbandonnee: React.lazy(
    () => import("pages/Prestation/views/PrestationAbandonnee")
  ),
  PrestataireMuet: React.lazy(
    () => import("pages/Prestation/views/PrestataireMuet")
  ),
  ClientAbsent: React.lazy(() => import("pages/Prestation/views/ClientAbsent")),
  FraisClientAbsent: React.lazy(
    () => import("pages/Prestation/views/FraisClientAbsentPage")
  ),
  ClientABord: React.lazy(
    () => import("pages/Prestation/views/ClientABordPage")
  ),
  SuccessPrestationPage: React.lazy(
    () => import("pages/Prestation/views/SuccessPrestationPage")
  ),
  PrestationInterrompue: React.lazy(
    () => import("pages/Prestation/views/PrestationInterrompue")
  ),
  PrestationAnnulee: React.lazy(
    () => import("pages/Prestation/views/PrestationAnnuler")
  ),
  PrestationAnnulerParReservant: React.lazy(
    () =>
      import(
        "pages/Prestation/views/NotificationPrestationAnnulerParReservantPage"
      )
  ),
  PrestationAnnuleeFrais: React.lazy(
    () => import("pages/Prestation/views/PrestationAnnuler")
  ),
  // toPrestationAnnuleeFrais
  NotificationPrestationAnnuler: React.lazy(
    () => import("pages/Prestation/views/NotificationPrestationAnnulerPage")
  ),
  CommentairePrestataireAboutLitigePage: React.lazy(
    () => import("pages/Prestation/views/CommentairePrestataireAboutLitigePage")
  ),
  ChoixGuidTrajetCommandePage: React.lazy(
    () => import("pages/Prestation/views/ChoixGuidTrajetCommandePage")
  ),
};

export const PrestationAppRoutes = () => (
  <Routes>
    <Route key={"PrestationRoutes"} element={<RequireAuth />}>
      <Route
        path={PrestationRoutes.toPrestationDisponible()}
        element={<FeatureUser.PrestationDisponible />}
      />
      <Route
        path={PrestationRoutes.toPrestationDetails()}
        element={<FeatureUser.PrestationDetails />}
      />
      <Route
        path={PrestationRoutes.toGetPrestation()}
        element={<FeatureUser.GetPrestation />}
      />

      <Route
        path={PrestationRoutes.toChoixGuidePage()}
        element={<FeatureUser.ChoixGuidePage />}
      />
      <Route
        path={PrestationRoutes.toChoixGuidTrajetCommande()}
        element={<FeatureUser.ChoixGuidTrajetCommandePage />}
      />
      <Route
        path={PrestationRoutes.toMesPrestations()}
        element={<FeatureUser.MesPrestations />}
      />
      <Route
        path={PrestationRoutes.toNotificationCommande()}
        element={<FeatureUser.NotificationCommande />}
      />

      <Route
        path={PrestationRoutes.toPrestationIgnoree()}
        element={<FeatureUser.PrestationIgnoree />}
      />
      <Route
        path={PrestationRoutes.toPrestataireSurPlace()}
        element={<FeatureUser.PrestataireSurPlace />}
      />
      <Route
        path={PrestationRoutes.toPrestataireEnRoute()}
        element={<FeatureUser.PrestataireEnPlace />}
      />
      <Route
        path={PrestationRoutes.toPrestationAbandonnee()}
        element={<FeatureUser.PrestationAbandonnee />}
      />
      <Route
        path={PrestationRoutes.toClientABord()}
        element={<FeatureUser.ClientABord />}
      />
      <Route
        path={PrestationRoutes.toPrestationInterrompue()}
        element={<FeatureUser.PrestationInterrompue />}
      />
      <Route
        path={PrestationRoutes.toSuccessPrestationPage()}
        element={<FeatureUser.SuccessPrestationPage />}
      />
      <Route
        path={PrestationRoutes.toPrestationAnnulee()}
        element={<FeatureUser.PrestationAnnulee />}
      />
      <Route
        path={PrestationRoutes.toPrestationAnnuleeParReservant()}
        element={<FeatureUser.PrestationAnnulerParReservant />}
      />
      <Route
        path={PrestationRoutes.toNotificationPrestationAnnuler()}
        element={<FeatureUser.NotificationPrestationAnnuler />}
      />
      <Route
        path={PrestationRoutes.toClientAbsent()}
        element={<FeatureUser.ClientAbsent />}
      />
      <Route
        path={PrestationRoutes.toAllPrestationDisponible()}
        element={<FeatureUser.AllPrestationDetails />}
      />
      <Route
        path={PrestationRoutes.toFraisClientAbsent()}
        element={<FeatureUser.FraisClientAbsent />}
      />
      <Route
        path={PrestationRoutes.toPrestataireMuet()}
        element={<FeatureUser.PrestataireMuet />}
      />
      <Route
        path={PrestationRoutes.toCommentairePrestataireAboutLitige()}
        element={<FeatureUser.CommentairePrestataireAboutLitigePage />}
      />
      {/* 
      {/* <Route
        path={UserRoutes.toDetailsUser()}
        element={<FeatureUser.DetailsUtilisateurPage />}
      />
      <Route
        path={UserRoutes.toAddUser()}
        element={<FeatureUser.AjoutUtilisateurPage />}
      /> */}
    </Route>

    <Route key={"PrestationRoutes"} element={<NotRequireAuth />}>
      <Route
        path={PrestationRoutes.toAllPrestation()}
        element={<FeatureUser.AllPrestation />}
      />
      <Route
        path={PrestationRoutes.toPrestationPublicDetail()}
        element={<FeatureUser.PrestationDetailsPublic />}
      />
    </Route>
  </Routes>
);
