import { MenuChildType, MenuType } from "interfaces";
import {
  AccountRoutes,
  AccueilRoutes,
  BanqueRoutes,
  CommandeRoutes,
  CompteRoutes,
  InterfaceBlobalRoutes,
  UserRoutes,
  VehiculeRoutes,
} from "pages";
import { FactureRoutes } from "pages/Factures/FactureRoutes";
import { PrestationRoutes } from "pages/Prestation";
import { Users } from "react-feather";

export const userMenuChild: MenuChildType[] = [
  {
    id: 1,
    text: "Profil",
    link: CompteRoutes.toProfile(),
  },
  {
    id: 2,
    text: "Entreprise",
    link: CompteRoutes.toInfoEntreprise(),
  },
  {
    id: 3,
    text: "Services",
    link: CompteRoutes.toMesService(),
  },
];

// menuleft l'entreprise est validé (user type PRO)
export const menus: MenuType[] = [
  {
    id: 1,
    text: "Accueil",
    icon: (
      <img src="/images/pictos-orange/home.svg" className="w-[36px]" alt="" />
    ),
    link: AccueilRoutes.toHomePage(),
  },
  {
    id: 2,
    text: "Compte",
    link: CompteRoutes.toProfile(),
    icon: (
      <img src="/images/pictos-orange/profil.png" className="w-[36px]" alt="" />
    ),
  },
  {
    id: 5,
    text: "Mes commandes",
    icon: (
      <img
        src="/images/pictos-orange/commandes.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: CommandeRoutes.toCommande(),
  },
  {
    id: 6,
    text: "Mon entreprise", // menu quand l'entreprise est validé
    icon: (
      <img
        src="/images/pictos-orange/entreprise.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: AccountRoutes.toInfoEntreprise(),
  },
  {
    id: 32,
    text: "Services",
    icon: (
      <img
        src="/images/pictos-orange/service.png"
        className="w-[40px]"
        alt=""
      />
    ),
    link: CompteRoutes.toMesService(),
  },
  {
    id: 4,
    text: "Véhicules",
    icon: (
      <img src="/images/pictos-orange/car.png" className="w-[40px]" alt="" />
    ),
    link: VehiculeRoutes.toAddActionUsageVehicule(),
  },
  {
    id: 3,
    text: "Utilisateurs",
    icon: <Users className="text-orange-belee" />,
    link: UserRoutes.toUsers(),
    // access: "ADMIN",
  },
  {
    id: 9,
    text: "Agenda",
    icon: (
      <img
        src="/images/pictos-orange/calendrier.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: CompteRoutes.toPlaning(),
  },
  {
    id: 10,
    text: "Banque",
    icon: (
      <img src="/images/pictos-orange/banque.svg" className="w-[36px]" alt="" />
    ),
    link: BanqueRoutes.toBanquePage(),
  },
  {
    id: 31,
    text: "Factures",
    icon: (
      <img
        src="/images/pictos-orange/facture.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: FactureRoutes.toFacturePage(),
  },
  {
    id: 11,
    text: "Favoris",
    icon: (
      <img
        src="/images/pictos-orange/favoris.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: UserRoutes.toListeFavoris(),
  },
  // {
  //   id: 12,
  //   text: "Sites",
  //   icon: <Link className="text-gray-400" />,
  //   link: SiteRoutes.toHomeSite(),
  // },
  {
    id: 13,
    text: "Choix des langues",
    icon: (
      <img
        src="/images/pictos-orange/langues.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: InterfaceBlobalRoutes.toLangue(),
  },
  {
    id: 14,
    text: "Support",
    icon: (
      <img
        src="/images/pictos-orange/parametres.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: InterfaceBlobalRoutes.toSupport(),
  },
  {
    id: 15,
    text: "Déconnexion",
    icon: (
      <img
        src="/images/pictos-orange/deconnexion.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
];

export const menuFactoryUnavailable: MenuType[] = [
  {
    id: 1,
    text: "Accueil",
    icon: (
      <img src="/images/pictos-orange/home.svg" className="w-[36px]" alt="" />
    ),
    link: AccueilRoutes.toHomePage(),
  },
  {
    id: 2,
    text: "Compte",
    icon: (
      <img src="/images/pictos-orange/profil.png" className="w-[36px]" alt="" />
    ),
    link: CompteRoutes.toProfile(),
  },
  {
    id: 3,
    text: "Mes prestations",
    icon: (
      <img
        src="/images/pictos-orange/commandes.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: PrestationRoutes.toMesPrestations(),
  },
  {
    id: 4,
    text: "Mes commandes",
    icon: (
      <img
        src="/images/pictos-orange/commandes.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: CommandeRoutes.toCommande(),
  },
  {
    id: 5,
    icon: (
      <img
        src="/images/pictos-orange/entreprise.png"
        className="w-[36px]"
        alt=""
      />
    ),
    text: "Entreprise",
    link: CompteRoutes.toInfoEntreprise(),
  },
  {
    id: 6,
    text: "Banque",
    icon: (
      <img src="/images/pictos-orange/banque.svg" className="w-[36px]" alt="" />
    ),
    link: BanqueRoutes.toBanquePage(),
  },
  {
    id: 31,
    text: "Factures",
    icon: (
      <img
        src="/images/pictos-orange/facture.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: FactureRoutes.toFacturePage(),
  },
  {
    id: 7,
    text: "Favoris",
    icon: (
      <img
        src="/images/pictos-orange/favoris.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: UserRoutes.toListeFavoris(),
  },
  {
    id: 8,
    text: "Choix des langues",
    icon: (
      <img
        src="/images/pictos-orange/langues.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: InterfaceBlobalRoutes.toLangue(),
  },
  {
    id: 9,
    text: "Supportb",
    icon: (
      <img
        src="/images/pictos-orange/parametres.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: InterfaceBlobalRoutes.toSupport(),
  },
  {
    id: 10,
    text: "Déconnexion",
    icon: (
      <img
        src="/images/pictos-orange/deconnexion.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
];

// si l'utilisateur est de type BASIC
export const menuClient: MenuType[] = [
  {
    id: 1,
    text: "Accueil",
    icon: (
      <img src="/images/pictos-orange/home.svg" className="w-[36px]" alt="" />
    ),
    link: AccueilRoutes.toHomePage(),
  },
  {
    id: 2,
    text: "Compte",
    icon: (
      <img src="/images/pictos-orange/profil.png" className="w-[36px]" alt="" />
    ),
    link: CompteRoutes.toProfile(),
    // child: userMenuChild,
  },
  {
    id: 5,
    text: "Mes commandes",
    icon: (
      <img
        src="/images/pictos-orange/commandes.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: CommandeRoutes.toCommande(),
  },
  {
    id: 6,
    text: "Creer entreprise", // "Créer entreprise"
    icon: (
      <img
        src="/images/pictos-orange/entreprise.png"
        className="w-[36px]"
        alt=""
      />
    ),
    // link: AccountRoutes.toInfoEntreprise(), // AccountRoutes.toCreateEntreprise()
    link: AccountRoutes.toCreateEntreprise(),
  },
  {
    id: 7,
    text: "Banque",
    icon: (
      <img src="/images/pictos-orange/banque.svg" className="w-[36px]" alt="" />
    ),
    link: BanqueRoutes.toBanquePage(),
  },
  {
    id: 31,
    text: "Factures",
    icon: (
      <img
        src="/images/pictos-orange/facture.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: FactureRoutes.toFacturePage(),
  },
  {
    id: 8,
    text: "Favoris",
    icon: (
      <img
        src="/images/pictos-orange/favoris.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: UserRoutes.toListeFavoris(),
  },
  {
    id: 9,
    text: "Choix des langues",
    icon: (
      <img
        src="/images/pictos-orange/langues.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: InterfaceBlobalRoutes.toLangue(),
  },
  {
    id: 10,
    text: "Support",
    icon: (
      <img
        src="/images/pictos-orange/parametres.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: InterfaceBlobalRoutes.toSupport(),
  },
  {
    id: 11,
    text: "Déconnexion",
    icon: (
      <img
        src="/images/pictos-orange/deconnexion.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
];

//new design
export const menuMobile: MenuType[] = [
  {
    id: 1,
    link: "",
    text: "Accueil",
    icon: (
      <img src="/images/pictos-orange/home.svg" className="w-[36px]" alt="" />
    ),
  },
  {
    id: 2,
    text: "Compte",
    icon: (
      <img src="/images/pictos-orange/profil.png" className="w-[36px]" alt="" />
    ),
    link: "",
  },
  {
    id: 4,
    text: "Entreprise",
    icon: (
      <img
        src="/images/pictos-orange/entreprise.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: VehiculeRoutes.toVehicule(),
  },
  {
    id: 4,
    text: "Véhicules",
    icon: (
      <img src="/images/pictos-orange/car.png" className="w-[36px]" alt="" />
    ),
    link: "",
  },
  {
    id: 6,
    text: "Mes commandes",
    icon: (
      <img
        src="/images/pictos-orange/commandes.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
  {
    id: 7,
    text: "Banque",
    icon: (
      <img src="/images/pictos-orange/banque.svg" className="w-[36px]" alt="" />
    ),
    link: BanqueRoutes.toBanquePage(),
  },
  {
    id: 8,
    text: "Favoris",
    icon: (
      <img
        src="/images/pictos-orange/favoris.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
  {
    id: 9,
    text: "Choix des langues",
    icon: (
      <img
        src="/images/pictos-orange/langues.png"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
  {
    id: 10,
    text: "Support",
    icon: (
      <img
        src="/images/pictos-orange/parametres.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: InterfaceBlobalRoutes.toSupport(),
  },
  {
    id: 11,
    text: "Déconnexion",
    icon: (
      <img
        src="/images/pictos-orange/deconnexion.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
];
