import { ReactNode } from "react";

type Props = {
  text: string;
  icon?: ReactNode;
  selected: string;
  setSelected: (selected: string) => void;
};
const TVAItem = ({ text, selected, setSelected, icon }: Props) => {
  return (
    <div
      className="flex relative justify-center items-center h-12 rounded-lg bg-gray-300 cursor-pointer"
      onClick={() => setSelected(text)}
    >
      <div className="absolute top-3 left-4 flex justify-center items-center w-6 h-6 rounded-full border border-orange-belee">
        {selected === text && (
          <div className="w-3 h-3 rounded-full bg-orange-belee"></div>
        )}
      </div>
      <div className="flex space-x-4">
        {icon && icon}
        <p className="text-sm">{text}</p>
      </div>
    </div>
  );
};

export default TVAItem;
