/* eslint-disable react-hooks/exhaustive-deps */
import { getCommandeAPI } from "api";

import { useEffect, useState } from "react";
import { usePaymentStore } from "store";
import { useAuth } from "./useAuth";
import { UserTypeEnum } from "enums";

export const useCommande = (commandeId?: string) => {
  const { user } = useAuth();
  const [commande, setCommande] = useState<any>();
  const [client, setClient] = useState<any>();
  const [trajet, setTrajet] = useState<any>();
  const [supplement, setSupplement] = useState<any>();
  const [serviceProviderNet, setServiceProviderNet] = useState<number>(0);
  const typePayment = usePaymentStore((state) => state.typePayment);
  const [fraisBancaireParDefaut, setFraisBancaireParDefaut] =
    useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [fraisBancaire, setFraisBancaire] = useState<number>(0);
  const [fraisInterne, setFraisInterne] = useState<number>(0);
  const [fraisPaypal, setFraisPaypal] = useState<number>(0);
  const [fraisTransfert, setFraisTransfert] = useState<number>(0);
  const [commissionFraisBelee, setCommissionFraisBelee] = useState<number>(0);
  const [commissionDispatcher, setCommissionDispatcher] = useState<number>(0);
  const [commissionPro, setCommissionPro] = useState<number>(0);
  const [fraisBancaireCommissionPro, setFraisBancaireCommissionPro] =
    useState<number>(0);

  useEffect(() => {
    const getCommande = async () => {
      if (commandeId) {
        const commande = await getCommandeAPI(commandeId);

        let frais_bancaire = 0;
        let frais_interne = 0;
        let frais_paypal = 0;
        let frais_bancaire_par_defaut = 0;

        if (typeof commande !== "string") {
          console.log(commande);
          if (
            Number(
              commande.parametre.commissions_frais.frais_bancaire_default
            ) !== 0
          ) {
            frais_bancaire_par_defaut =
              (Number(commande.trajet.totalPrice) *
                Number(
                  commande.parametre.commissions_frais.frais_bancaire_default
                )) /
                100 +
              Number(
                commande.parametre.commissions_frais.frais_bancaire_default_plus
              );
          }
          if (
            Number(commande.parametre.commissions_frais.frais_bancaire_per) !==
            0
          ) {
            frais_bancaire =
              (Number(commande.trajet.totalPrice) *
                Number(
                  commande.parametre.commissions_frais.frais_bancaire_per
                )) /
                100 +
              Number(commande.parametre.commissions_frais.frais_bancaire_plus);
          }
          if (
            Number(
              commande.parametre.commissions_frais.frais_transaction_interne
            ) !== 0
          ) {
            frais_interne =
              (Number(commande.trajet.totalPrice) *
                Number(
                  commande.parametre.commissions_frais.frais_transaction_interne
                )) /
                100 +
              Number(
                commande.parametre.commissions_frais
                  .frais_transaction_interne_plus
              );
          }
          if (
            Number(
              commande.parametre.commissions_frais.frais_transfert_paypal
            ) !== 0
          ) {
            frais_paypal =
              (Number(commande.trajet.totalPrice) *
                Number(
                  commande.parametre.commissions_frais.frais_transfert_paypal
                )) /
                100 +
              Number(
                commande.parametre.commissions_frais.frais_transfert_paypal_plus
              );
          }

          let detailPaiementCommande_raw = localStorage.getItem(
            "detailPaiementCommande"
          );

          if (detailPaiementCommande_raw) {
            let detailPaiementCommande = JSON.parse(detailPaiementCommande_raw);

            console.log(detailPaiementCommande.prix_total);
            setTotalPrice(detailPaiementCommande.prix_total);
          }

          setFraisBancaireParDefaut(frais_bancaire_par_defaut);
          setFraisBancaire(frais_bancaire);
          setFraisInterne(frais_interne);
          setFraisPaypal(frais_paypal);
          setCommande(commande);
          setTrajet(commande.trajet);
          setClient(commande.user);
          setSupplement(commande.supplement);
        }
      }
    };
    getCommande();
  }, []);

  useEffect(() => {
    if (trajet && commande && typePayment) {
      if (user?.type === UserTypeEnum.PRO) {
        let commission_dispatcher = 0;
        if (typePayment.includes("differes")) {
          commission_dispatcher = 0;
        } else {
          commission_dispatcher = Number(trajet.commission);
        }
        let commissions =
          commission_dispatcher + Number(trajet.commission_belee);
        // console.log("commission_dispatcher : ", commission_dispatcher);
        // console.log("commission_belee : ", Number(trajet.commission_belee));

        if (commissions !== 0) {
          // console.log("addition des commissions : ", commissions);
          let frais_bancaire_default =
            (commissions *
              Number(
                commande.parametre.commissions_frais.frais_bancaire_default
              )) /
              100 +
            Number(
              commande.parametre.commissions_frais.frais_bancaire_default_plus
            );

          setFraisBancaireCommissionPro(
            Number(frais_bancaire_default.toFixed(2))
          );

          let commission = Number(trajet.commission) + frais_bancaire_default;
          setCommissionPro(Number(commission.toFixed(2)));
        }
      }
    }
  }, [trajet, commande, typePayment]);

  useEffect(() => {
    if (trajet && commande && typePayment && user && totalPrice) {
      let serviceProviderNet = 0;
      if (user && user.type === "BASIC") {
        serviceProviderNet =
          Number(trajet.totalPrice) -
          Number(trajet.commission) -
          Number(trajet.commission_belee) -
          Number(trajet.tva);
      } else {
        serviceProviderNet =
          Number(trajet.totalPrice) -
          Number(trajet.commission_belee) -
          Number(trajet.tva);
      }
      let transfertFrais = 0,
        prixTotal = Number(trajet.totalPrice);

      let detailPaiementCommande_raw = localStorage.getItem(
        "detailPaiementCommande"
      );

      if (detailPaiementCommande_raw) {
        let detailPaiementCommande = JSON.parse(detailPaiementCommande_raw);
        prixTotal = detailPaiementCommande.prix_total;
      }

      if (typePayment === "payement_prestataire") {
        // pab compte basic
        serviceProviderNet = serviceProviderNet;
        setFraisTransfert(transfertFrais);
      } else if (typePayment.includes("pab")) {
        // pab compte pro
        serviceProviderNet =
          serviceProviderNet -
          Number(trajet.commission) -
          fraisBancaireParDefaut;
        // serviceProviderNet =
        // serviceProviderNet -
        // Number(trajet.commission) -
        // fraisBancaireCommissionPro;
        transfertFrais = Number(fraisBancaireParDefaut.toFixed(2));
        setFraisTransfert(transfertFrais);
      } else if (typePayment === "paypal") {
        // serviceProviderNet = serviceProviderNet - fraisPaypal;
        if (user && user.type === "BASIC") {
          serviceProviderNet = serviceProviderNet - fraisBancaireParDefaut;
        } else {
          serviceProviderNet =
            serviceProviderNet -
            fraisBancaireParDefaut -
            Number(trajet.commission);
          prixTotal = prixTotal - Number(trajet.commission);
        }
        transfertFrais = Number(fraisBancaireParDefaut.toFixed(2));
        setFraisTransfert(transfertFrais);
      } else if (typePayment === "carte_bancaire") {
        // serviceProviderNet = serviceProviderNet - fraisBancaire;
        if (user && user.type === "BASIC") {
          serviceProviderNet = serviceProviderNet - fraisBancaireParDefaut;
        } else {
          serviceProviderNet =
            serviceProviderNet -
            fraisBancaireParDefaut -
            Number(trajet.commission);
          prixTotal = prixTotal - Number(trajet.commission);
        }
        transfertFrais = Number(fraisBancaireParDefaut.toFixed(2));
        setFraisTransfert(transfertFrais);
      } else if (typePayment === "solde") {
        // serviceProviderNet = serviceProviderNet - fraisInterne;
        if (user && user.type === "BASIC") {
          serviceProviderNet = serviceProviderNet - fraisBancaireParDefaut;
        } else {
          serviceProviderNet =
            serviceProviderNet -
            fraisBancaireParDefaut -
            Number(trajet.commission);
          prixTotal = prixTotal - Number(trajet.commission);
        }
        transfertFrais = Number(fraisBancaireParDefaut.toFixed(2));
        setFraisTransfert(transfertFrais);
      } else if (typePayment.includes("differes")) {
        serviceProviderNet =
          serviceProviderNet -
          fraisBancaireParDefaut -
          Number(trajet.commission);
        transfertFrais = Number(fraisBancaireParDefaut.toFixed(2));
        setFraisTransfert(transfertFrais);
        prixTotal = prixTotal - Number(trajet.commission);
      }
      setTotalPrice(prixTotal);

      setServiceProviderNet(Number(serviceProviderNet.toFixed(2)));
    }
  }, [trajet, commande, typePayment]);

  useEffect(() => {
    // console.log("fraisTransfert", fraisTransfert);
    if (trajet) {
      let commissionEtFrais = Number(trajet.commission_belee);
      if (typePayment.includes("pab")) {
        commissionEtFrais = commissionEtFrais + fraisTransfert;
        // setCommissionDispatcher(Number(trajet.commission));
        setCommissionFraisBelee(commissionEtFrais);
      } else if (typePayment === "payement_prestataire") {
        commissionEtFrais = commissionEtFrais + fraisBancaireCommissionPro;
        // setCommissionDispatcher(Number(trajet.commission));
        setCommissionFraisBelee(commissionEtFrais);
      } else if (typePayment.includes("differes")) {
        commissionEtFrais = commissionEtFrais + fraisTransfert;
        // setCommissionDispatcher(0);
        setCommissionFraisBelee(commissionEtFrais);
      } else {
        commissionEtFrais = commissionEtFrais + fraisTransfert;
        // setCommissionDispatcher(Number(trajet.commission));
        setCommissionFraisBelee(commissionEtFrais);
      }
      // console.log("commissionBelee", commissionEtFrais);
    }
  }, [trajet, fraisTransfert, fraisBancaireCommissionPro, typePayment]);

  return {
    commande,
    client,
    trajet,
    supplement,
    serviceProviderNet,
    fraisTransfert,
    typePayment,
    commissionPro,
    commissionFraisBelee,
    fraisBancaireCommissionPro,
    commissionDispatcher,
    totalPrice,
  };
};
