import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotRequireAuth, RequireAuth } from "routes";
import { CompteRoutes } from "./CompteRoutes";

const FeatureCompte = {
  ProfilePage: React.lazy(() => import("pages/Compte/views/ProfilePage")),
  ModifierProfilePage: React.lazy(
    () => import("pages/Compte/views/ModifierProfilePage")
  ),
  ModifierAdressePage: React.lazy(
    () => import("pages/Compte/views/ModifierAdressePage")
  ),
  InfoEntreprisePage: React.lazy(
    () => import("pages/Compte/views/InfoEntreprisePage")
  ),
  MoyenEncaissementPage: React.lazy(
    () => import("pages/Compte/views/MoyenEncaissementPage")
  ),
  MesServicePage: React.lazy(() => import("pages/Compte/views/MesServicePage")),
  ActiverServicePage: React.lazy(
    () => import("pages/Compte/views/ActiverServicePage")
  ),
  ActiverServiceBeleeCashPage: React.lazy(
    () => import("pages/Compte/views/ActiverServiceBeleeCash")
  ),
  ServiceBeleeCashAddedPage: React.lazy(
    () => import("pages/Compte/views/SuccessServiceBeleeCashPage")
  ),
  CompteNotValidePage: React.lazy(
    () => import("pages/Compte/views/CompteNotValidePage")
  ),
  PlaningPage: React.lazy(() => import("pages/Compte/views/PlaningPage")),
};

export const CompteAppRoutes = () => (
  <Routes>
    <Route key={"CompteRoutes"} element={<RequireAuth />}>
      <Route
        path={CompteRoutes.toProfile()}
        element={<FeatureCompte.ProfilePage />}
      />
      <Route
        path={CompteRoutes.toModifierProfile()}
        element={<FeatureCompte.ModifierProfilePage />}
      />
      <Route
        path={CompteRoutes.toModifierAdresse()}
        element={<FeatureCompte.ModifierAdressePage />}
      />
      <Route
        path={CompteRoutes.toInfoEntreprise()}
        element={<FeatureCompte.InfoEntreprisePage />}
      />
      <Route
        path={CompteRoutes.toMoyenEncaissement()}
        element={<FeatureCompte.MoyenEncaissementPage />}
      />
      <Route
        path={CompteRoutes.toMesService()}
        element={<FeatureCompte.MesServicePage />}
      />
      <Route
        path={CompteRoutes.toActiverService()}
        element={<FeatureCompte.ActiverServicePage />}
      />
      <Route
        path={CompteRoutes.toActiverServiceBeleeCash()}
        element={<FeatureCompte.ActiverServiceBeleeCashPage />}
      />
      <Route
        path={CompteRoutes.toServiceBeleeCashAddedSucess()}
        element={<FeatureCompte.ServiceBeleeCashAddedPage />}
      />
      <Route
        path={CompteRoutes.toPlaning()}
        element={<FeatureCompte.PlaningPage />}
      />
    </Route>
    <Route key={"CompteRoutesNoValidate"} element={<NotRequireAuth />}>
      <Route
        path={CompteRoutes.toCompteNotValide()}
        element={<FeatureCompte.CompteNotValidePage />}
      />
    </Route>
  </Routes>
);
