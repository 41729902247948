import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { RetirerRoutes } from ".";

const FeatureBanqueRetirer = {
  RetirerMonComptePage: React.lazy(
    () => import("pages/Banques/Retirer/views/RetirerMonComptePage")
  ),
  SaveMessageRetrait: React.lazy(
    () => import("pages/Banques/Retirer/views/SaisirSommeRetraitPage")
  ),
  retraitParPaypal: React.lazy(
    () => import("pages/Banques/Retirer/views/RetirerMonCompteParPaypalPage")
  ),
  retraitParVirement: React.lazy(
    () => import("pages/Banques/Retirer/views/RetirerMonCompteParVirementPage")
  ),
  retraitParEspece: React.lazy(
    () => import("pages/Banques/Retirer/views/RetirerMonCompteParEspecePage")
  ),
  // IbanInfoBeleePage: React.lazy(
  //   () => import("pages/Banques/Retirer/views/IbanInfoBeleePage")
  // ),
  SaisirSommeRetraitPage: React.lazy(
    () => import("pages/Banques/Retirer/views/SaisirSommeRetraitPage")
  ),
  ConfirmRetraitPage: React.lazy(
    () => import("pages/Banques/Retirer/views/ConfirmerRetraitPage")
  ),
  ScanneQRCodePage: React.lazy(
    () => import("pages/Banques/Retirer/views/ScanneQRCodePage")
  ),
  SuccessCashPage: React.lazy(
    () => import("pages/Banques/Retirer/views/SuccessCashPage")
  ),
};

export const RetirerAppRoutes = () => (
  <Routes>
    <Route key={"RetirerRoutes"} element={<RequireAuth />}>
      <Route
        path={RetirerRoutes.toRetirer()}
        element={<FeatureBanqueRetirer.RetirerMonComptePage />}
      />
      <Route
        path={RetirerRoutes.toSaveMessageRetirer()}
        element={<FeatureBanqueRetirer.SaveMessageRetrait />}
      />
      <Route
        path={RetirerRoutes.toRetirerParPaypal()}
        element={<FeatureBanqueRetirer.retraitParPaypal />}
      />
      <Route
        path={RetirerRoutes.toRetirerParVirement()}
        element={<FeatureBanqueRetirer.retraitParVirement />}
      />
      <Route
        path={RetirerRoutes.toRetirerParEspece()}
        element={<FeatureBanqueRetirer.retraitParEspece />}
      />
      <Route
        path={RetirerRoutes.toSaisirSommeRetrait()}
        element={<FeatureBanqueRetirer.SaisirSommeRetraitPage />}
      />
      <Route
        path={RetirerRoutes.toConfirmRetrait()}
        element={<FeatureBanqueRetirer.ConfirmRetraitPage />}
      />
      <Route
        path={RetirerRoutes.toScanneQRCode()}
        element={<FeatureBanqueRetirer.ScanneQRCodePage />}
      />
      <Route
        path={RetirerRoutes.toSuccessCash()}
        element={<FeatureBanqueRetirer.SuccessCashPage />}
      />
    </Route>
  </Routes>
);
