/* eslint-disable react-hooks/exhaustive-deps */
import { getUsersServices } from "api";
import { ServiceResponseAPI } from "interfaces";
import { useEffect, useState } from "react";
import { useAuth } from "./useAuth";

export const useUserServcie = () => {
  const [services, setServices] = useState<ServiceResponseAPI[]>();
  const [activesServices, setActiveServices] = useState<ServiceResponseAPI[]>();
  const [nombreServiceActive, setNombreServiceActive] = useState<any>();
  const [haveServiceActive, setHaveServiceActive] = useState<boolean>();
  const { user } = useAuth();

  const getUserSservices = async () => {
    if (user) {
      const response = await getUsersServices(user._id);
      if (typeof response !== "string") {
        setNombreServiceActive(response.length);

        setServices(response);
        let actives = [];
        for (const service of response) {
          if (service.etat === "VALIDE") {
            actives.push(service);
            setHaveServiceActive(true);
          }
        }
        if (actives && actives.length > 0) {
          setActiveServices(actives);
        }
      }
    }
  };

  useEffect(() => {
    getUserSservices();
  }, []);
  return { services, activesServices, nombreServiceActive, haveServiceActive };
};
