import { getAllVehiculeEntrepriseAPI } from "api/vehicule";
import { useAuth } from "hooks";
import { useVehicules } from "hooks/useVehicule";
import { useEffect, useState } from "react";

const VehiculeDetails = () => {
  const { user, userEntreprise } = useAuth();
  const { monVehicule, activeCount, gamme } = useVehicules();
  const [nombreVehiculeActive, setNombreVehiculeActive] = useState<number>(0);

  const getVehiculeEnreprise = async () => {
    if (user && userEntreprise) {
      const vehicules = await getAllVehiculeEntrepriseAPI(
        userEntreprise._id,
        "VALIDE",
        ""
      );
      if (typeof vehicules !== "string") {
        setNombreVehiculeActive(vehicules.length);
      }
    }
  };

  useEffect(() => {
    getVehiculeEnreprise();
    // console.log("activeCount", activeCount);
  }, [nombreVehiculeActive]);

  return (
    <div className="flex flex-col space-y-2 text-xs">
      {/* {monVehicule ? (
        <p>
          {monVehicule.immatriculation +
            " " +
            monVehicule.marque +
            " " +
            monVehicule.modele +
            " " +
            gamme?.name}
        </p>
      ) : (
        <p>Aucun véhicule assigné</p>
      )} */}
      {nombreVehiculeActive != 0 ? (
        <p>{nombreVehiculeActive} véhicules validés</p>
      ) : (
        <p>0 véhicules validés</p>
      )}
    </div>
  );
};

export default VehiculeDetails;
