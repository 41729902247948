export enum TypeTransactionEnum {
  RETRAIT = "RETRAIT",
  RECHARGEMENT = "RECHARGEMENT",
  TRANSFERT = "TRANSFERT",
  GAINS = "GAINS",
  PAYEMENT_COMMANDE = "PAYEMENT_COMMANDE",
  PAYEMENT_COMMANDE_DESKTOP = "PAYEMENT_COMMANDE_DESKTOP",
  PAYFRAISANN = "PAIEMENT_FRAIS_ANNULATION",
  PAYFRAISABS = "PAIEMENT_FRAIS_ABSENCE",
  PAYFRAISABD = "PAIEMENT_FRAIS_ABANDON",
  PAYEMENT_COMMISSION = "PAYEMENT_COMMISSION",
  ENVOI = "ENVOI",
}

export enum ModePaiementEnum {
  CB = "carte_bancaire",
  PPL = "paypal",
  SD = "solde",
  GAINS = "GAINS",
}

export enum MotifTransactionEnum {
  PAYCOM = "Paiement de commissions",
  COMAFF = "Commission AFF",
  RTBGNS = "Rétribution Gains",
  RMBCMB = "Remboursement commande",
}
