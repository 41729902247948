import { REGEX } from "utils";

const InvitationValidate = (values: any) => {
  const errors: any = {
    nom: "",
    prenom: "",
    email: "",
    phone: "",
  };
  if (!values.email) {
    errors.email = "L'adresse email est obligatoire";
  } else if (values.email.search(REGEX.EMAIL_REGEXP) === -1) {
    errors.email = "Veuillez entrer un email valide";
  }

  // if (!values.phone) {
  //   errors.phone = "Le numéro de téléphone est obligatoire";
  // } else if (values.phone.search(REGEX.PHONE_REGEXP) === -1) {
  //   errors.phone = "Veuillez entrer numero valide";
  // }

  if (!values.prenom) {
    errors.prenom = "Le prénom est obligatoire";
  } else if (values.prenom.length < 4) {
    errors.prenom = "Le prénom est invalide,minimum 4 caractères";
  }

  if (!values.nom) {
    errors.nom = "Le nom est obligatoire";
  } else if (values.nom.length < 4) {
    errors.nom = "Le nom est invalide,minimum 4 caractères";
  }

  return errors;
};

export default InvitationValidate;
