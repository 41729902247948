import { useCallback, useEffect, useRef, useState } from "react";
type Props<T> = {
  initialValues: T;
  validate?: (valide: T) => T;
  asyncValidate?: any;
  asyncBlurFields?: any[];
};

export const isEmpty = (obj: any) => {
  let keys = Object.keys(obj);
  for (const key of keys) {
    if (obj[key] !== "") {
      return false;
    }
  }
  return true;
};

export const useForm = <T>({
  initialValues,
  validate,
  asyncValidate,
  asyncBlurFields = [],
}: Props<T>) => {
  const initial = useRef<any>(initialValues);
  const [values, setValues] = useState<T>(initialValues);
  const [touched, setTouched] = useState<any>({});
  const [asyncValidatingField, setAsyncValidatingField] = useState(null);
  const [asyncErrors, setAsyncErrors] = useState<any>({});
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e: any) => {
    const { type, name } = e.target;

    const getValue = () => {
      if (type === "checkbox") {
        return e.target.checked;
      } else if (type === "select-multiple") {
        return Array.from(e.target.selectedOptions).map((o: any) => o.value);
      } else if (type === "file") {
        return e.target.files[0];
      }
      return e.target.value;
    };

    const value = getValue();
    setValues((prevValues: any) => ({ ...prevValues, [name]: value }));
    setAsyncErrors((asyncErrors: any) => {
      const errors = { ...asyncErrors };
      delete errors[name];
      return errors;
    });
  };

  const handleChangeNumPhone = (e: any) => {
    const { type, name } = e.target;

    const getValue = () => {
      if (type === "checkbox") {
        return e.target.checked;
      } else if (type === "select-multiple") {
        return Array.from(e.target.selectedOptions).map((o: any) => o.value);
      } else if (type === "file") {
        return e.target.files[0];
      }
      return e.target.value;
    };

    const value = getValue();
    setValues((prevValues: any) => ({ ...prevValues, [name]: value }));
    setAsyncErrors((asyncErrors: any) => {
      const errors = { ...asyncErrors };
      delete errors[name];
      return errors;
    });
  };

  const handleChangeTotalPriceValue = (e: any) => {
    const { type, name } = e.target;
    let valueMin = window.localStorage.getItem("totalPriceMin");
    if (!valueMin) {
      alert("Parametre valeur minimal non défini");
      return;
    }
    if (e.target.value > valueMin) {
      const getValue = () => {
        if (type === "checkbox") {
          return e.target.checked;
        } else if (type === "select-multiple") {
          return Array.from(e.target.selectedOptions).map((o: any) => o.value);
        } else if (type === "file") {
          return e.target.files[0];
        }
        return e.target.value;
      };

      const value = getValue();
      setValues((prevValues: any) => ({ ...prevValues, [name]: value }));
      setAsyncErrors((asyncErrors: any) => {
        const errors = { ...asyncErrors };
        delete errors[name];
        return errors;
      });
    }
  };

  const handleBlur = (e: { target: { name: any } }) => {
    const { name } = e.target;
    setTouched((prevTouched?: any) => ({ ...prevTouched, [name]: true }));

    if (asyncValidate) {
      const isAsyncField = !!asyncBlurFields.find(
        (field: any) => field === name
      );
      if (isAsyncField) {
        setAsyncValidatingField(name);
      }
    }
  };

  const initialize = useCallback((initialValues: any) => {
    if (!initialValues) {
      setValues(initial.current);
    } else {
      initial.current = initialValues;
      setValues(initialValues);
    }
  }, []);

  useEffect(() => {
    if (asyncValidatingField) {
      asyncValidate(values).then((errors: any) => {
        if (!isEmpty(errors)) {
          setAsyncErrors(errors);
        }
        setAsyncValidatingField(null);
      });
    }
  }, [asyncValidatingField, values, asyncValidate]);

  const asyncValidateFields = () => {
    return asyncValidate(values).then((errors: any) => {
      if (!isEmpty(errors)) {
        setAsyncErrors(errors);
        return false;
      }
      setAsyncValidatingField(null);
      return true;
    });
  };

  const errors = validate ? validate(values) : undefined;
  const valid = isEmpty(errors) && isEmpty(asyncErrors);

  const handleSubmit =
    (onSubmit: any) =>
    (e: { preventDefault: () => void; persist: () => void }) => {
      setSubmitting(true);
      if (e && typeof e.preventDefault === "function") {
        e.preventDefault();
      }
      if (asyncValidate) {
        e.persist();
        asyncValidateFields().then((asyncValid: any) => {
          if (asyncValid && valid) {
            Promise.resolve(onSubmit(values, e)).finally(() =>
              setSubmitting(false)
            );
          } else {
            setSubmitting(false);
          }
        });
      } else if (valid) {
        Promise.resolve(onSubmit(values, e)).finally(() =>
          setSubmitting(false)
        );
      } else {
        setSubmitting(false);
      }
    };

  const dirty = !!Object.keys(initial.current).find(
    (key) => initial.current[key] !== values[key as keyof typeof values]
  );
  const pristine = !dirty;

  return {
    values,
    setValues,
    handleChange,
    handleBlur,
    handleSubmit,
    initialize,
    errors,
    asyncErrors,
    submitting,
    valid,
    dirty,
    pristine,
    touched,
    setTouched,
    handleChangeTotalPriceValue,
    handleChangeNumPhone,
  };
};

export default useForm;
