import { ReactNode } from "react";

type Props = {
  title: string;
  icon: ReactNode;
  lieu?: string;
  address: string;
  bgColor?: string;
  borderColor?: string;
};
const CmdAddressItem = ({
  title,
  icon,
  lieu,
  address,
  bgColor,
  borderColor,
}: Props) => {
  return (
    <div className="flex flex-col">
      <p className="font-semibold text-sm mb-2">{title}</p>
      <div
        className={`py-3 ${
          borderColor ? borderColor : "border border-orange-belee"
        } ${
          bgColor ? bgColor : "bg-gray-300"
        } rounded-lg grid grid-cols-12 gap-4 items-center px-2`}
      >
        {icon}
        <div className="flex col-span-11 text-sm flex-col">
          <p className="text-xs">{address}</p>
        </div>
      </div>
    </div>
  );
};

export default CmdAddressItem;
