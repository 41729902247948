import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { SiteRoutes } from "./SiteRoutes";

const FeatureSite = {
  SiteHomePage: React.lazy(() => import("pages/Site/views/SiteHomePage")),
  ParametreDispatchingEntreprisePage: React.lazy(
    () => import("pages/Site/views/ParametreDispatchingEntreprisePage")
  ),
  ParametreTarificationEntreprisePage: React.lazy(
    () => import("pages/Site/views/ParametreTarificationEntreprisePage")
  ),
};

export const SiteAppRoutes = () => (
  <Routes>
    <Route key={"SiteRoutes"} element={<RequireAuth />}>
      <Route
        path={SiteRoutes.toHomeSite()}
        element={<FeatureSite.SiteHomePage />}
      />
      <Route
        path={SiteRoutes.toModeDispatching()}
        element={<FeatureSite.ParametreDispatchingEntreprisePage />}
      />
      <Route
        path={SiteRoutes.toTarificationEntreprise()}
        element={<FeatureSite.ParametreTarificationEntreprisePage />}
      />
    </Route>
  </Routes>
);
