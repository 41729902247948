import { clientRapidApi } from "./client";

export const getVehiculeDetails = async (immatriculation: string) => {
  try {
    const response = await clientRapidApi.get(immatriculation);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};
