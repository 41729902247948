import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import i18n from "i18next";

import commonEn from "./locales/en/translation.en.json";
import commonFr from "./locales/fr/translation.fr.json";
import commonEsp from "./locales/esp/translation.esp.json";
import commonIta from "./locales/ita/translation.ita.json";

import { I18nextProvider } from "react-i18next";

const getStoredLanguage = () => {
  const storedLanguage = localStorage.getItem("i18nextLng");
  return storedLanguage || "fr";
};

i18n.init({
  interpolation: { escapeValue: false },
  lng: getStoredLanguage(),
  resources: {
    en: {
      translation: commonEn,
    },
    fr: {
      translation: commonFr,
    },
    esp: {
      translation: commonEsp,
    },
    ita: {
      translation: commonIta,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
