import { UpdateAddressData } from "api";
import { REGEX } from "utils";

const updateAdressValidate = (values: UpdateAddressData) => {
  const errors: UpdateAddressData = {
    ville: "",
    address: "",
    code_postale: "",
  };
  if (!values.ville) {
    errors.ville = "La ville est obligatoire";
  }

  if (!values.address) {
    errors.address = "L'adresse est obligatoire";
  }

  if (!values.code_postale) {
    errors.code_postale = "Le code postale est obligatoire";
  } else if (values.code_postale.search(REGEX.CODE_POSTAL_REGEXP) === -1) {
    errors.code_postale = "Veuillez entrer un email valide";
  }

  return errors;
};

export default updateAdressValidate;
