/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, MouseEventHandler, useRef } from "react";
import { X } from "react-feather";
type ModalProps = {
  isShow: boolean;
  closeModal: MouseEventHandler;
  children: React.ReactNode;
  onClose: (value: boolean) => void;
  bgColor?: string;
  borderColor?: string;
  closeVisibility?: boolean;
  colorClose?: string;
};

const Modal = ({
  isShow = false,
  closeModal,
  onClose,
  bgColor = "bg-orange-belee bg-opacity-[0.8]",
  borderColor = "border-2 border-orange-belee",
  closeVisibility = true,
  children,
  colorClose,
}: ModalProps) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center px-4 xs:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform  rounded-3xl text-white ${borderColor} ${bgColor} text-left shadow-xl transition-all xs:my-8 w-80`}
              >
                {closeVisibility && (
                  <div className="absolute top-3 right-5 z-20">
                    <X
                      size={30}
                      className={`cursor-pointer ${
                        colorClose ? colorClose : "text-white"
                      }`}
                      onClick={closeModal}
                    />
                  </div>
                )}

                <div className="p-8 text-center font-sans">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default Modal;
