import { Autocomplete } from "@react-google-maps/api";
import { useState } from "react";
import DepartIcon from "../icons/DepartIcon";
import Input from "./Input";

type Props = {
  onChange: (place: any) => void;
  placeholder: string;
  bgColor?: string;
  icon?: React.ReactNode;
  name: string;
  borderColor?: string;
  defaultValue?: string;
  value?: string;
};

const AutoCompleteInput = ({
  onChange,
  placeholder,
  icon = <DepartIcon />,
  name,
  bgColor = "bg-[#121212]",
  borderColor = "border-[#3A3A3A]",
  defaultValue,
  value,
}: Props) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      onChange(place);
    }
  };

  const onLoad = (autoCompleteInstance: google.maps.places.Autocomplete) => {
    setAutocomplete(autoCompleteInstance);

    // Définir les restrictions pour la région de l'Europe centrale
    const restrictedOptions = {
      bounds: new google.maps.LatLngBounds(
        { lat: 42.604362, lng: 9.662003 }, // Coordonnées du coin supérieur gauche de la région
        { lat: 49.640986, lng: 24.155988 } // Coordonnées du coin inférieur droit de la région
      ),
    };

    // Appliquer les options restreintes à l'instance Autocomplete
    autoCompleteInstance.setOptions(restrictedOptions);
  };

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <Input
        icon={icon}
        textSize="text-sm"
        bgColor={bgColor}
        name={name}
        defaultValue={defaultValue}
        value={value}
        borderColor={borderColor}
        placeholder={placeholder}
      />
    </Autocomplete>
  );
};

export default AutoCompleteInput;
