const PaypalIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58562 18.2855L4.90796 16.2161L4.19001 16.1993H0.761719L3.14423 0.92867C3.1515 0.882566 3.17563 0.839582 3.21075 0.809061C3.24588 0.77854 3.29094 0.761719 3.33777 0.761719H9.11841C11.0375 0.761719 12.3619 1.1654 13.0534 1.96215C13.3775 2.33594 13.584 2.72652 13.6839 3.15636C13.7885 3.6074 13.7905 4.14625 13.6881 4.80348L13.6807 4.85145V5.27256L14.005 5.45821C14.278 5.60459 14.4948 5.77217 14.6612 5.96405C14.9386 6.28362 15.118 6.68979 15.1937 7.17133C15.272 7.66659 15.2461 8.25591 15.118 8.9231C14.97 9.69059 14.7309 10.359 14.408 10.906C14.1109 11.41 13.7325 11.8279 13.2833 12.1519C12.8543 12.4596 12.3446 12.6933 11.7684 12.8428C11.21 12.9897 10.5735 13.064 9.87521 13.064H9.42532C9.10369 13.064 8.79111 13.1811 8.54594 13.391C8.30006 13.6053 8.13721 13.898 8.08736 14.2183L8.05347 14.4046L7.48401 18.0519L7.45811 18.1858C7.45137 18.2283 7.43966 18.2495 7.42245 18.2638C7.40702 18.2767 7.38484 18.2855 7.3632 18.2855H4.58562Z"
        fill="#253B80"
      />
      <path
        d="M14.4093 4.57227C14.3917 4.68737 14.3717 4.80506 14.349 4.92596C13.5711 9.01029 10.9103 10.4213 7.51178 10.4213H5.78146C5.3659 10.4213 5.01568 10.7299 4.95088 11.1492L4.06491 16.896L3.81405 18.5249C3.77188 18.8002 3.97948 19.0485 4.25097 19.0485H7.3201C7.68354 19.0485 7.99213 18.7784 8.04933 18.4118L8.07956 18.2522L8.65747 14.5017L8.69457 14.296C8.75105 13.9282 9.06036 13.6581 9.4238 13.6581H9.8828C12.8562 13.6581 15.184 12.4233 15.8643 8.85016C16.1485 7.35752 16.0013 6.11121 15.2493 5.23465C15.0218 4.97033 14.7394 4.75104 14.4093 4.57227Z"
        fill="#179BD7"
      />
      <path
        d="M13.6251 4.84442C13.5007 4.8107 13.3722 4.78005 13.2405 4.75247C13.1082 4.72551 12.9725 4.7016 12.833 4.68076C12.3444 4.6072 11.8091 4.57227 11.2356 4.57227H6.39484C6.27562 4.57227 6.16247 4.59739 6.06107 4.64276C5.8378 4.74266 5.67195 4.93943 5.63177 5.18031L4.60183 11.2523L4.57227 11.4294C4.64012 11.0298 5.00687 10.7355 5.44205 10.7355H7.25401C10.8129 10.7355 13.5995 9.39011 14.4139 5.49783C14.4382 5.38259 14.4586 5.27042 14.477 5.16071C14.271 5.05895 14.0477 4.97191 13.8074 4.89774C13.7483 4.87935 13.687 4.86158 13.6251 4.84442Z"
        fill="#222D65"
      />
      <path
        d="M6.00294 4.8099C6.04105 4.56436 6.1985 4.36383 6.41005 4.26261C6.50693 4.21638 6.6137 4.19077 6.72658 4.19077H11.3173C11.8612 4.19077 12.3688 4.22638 12.832 4.30135C12.9645 4.32259 13.0932 4.34696 13.2186 4.37444C13.3435 4.40256 13.4652 4.4338 13.5833 4.46815C13.6419 4.48565 13.7 4.50376 13.7568 4.52189C13.9847 4.59747 14.1964 4.68682 14.3918 4.7899C14.6217 3.32363 14.39 2.32529 13.5977 1.42128C12.7242 0.426072 11.1475 0 9.13006 0H3.27304C2.8609 0 2.5095 0.299862 2.4457 0.707825L0.00625957 16.1796C-0.0419109 16.4857 0.194089 16.7619 0.502523 16.7619H4.11854L5.02641 10.9986L6.00294 4.8099Z"
        fill="#253B80"
      />
    </svg>
  );
};

export default PaypalIcon;
