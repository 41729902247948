export const FactureRoutes = {
  toFacturePage: (): string => "/factures",
  toFactureCommandePdf: (factureId?: string): string =>
    factureId
      ? "/facture/pdf/commande/" + factureId
      : "/facture/pdf/commande/:factureId",
  toFactureFraisCommandePdf: (factureId?: string): string =>
    factureId
      ? "/facture/pdf/commande/frais/" + factureId
      : "/facture/pdf/commande/frais/:factureId",
  toFactureCommissionsPdf: (factureId?: string): string =>
    factureId
      ? "/facture/pdf/commissions/" + factureId
      : "/facture/pdf/commissions/:factureId",
  toFactureFraisCommissionsPdf: (factureId?: string): string =>
    factureId
      ? "/facture/pdf/commissions/frais/" + factureId
      : "/facture/pdf/commissions/frais/:factureId",
  toFactureCommissionDispatcherPdf: (factureId?: string): string =>
    factureId
      ? "/facture/pdf/commission/dispatcher/" + factureId
      : "/facture/pdf/commission/dispatcher/:factureId",
  toFactureGainPrestatairePdf: (factureId?: string): string =>
    factureId
      ? "/facture/pdf/gain-prestataire/" + factureId
      : "/facture/pdf/gain-prestataire/:factureId",
  toFactureRemboursementCommandePdf: (factureId?: string): string =>
    factureId
      ? "/facture/pdf/remboursement/" + factureId
      : "/facture/pdf/remboursement/:factureId",
  toFactureFraisRemoursementCommandePdf: (factureId?: string): string =>
    factureId
      ? "/facture/pdf/remboursement/frais/" + factureId
      : "/facture/pdf/remboursement/frais/:factureId",
};
