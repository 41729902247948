import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { UserRoutes } from "./UserRoutes";

const FeatureUser = {
  UtilisateursPage: React.lazy(
    () => import("pages/Utilisateur/views/UtilisateursPage")
  ),
  // InviterUtilisateurPage: React.lazy(
  //   () => import("pages/Utilisateur/views/InviterUtilisateurPage")
  // ),
  EnvoyerInvitationPage: React.lazy(
    () => import("pages/Utilisateur/views/EnvoyerInvitationPage")
  ),
  DetailsUtilisateurPage: React.lazy(
    () => import("pages/Utilisateur/views/DetailsUtilisateurPage")
  ),
  AjoutUtilisateurPage: React.lazy(
    () => import("pages/Utilisateur/views/InviterUtilisateurPage")
  ),
  AjoutFavorisPage: React.lazy(
    () => import("pages/Utilisateur/views/AjoutFavorisPage")
  ),
  ListeFavorisPage: React.lazy(
    () => import("pages/Utilisateur/views/ListeFavorisPage")
  ),
  QrCodePdfPage: React.lazy(
    () => import("pages/Utilisateur/views/QrCodePdfPage")
  ),
};

export const UserAppRoutes = () => (
  <Routes>
    <Route key={"UserRoutes"} element={<RequireAuth />}>
      <Route
        path={UserRoutes.toUsers()}
        element={<FeatureUser.UtilisateursPage />}
      />
      {/* <Route
        path={UserRoutes.toInviteUser()}
        element={<FeatureUser.InviterUtilisateurPage />}
      /> */}
      <Route
        path={UserRoutes.toSendInvitationUser()}
        element={<FeatureUser.EnvoyerInvitationPage />}
      />
      <Route
        path={UserRoutes.toDetailsUser()}
        element={<FeatureUser.DetailsUtilisateurPage />}
      />
      <Route
        path={UserRoutes.toAddUser()}
        element={<FeatureUser.AjoutUtilisateurPage />}
      />
      <Route
        path={UserRoutes.toAddFavoris()}
        element={<FeatureUser.AjoutFavorisPage />}
      />
      <Route
        path={UserRoutes.toListeFavoris()}
        element={<FeatureUser.ListeFavorisPage />}
      />
      <Route
        path={UserRoutes.toQrCodePdf()}
        element={<FeatureUser.QrCodePdfPage />}
      />
    </Route>
  </Routes>
);
