export const RechargerRoutes = {
  toRecharger: (): string => "/banque/recharger",
  toInfoIbanBelee: (): string => "/banque/recharger/iban",
  toChoixRechargementVirement: (): string => "/banque/recharger/virement/choix",
  toVirementIbanEnAttente: (): string => "/banque/recharger/iban/en-attente",
  toSaisirSomme: (
    id?: string
  ): string => // recharger par carte ancien Mars 19 2024
    id
      ? "/banque/recharger/saisir/" + id
      : "/banque/recharger/saisir/:idMethodePaiement",
  toRechargerSoldeParCarte: (id?: string): string =>
    id
      ? "/banque/recharger/solde/carte/" + id
      : "/banque/recharger/solde/carte/:idMethodePaiement",
  toGetInfoCartePaiement: (): string => "/banque/get/info/carte/paiement",
};
