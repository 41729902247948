import { client } from "./client";

export const savePaypalPayment = async (commandeId: string, details: any) => {
  let err = "";
  try {
    const response = await client.post("/paiement/paypal/" + commandeId, {
      details: details,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const envoiArgentBeneficiaireParPaypalAPI = async (
  beneficiaireId: string,
  data: any
) => {
  let err = "";

  try {
    const response = await client.post(
      "/paypal/envoie/" + beneficiaireId,
      data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};
