import { ConfigRoutes } from "pages";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";

const FeatureConfig = {
  TauxTVAPage: React.lazy(
    () => import("pages/Accueil/configs/views/TauxTVAPage")
  ),
};

export const ConfigAppRoutes = () => (
  <Routes>
    <Route key={"ConfigAppRoutes"} element={<RequireAuth />}>
      <Route
        path={ConfigRoutes.toTauxTVA()}
        element={<FeatureConfig.TauxTVAPage />}
      />
    </Route>
  </Routes>
);
