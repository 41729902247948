import { ButtonType } from "interfaces";
import { ReactElement } from "react";
import Loader4 from "../loaders/Loader4";

const Button = ({
  icon,
  children,
  rightIcon,
  addStyle,
  disabled,
  isLoading,
  textColor = "text-white",
  ...buttonProps
}: ButtonType &
  React.ButtonHTMLAttributes<HTMLButtonElement>): ReactElement => {
  return (
    <button
      className={`flex relative space-x-4 justify-center items-center ${textColor} ${
        disabled || isLoading
          ? "bg-gray-300 border-2 border-orange-belee h-12 px-8 rounded-full cursor-auto"
          : addStyle
          ? addStyle
          : "bg-orange-belee h-16 px-10 rounded-3xl text-xl"
      } `}
      {...buttonProps}
    >
      {isLoading && (
        <div
          className={
            "absolute top-0 w-full bottom-0 right-0 left-1 flex justify-center items-center"
          }
        >
          <Loader4 />
        </div>
      )}

      {icon && (
        <div className={`pr-3 ${isLoading ? "opacity-25" : ""}`}>{icon}</div>
      )}
      <p className={`${isLoading ? "opacity-25" : ""}`}>{children}</p>
      {rightIcon && <div className="pl-3">{rightIcon}</div>}
    </button>
  );
};

export default Button;
