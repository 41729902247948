/* eslint-disable react-hooks/exhaustive-deps */
import { createEntreprise, EntrepriseData, getParametreAPI } from "api";
import { AlertKind } from "components";
import Alert from "components/elements/alerts/Alert";
import Input from "components/elements/inputs/Input";
import InputFile from "components/elements/inputs/InputFile";
import { useAuth, useForm, useLocalStorage } from "hooks";
import infoEntrepriseValidate from "pages/Accounts/utils/infoEntrepriseValidate";
import updateEntrepriseValidate from "pages/Accounts/utils/updateEntrepriseValidate";
import { useEffect, useState } from "react";
import { Check, Edit2, Info } from "react-feather";
import Modal from "../modals/Modal";
import { useNavigate } from "react-router-dom";
import TVAItem from "pages/Banques/Transfert/components/TVAItem";
import { tvas } from "pages/Banques/Transfert/utils/mock-tva";
import { AccountRoutes } from "pages";
import Loader2 from "../loaders/Loader2";

type Props = {
  idUser?: string;
  actionDone: (response: any) => void;
  showSuccess?: boolean;
  title?: string;
};

const InforEntrepriseForm = ({
  idUser,
  actionDone,
  showSuccess = true,
}: Props) => {
  const { user, isAdmin, isAuthenticated, authEntreprise } = useAuth();
  const [error, setError] = useState<string>();
  const [selected, setSelected] = useState<string>(tvas[0].value);

  const [isShow, setIshow] = useState<boolean>(false);
  let initialisations = {
    siren: authEntreprise?.siren ?? "",
    name: authEntreprise?.name ?? "",
    address: authEntreprise?.address ?? "",
    code_postale: authEntreprise?.code_postale ?? "",
    ville: authEntreprise?.ville ?? "",
    piece_identite_recto: "",
    piece_identite_verso: "",
    kbis: "",
  };
  const [mesTva, setMesTva] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useLocalStorage<EntrepriseData>(
    "entreprise_option" + (user ? user.id : ""),
    initialisations
  );

  let initialValues: EntrepriseData = {
    siren: "",
    name: "",
    // siren: defaultValues.siren,
    // name: defaultValues.name,
    address: defaultValues.address,
    code_postale: defaultValues.code_postale,
    ville: defaultValues.ville,
    // piece_identite_recto: "",
    // piece_identite_verso: "",
    kbis: "",
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setTouched,
    setValues,
  } = useForm<any>({
    initialValues,
    validate: authEntreprise
      ? updateEntrepriseValidate
      : infoEntrepriseValidate,
  });

  const onsubmit = async (values: EntrepriseData) => {
    if (idUser) {
      setIsLoading(true);
      let response: any = {};
      let data = { ...values, tvaEntreprise: selected };

      if (selected) {
        // un tva doit aumoin être selected

        if (isAuthenticated) {
          response = await createEntreprise(idUser, data);
          if (typeof response !== "string") {
            setValues(initialValues);
            setTouched({});
            setError(undefined);
            setDefaultValues(initialisations);
            setIsSuccess(response.message);
            actionDone(response);
            // response.entreprise

            let auth = localStorage.getItem("bappauth");
            if (auth) {
              let data = JSON.parse(auth);
              data.entreprise = response.entreprise;
              localStorage.setItem("bappauth", JSON.stringify(data));
            }

            setIsLoading(false);
            navigate(AccountRoutes.toInfoEntrepriseSaved());
            return;
          } else {
            setError(response);
            setDefaultValues(values);
            return;
          }
        }
      } else {
        setIsLoading(false);
        setError("Tous les champs sont obligatoires");
        setIsLoading(false);
      }
    } else {
      alert("erreur idUser manquant");
    }
  };

  // const getEntrepriseDetail = async (siren: string) => {
  //   const response = await getEntrepriseDetails(siren);
  //   if (response) {
  //     setValues((prevValues: any) => ({
  //       ...prevValues,
  //       name: response.nom_entreprise,
  //     }));
  //     setValues((prevValues: any) => ({
  //       ...prevValues,
  //       address: response.siege.adresse_ligne_1,
  //     }));
  //     setValues((prevValues: any) => ({
  //       ...prevValues,
  //       code_postale: response.siege.code_postal,
  //     }));
  //     setValues((prevValues: any) => ({
  //       ...prevValues,
  //       ville: response.siege.ville,
  //     }));
  //   } else {
  //   }
  // };

  const getTva = async () => {
    const response = await getParametreAPI("France");
    if (typeof response != "string") {
      const tvaArray = [
        { id: "tva_1", value: response.tva.tva_1 },
        { id: "tva_2", value: response.tva.tva_2 },
        { id: "tva_3", value: response.tva.tva_3 },
        { id: "tva_4", value: response.tva.tva_4 },
        { id: "tva_5", value: response.tva.tva_5 },
      ];
      setSelected(tvaArray[0].value);
      setMesTva(tvaArray);
    }
  };

  const getFileName = (value: any, fieldName: string) => {
    if (value) {
      return value.name;
    } else {
      if (authEntreprise && user?.entreprise && user?.entreprise?.[fieldName]) {
        let filename = user?.entreprise?.[fieldName] as string;
        return filename.replace(
          "/uploads/entreprises/" + authEntreprise.slug + "/",
          ""
        );
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    getTva();
  }, []);

  useEffect(() => {
    // if (!errors.siren && values.siren) {
    //   getEntrepriseDetail(values.siren);
    // }

    if (authEntreprise) {
      if (authEntreprise.slug === "belee") {
      } else {
        navigate(AccountRoutes.toUpdateEntreprise());
      }
    }
  }, [values.siren, errors.siren]);

  return (
    <div className="mx-3 lg:w-[1000px] md:w-[800px] lg:mx-auto md:mx-auto bg-white lg:mt-32 md:mt-32 mt-28 lg:mb-48 md:mb-48 mb-5 pb-12 rounded-[30px] lg:px-10 md:px-10 px-5 shadow-xl bg-opacity-80">
      {isLoading && <Loader2 />}

      <h2 className="mt-10 text-center text-2xl font-semibold text-orange-belee">
        {isAdmin && user?.entreprise !== undefined
          ? "Information sur votre entreprise"
          : isAdmin && user?.entreprise === undefined
          ? "Saisissez les informations"
          : "Votre entreprise"}
      </h2>
      {isSuccess && showSuccess && (
        <Alert kind={AlertKind.success}>{isSuccess}</Alert>
      )}

      {error && (
        <div className="flex justify-start items-center space-x-4 text-red-500 pt-5">
          <Info />
          <p className="text-sm">{error}</p>
        </div>
      )}

      <div className="mt-5 lg:grid md:grid grid-cols-2 gap-6">
        <div className="col-span-1 space-y-2">
          <Input
            label="Siren"
            type="text"
            name="siren"
            onChange={handleChange}
            value={values.siren}
            required
            error={errors.siren}
            touched={touched.siren}
            onBlur={handleBlur}
            // disabled={isAuthenticated && !isAdmin}
            bgColor="bg-gray-300 shadow-lg"
            noBorder
          />

          <Input
            label="Nom de l'entreprise"
            type="text"
            name="name"
            onChange={handleChange}
            value={values.name}
            required
            error={errors.name}
            touched={touched.name}
            // disabled={(isAuthenticated && !isAdmin) || notEditable}
            onBlur={handleBlur}
            bgColor="bg-gray-300 shadow-lg"
            noBorder
          />

          <Input
            label="Adresse"
            type="text"
            name="address"
            onChange={handleChange}
            value={values.address}
            required
            error={errors.address}
            touched={touched.address}
            // disabled={(isAuthenticated && !isAdmin) || notEditable}
            onBlur={handleBlur}
            bgColor="bg-gray-300 shadow-lg"
            noBorder
          />

          <Input
            label="Code postale"
            type="text"
            name="code_postale"
            onChange={handleChange}
            value={values.code_postale}
            required
            error={errors.code_postale}
            touched={touched.code_postale}
            // disabled={(isAuthenticated && !isAdmin) || notEditable}
            onBlur={handleBlur}
            bgColor="bg-gray-300 shadow-lg"
            noBorder
          />

          <Input
            label="Ville"
            name="ville"
            type="text"
            onChange={handleChange}
            value={values.ville}
            required
            error={errors.ville}
            touched={touched.ville}
            onBlur={handleBlur}
            // disabled={(isAuthenticated && !isAdmin) || notEditable}
            bgColor="bg-gray-300 shadow-lg"
            noBorder
          />

          {/* {(!isAuthenticated || (isAuthenticated && isAdmin)) && ( */}
          {/* <InputFile
            label="Pièce d’identité du responsable (Recto)"
            name="piece_identite_recto"
            onChange={handleChange}
            required={!authEntreprise}
            id="piece_identite_recto"
            error={errors.piece_identite_recto}
            touched={touched.piece_identite_recto}
            value={getFileName(
              values.piece_identite_recto,
              "piece_identite_recto"
            )}
            onBlur={handleBlur}
            setTouched={setTouched}
            rightIcon={<Edit2 size={18} className="cursor-pointer" />}
            bgColor="bg-gray-300"
            noShadow
          />

          <InputFile
            label="Pièce d’identité du responsable (Verso)"
            name="piece_identite_verso"
            onChange={handleChange}
            required={!authEntreprise}
            id="piece_identite_verso"
            error={errors.piece_identite_verso}
            touched={touched.piece_identite_verso}
            value={getFileName(
              values.piece_identite_verso,
              "piece_identite_verso"
            )}
            onBlur={handleBlur}
            setTouched={setTouched}
            rightIcon={<Edit2 size={18} className="cursor-pointer" />}
            bgColor="bg-gray-300"
            noShadow
          /> */}
        </div>

        <div className="col-span-1 space-y-2">
          <InputFile
            label="Kbis de moins de 3 mois"
            name="kbis"
            id="kbis"
            onChange={handleChange}
            required={!authEntreprise}
            error={errors.kbis}
            touched={touched.kbis}
            value={getFileName(values.kbis, "kbis")}
            onBlur={handleBlur}
            setTouched={setTouched}
            rightIcon={<Edit2 size={18} className="cursor-pointer" />}
            bgColor="bg-gray-300"
            noShadow
          />

          {errors.kbis && errors.kbis !== "Ce champ est obligatoire" && (
            <p className="text-xs text-red-500">{errors.kbis}</p>
          )}

          <div className="text-black pt-5 flex flex-col space-y-5">
            <p className="text-orange-belee font-medium ">Votre taux de TVA</p>
            <div className="flex flex-col space-y-3">
              {mesTva &&
                mesTva.map((tva: any) => (
                  <TVAItem
                    text={tva.value}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              className="mt-8 lg:mt-14 px-10 text-center text-sm md:text-lg lg:text-lg font-base md:font-medium lg:font-medium text-white rounded-full bg-orange-belee py-3 shadow-xl hover:shadow-none"
              onClick={handleSubmit(onsubmit)}
            >
              Valider
            </button>
          </div>
        </div>
      </div>

      {/* <Button
            // disabled={!valid || submitting}
            addStyle="button-belee"
            onClick={handleSubmit(onsubmit)}
          >
            {isEmpty(errors) ? "Valider" : "Remplir tous les champs"}
          </Button> */}
      {/* )} */}

      <Modal
        isShow={isShow}
        closeModal={() => setIshow(false)}
        onClose={setIshow}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-16 h-16 bg-white text-green-belee rounded-full">
            <Check size={35} />
          </div>
          <p className="text-xl mt-2">
            Votre Entreprise a été modifiée avec succès
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default InforEntrepriseForm;
