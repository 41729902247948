export const CommandeRoutes = {
  toCommande: (): string => "/commande",
  toCommandeDetal: (commandeId?: string): string =>
    commandeId ? "/commande/" + commandeId : "/commande/:commandeId",
  toCommandeAnnule: (commandeId?: string): string =>
    commandeId
      ? "/commande/annulation/" + commandeId
      : "/commande/annulation/:commandeId",
  toCommandeAnnulerReservant: (commandeId?: string): string =>
    commandeId
      ? "/commande/annulation-reservant/" + commandeId
      : "/commande/annulation-reservant/:commandeId",
  toCommandeRembourser: (commandeId?: string): string =>
    commandeId
      ? "/commande/rembourser/" + commandeId
      : "/commande/rembourser/:commandeId",
  toCommandeFacture: (commandeId?: string): string =>
    commandeId
      ? "/commande/facture/" + commandeId
      : "/commande/facture/:commandeId",
  toDetailRetrait: (commandeId?: string): string =>
    commandeId
      ? "/commande/retrait/detail/" + commandeId
      : "/commande/retrait/detail/:commandeId",
  toDemandeurSurPlace: (commandeId?: string): string =>
    commandeId
      ? "/commande/retrait/demandeur/sur-place/" + commandeId
      : "/commande/retrait/demandeur/sur-place/:commandeId",
  toNoteUserAndVehicule: (commandeId?: string): string =>
    commandeId
      ? "/commande/note/user/vehicule/" + commandeId
      : "/commande/note/user/vehicule/:commandeId",
  toDeclarerLitige: (commandeId?: string, type?: string): string =>
    type && commandeId
      ? "/commande/declarer-litige/" + type + "/" + commandeId
      : "/commande/declarer-litige/:type/:commandeId",
};
