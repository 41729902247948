export enum Service {
  VTC = "VTC",
  TAXI = "TAXI",
  CASH = "CASH",
  LAVAGE_AUTO = "LAVAGE_AUTO",
  LOCATION_VEHICULE = "LOCATION_VEHICULE",
  LOCATION_VEHICULE_SANS_CHAUFFEUR = "LOCATION_VEHICULE_SANS_CHAUFFEUR",
  LOCATION_VEHICULE_AVEC_CHAUFFEUR = "LOCATION_VEHICULE_AVEC_CHAUFFEUR",
  DEPANNAGE = "DEPANNAGE",
  BELEE_CASH = "BELEE_CASH",
}
