import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Loader from "../components/elements/loaders/Loader";
import { BeleeAppRoutes } from "./BeleeAppRoutes";

export const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <BeleeAppRoutes />
      </Suspense>
    </Router>
  );
};
