import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotRequireAuth } from "routes";
import { PaymentRoutes } from "./PaymentRoutes";

const FeaturePayment = {
  ChoisirModePaymentPage: React.lazy(
    () => import("pages/Payment/views/ChoisirModePaymentPage")
  ),
  ChoisirModePaymentCommissionPage: React.lazy(
    () => import("pages/Payment/views/ChoisirModePaymentCommissionPage")
  ),
  ChoisirModePaymentFraisAbandonPage: React.lazy(
    () => import("pages/Payment/views/ChoisirModePaymentFraisAbandonPage")
  ),
  ChoisirModePaymentFraisAbsencePage: React.lazy(
    () => import("pages/Payment/views/ChoisirModePaymentFraisAbsencePage")
  ),
  ChoisirModePaymentFraisAnnulationPage: React.lazy(
    () => import("pages/Payment/views/ChoisirModePaymentFraisAnnulationPage")
  ),
  PaymentAvecSoldePage: React.lazy(
    () => import("pages/Payment/views/PaymentAvecSoldePage")
  ),
  PaymentAvecCartePage: React.lazy(
    () => import("pages/Payment/views/PaymentAvecCartePage")
  ),
  PaymentAvecPaypalPage: React.lazy(
    () => import("pages/Payment/views/PaymentAvecPaypalPage")
  ),
  StripeSuccessPage: React.lazy(
    () => import("pages/Payment/views/StripeSuccessPage")
  ),
  StripeCBSuccessPage: React.lazy(
    () => import("pages/Payment/views/StripeCBSuccessPage")
  ),
  StripeCancelledPage: React.lazy(
    () => import("pages/Payment/views/StripeCancelledPage")
  ),
  CommissionPaiementAvecSolde: React.lazy(
    () => import("pages/Payment/views/PaymentCommissionAvecSoldePage")
  ),
  FraisAnnulationPaiementAvecSolde: React.lazy(
    () => import("pages/Payment/views/PaymentFraisAnnulationAvecSoldePage")
  ),
  FraisAbsencePaiementAvecSolde: React.lazy(
    () => import("pages/Payment/views/PaymentFraisAbsenceAvecSoldePage")
  ),
  CommissionPaiementParCB: React.lazy(
    () => import("pages/Payment/views/PaymentCommissionAvecCartePage")
  ),
  FraisAnnulationPaiementParCB: React.lazy(
    () => import("pages/Payment/views/PaymentFraisAnnulationAvecCartePage")
  ),
  FraisAbsencePaiementParCB: React.lazy(
    () => import("pages/Payment/views/PaymentFraisAbsenceAvecCartePage")
  ),
  FraisAbandonPaiementParCB: React.lazy(
    () => import("pages/Payment/views/PaymentFraisAbadonAvecCartePage")
  ),
  PayFraisAbandon: React.lazy(
    () => import("pages/Payment/views/PaymentFraisAbandonParSoldePage")
  ),
  FraisAbandonPaiementAvecSolde: React.lazy(
    () => import("pages/Payment/views/PaymentFraisAbandonAvecSoldePage")
  ),
};

export const PaymentAppRoutes = () => (
  <Routes>
    <Route key={"PaymentRoutes"} element={<NotRequireAuth />}>
      <Route
        path={PaymentRoutes.toChoiceModePayment()}
        element={<FeaturePayment.ChoisirModePaymentPage />}
      />
      <Route
        path={PaymentRoutes.toChoiceModePaymentCommission()}
        element={<FeaturePayment.ChoisirModePaymentCommissionPage />}
      />
      <Route
        path={PaymentRoutes.toChoiceModePaymentFraisAbandon()}
        element={<FeaturePayment.ChoisirModePaymentFraisAbandonPage />}
      />
      <Route
        path={PaymentRoutes.toChoiceModePaymentFraisAbsence()}
        element={<FeaturePayment.ChoisirModePaymentFraisAbsencePage />}
      />
      <Route
        path={PaymentRoutes.toChoiceModePaymentFraisAnulation()}
        element={<FeaturePayment.ChoisirModePaymentFraisAnnulationPage />}
      />
      <Route
        path={PaymentRoutes.toPaymentParSolde()}
        element={<FeaturePayment.PaymentAvecSoldePage />}
      />
      <Route
        path={PaymentRoutes.toPaymentParCarte()}
        element={<FeaturePayment.PaymentAvecCartePage />}
      />
      <Route
        path={PaymentRoutes.toPaymentParPaypal()}
        element={<FeaturePayment.PaymentAvecPaypalPage />}
      />
      <Route
        path={PaymentRoutes.toStripeSuccessPage()}
        element={<FeaturePayment.StripeSuccessPage />}
      />
      <Route
        path={PaymentRoutes.toStripeCBSuccessPage()}
        element={<FeaturePayment.StripeCBSuccessPage />}
      />
      <Route
        path={PaymentRoutes.toStripeCancelPage()}
        element={<FeaturePayment.StripeCancelledPage />}
      />
      <Route
        path={PaymentRoutes.toPaiementCommissionAvecSolde()}
        element={<FeaturePayment.CommissionPaiementAvecSolde />}
      />
      <Route
        path={PaymentRoutes.toPaiementFraisAnnulationAvecSolde()}
        element={<FeaturePayment.FraisAnnulationPaiementAvecSolde />}
      />
      <Route
        path={PaymentRoutes.toPaiementFraisAbsenceAvecSolde()}
        element={<FeaturePayment.FraisAbsencePaiementAvecSolde />}
      />
      <Route
        path={PaymentRoutes.toPaiementCommissionParCarte()}
        element={<FeaturePayment.CommissionPaiementParCB />}
      />
      <Route
        path={PaymentRoutes.toPaiementFraisAnnulationParCarte()}
        element={<FeaturePayment.FraisAnnulationPaiementParCB />}
      />
      <Route
        path={PaymentRoutes.toPaiementFraisAbsenceParCarte()}
        element={<FeaturePayment.FraisAbsencePaiementParCB />}
      />
      <Route
        path={PaymentRoutes.toPaiementFraisAbandonParCarte()}
        element={<FeaturePayment.FraisAbandonPaiementParCB />}
      />
      <Route
        path={PaymentRoutes.toPayFraisAbandon()}
        element={<FeaturePayment.PayFraisAbandon />}
      />
      <Route
        path={PaymentRoutes.toPaiementFraisAbadonAvecSolde()}
        element={<FeaturePayment.FraisAbandonPaiementAvecSolde />}
      />
    </Route>
  </Routes>
);
