import { REGEX } from "utils";

const resetValidate = (values: any) => {
  const errors: any = {
    beneficiaire: "",
    iban: "",
    bicswift: "",
    paypal: "",
  };

  // if (values.paypal) {

  // }
  //   if (!values.beneficiaire) {
  //     errors.beneficiaire = "Champ obligatoire!";
  //   }

  //   if (!values.iban) {
  //     errors.iban = "Champ obligatoire!";
  //   }

  //   if (!values.bicswift) {
  //     errors.bicswift = "Champ obligatoire!";
  //   }

  if (values.paypal && values.paypal.search(REGEX.EMAIL_REGEXP) === -1) {
    errors.paypal = "Veuillez entrer un email valide";
  }

  return errors;
};

export default resetValidate;
