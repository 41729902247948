import { InputProps } from "interfaces";
import React, { useState } from "react";

const InputFileImg = ({
  icon,
  inputSize = "h-12",
  rightIcon,
  label,
  bgColor = "bg-[#292929]",
  borderColor = "border-[#444444]",
  addClass,
  rounded = "rounded-md ",
  noShadow = false,
  setTouched,
  error,
  name,
  value,
  onChange,
  onChangeImage,
  onFileSelect,
  textRight,
  id,
  touched,
  ...inputProps
}: InputProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  any): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);

      // Appeler la fonction de rappel pour transmettre l'URL de l'image au parent
      if (onChange) {
        onChangeImage(imageUrl);
      }
      if (onFileSelect) {
        onFileSelect(file);
      }
    } else {
      setSelectedImage(null);
    }
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label className={"mb-2 text-sm font-light " + textRight}>
          {label}
        </label>
      )}
      <div className="relative  rounded-md">
        <input
          {...inputProps}
          type="file"
          id={id}
          className="hidden"
          name={name}
          onChange={handleChange} // Écoutez le changement de fichier ici
        />
        <label
          htmlFor={id}
          onClick={() => {
            setFocused(true);
          }}
        >
          <span
            id="file-name"
            className={`w-full pl-4 ${inputSize} overflow-hidden text-ellipsis mr-8 border text-md ${addClass}  ${bgColor}  ${
              error && touched
                ? "border-red-500"
                : focused
                ? " border-orange-belee"
                : borderColor
            } flex justify-start items-center space-x-4 ${rounded} ${
              noShadow ? "" : "shadow-sm shadow-dark-belee "
            } `}
          ></span>
          <span className="absolute p-4 top-0 right-0 cursor-pointer">
            {rightIcon && rightIcon}
          </span>
        </label>
      </div>
      {error && touched && <p className="text-xs text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default InputFileImg;
