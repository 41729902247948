const OvalIcon = () => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 19.5073C8.26099 19.5073 11.8848 14.9564 12.7784 13.5068C14.152 11.2784 15 9.96005 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 9.97285 0.785791 11.2824 2.15479 13.5068C3.10895 15.0571 6.73901 19.5073 7.5 19.5073ZM7.50071 11.2496C9.57178 11.2496 11.2507 9.5707 11.2507 7.49964C11.2507 5.42857 9.57178 3.74964 7.50071 3.74964C5.42964 3.74964 3.75071 5.42857 3.75071 7.49964C3.75071 9.5707 5.42964 11.2496 7.50071 11.2496Z"
        fill="#F7CE3B"
      />
    </svg>
  );
};

export default OvalIcon;
