import { InputProps } from "interfaces";
import React, { useState } from "react";

const Input = ({
  icon,
  inputSize = "h-12",
  rightIcon,
  label,
  bgColor = "bg-[#292929]",
  borderColor = "border-[#444444]",
  addClass,
  // rounded = "rounded-md ",
  rounded,
  noShadow,
  error,
  noBorder,
  textSize = "text-md",
  textRight,
  touched,
  centerText = false,
  noSpaceX = false,
  ...inputProps
}: InputProps & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="flex flex-col justify-start text-black">
      {label && (
        <label className={"mb-2 text-sm font-light " + textRight}>
          {label}
        </label>
      )}
      <div
        className={`w-full  ${inputSize} ${
          noBorder ? "" : "border"
        }  ${addClass}  ${bgColor}  ${
          error && touched
            ? "border-red-500"
            : focused
            ? " border-orange-belee"
            : borderColor
        } flex justify-start items-center ${
          noSpaceX ? "" : "space-x-4"
        }  ${rounded} ${noShadow ? "" : "shadow-sm"} `}
      >
        {icon && <div className="pl-4 text-orange-belee">{icon}</div>}
        <input
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          className={`placeholder-gray-500 ${
            icon && icon ? "pl-0" : "pl-4"
          } w-full h-full overflow-hidden ${centerText ? "text-center" : ""}  ${
            icon ? "rounded-r-md" : "rounded-md "
          } bg-transparent outline-none ${
            rightIcon ? "" : "rounded-r-md"
          }  ${textSize}`}
          {...inputProps}
        />
        {rightIcon && <div className="pr-4">{rightIcon}</div>}
      </div>
      {error && touched && (
        <p className="text-[10px] text-red-500 mt-1">{error}</p>
      )}
    </div>
  );
};

export default Input;
