import { InputProps } from "interfaces";
import { Search } from "react-feather";

const InputSearch = ({
  placeholder = "Rechercher un utilisateur",
  ...inputProps
}: InputProps & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  return (
    <div className="w-full h-11 rounded-lg flex justify-start items-center bg-gray-200 border border-orange-400 pl-6">
      <Search size={20} color="orange" />
      <input
        className={`w-full pl-4 h-full overflow-hidden bg-transparent border-none outline-none `}
        placeholder={placeholder}
        {...inputProps}
      />
    </div>
  );
};

export default InputSearch;
