export enum Devise {
  EUR = "Euro",
  XOF = "FCFA",
}

export enum CodeDevise {
  EUR = "EUR",
  CFA = "XOF",
}

export enum SymbolDevise {
  EUR = "€",
  CFA = "CFA",
}
