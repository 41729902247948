import { create } from "zustand";

type TrajetStoreState = {
  isUpdatedGame: string;
  isUpdatedSupplement: string;
  setIsUpdatedGamme: (isUpdated: string) => void;
  setIsUpdatedSupplement: (isUpdated: string) => void;
};

export const useTrajetStore = create<TrajetStoreState>((set) => ({
  isUpdatedGame: "",
  isUpdatedSupplement: "",

  setIsUpdatedGamme: (isUpdated?: string) =>
    set(() => ({ isUpdatedGame: isUpdated })),

  setIsUpdatedSupplement: (isUpdated?: string) =>
    set(() => ({ isUpdatedSupplement: isUpdated })),
}));
