import MenuLayout from "components/layouts/MenuLayout";
import { useAuth } from "hooks";
import { CompteRoutes } from "pages/Compte/CompteRoutes";
import { X } from "react-feather";
import { useNavigate } from "react-router-dom";
import { menuClient, menus } from "utils";
import MenuItem from "./MenuItem";
import { useEffect, useState } from "react";
import { MenuType } from "interfaces";
import { RoleUserEnum } from "enums/RoleUserEnum";
type Props = {
  showMenu: boolean;
  setShowMenu: (open: boolean) => void;
};
const Menu = ({ showMenu, setShowMenu }: Props) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState<MenuType[]>(menus);
  const { user } = useAuth();
  const [beleeAppVersion, setBeleeAppVersion] = useState<string>("");

  useEffect(() => {
    let beleeLocalVersion = window.localStorage.getItem("cachedVersion");
    if (beleeLocalVersion) {
      setBeleeAppVersion(beleeLocalVersion);
    }
  }, []);

  useEffect(() => {
    if (user && user.role === RoleUserEnum.ADMIN) {
      setMenu(menus);
    } else {
      setMenu(menuClient);
    }
  }, []);

  return (
    <MenuLayout isOpen={showMenu} closeMenu={setShowMenu}>
      <div className="float-right mt-2 mr-2">
        <div className="rounded-full p-1 text-white bg-orange-belee shadow-sm shadow-gray-500 hover:bg-gray-400 hover:text-white">
          <p
            className="cursor-pointer font-bold "
            onClick={() => setShowMenu(false)}
          >
            <X size={20} />
          </p>
        </div>
      </div>

      <div className="p-5 font-sans">
        <div
          className="flex justify-start items-center text-white  cursor-pointer mt-4"
          onClick={() => navigate(CompteRoutes.toProfile())}
        >
          <img
            src={`${
              user?.pdp
                ? process.env.REACT_APP_BACK_URL + user?.pdp
                : "/images/avatar-" +
                  (user?.gender === "Homme" ? "homme" : "femme") +
                  ".png"
            }`}
            alt=""
            className="w-16 h-16 rounded-full border-2 border-white object-cover"
          />

          <div className="text-left pl-1 text-[#6d6d6d]">
            <p className="font-semibold text-xl">
              {user?.lastname.toLocaleUpperCase() +
                " " +
                user?.firstname.toLocaleUpperCase()}
            </p>
            <p className="text-xs">{user?.email}</p>
          </div>
        </div>

        <p className="text-[#6d6d6d] font-bold mt-5 text-center">
          {user?.numero_compte}
        </p>

        {/* menu list */}
        <div className="flex flex-col space-y-4 text-[#6d6d6d] mt-8 pl-5">
          {menu.map((menu) => (
            <>
              {menu.access ? (
                <>
                  {user?.role.includes(menu.access) && (
                    <MenuItem menu={menu} key={menu.id} />
                  )}
                </>
              ) : (
                <MenuItem menu={menu} key={menu.id} />
              )}
            </>
          ))}
        </div>
      </div>

      {beleeAppVersion && (
        <div className="text-xs font-sans text-gray-600 text-center mr-2 mb-3 underline">
          Applicatiion version {beleeAppVersion}
        </div>
      )}
    </MenuLayout>
  );
};

export default Menu;
