import { useEffect } from "react";
import { ArrowDownLeft, ArrowUpRight } from "react-feather";
import HistoryItemTransfert from "./HistoryItemTransfert";
import { getDeviseSymbole } from "helpers";

type Props = {
  key: string;
  history: any;
};
const HistoryItem = ({ history, key }: Props) => {
  const getTypePaiement = () => {
    return (
      history?.motif_paiement ==
        "Paiement de frais d'annulations par carte bancaire" ||
      history?.motif_paiement == "Paiement de frais d'annulations par paypal" ||
      history?.motif_paiement == "Commission AFF" ||
      history?.motif_paiement == "Compensation annulation" ||
      history?.motif_paiement == "Paiement de frais d'annulations par solde" ||
      history?.motif_paiement == "Commission" ||
      history?.motif_paiement == "Paiement de commissions par paypal" ||
      history?.motif_paiement == "Paiement de commissions par carte bancaire" ||
      history?.motif_paiement == "Paiement de commissions par solde" ||
      history?.motif_paiement == "Paiement de commande par carte bancaire" ||
      history?.motif_paiement == "Paiement de commande par solde" ||
      history?.motif_paiement == "Rétribution Gains" ||
      history?.motif_paiement === "Compensation absence" ||
      history?.motif_paiement === "Compensation abandon" ||
      history?.motif_paiement === "Remboursement commande"
    );
  };

  useEffect(() => {
    if ("Transfert d'argent" == history?.motif_paiement) {
      console.log(history);
    }
  }, []);
  return (
    <div className="grid grid-cols-12 gap-2">
      <>
        {history && history?.type == "REMBOURSEMENT" ? (
          <>
            <div className="col-span-9 grid grid-cols-12 gap-4">
              <div className="col-span-2 flex justify-start items-center">
                <div className="w-7 h-7 bg-white rounded-md flex justify-center items-center">
                  <ArrowDownLeft className="text-green-500" />
                </div>
              </div>

              <div className="col-span-9 flex flex-col">
                <p className=" text-[11px]">
                  Motif : {history?.motif_paiement} <br />
                  {history?.detail?.fraisBancaireTotal ? (
                    <>
                      Frais bancaires :
                      {" " + history?.detail?.fraisBancaireTotal.toFixed(2)}
                      € <br />
                    </>
                  ) : (
                    ""
                  )}
                  Montant demandé :{" "}
                  {history?.detail?.transaction_total.toFixed(2)} € <br />
                  <span className="text-[9px]">{history?.numero_paiement}</span>
                  <br />
                  {history.commande[0]
                    ? history.commande[0]?.numero_commande
                    : ""}
                </p>
              </div>
            </div>
            <div className="col-span-3 flex flex-col space-y-1 justify-start">
              <div className="flex flex-col">
                <p className={`text-[10px] text-green-500`}>
                  {history?.transaction_amount.toFixed(2)} €
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            {(history && history?.type == "RETRAIT") ||
            history?.type == "PAIEMENT" ? (
              <>
                <div className="col-span-9 grid grid-cols-12 gap-4">
                  <div className="col-span-2 flex justify-start items-center">
                    <div className="w-7 h-7 bg-white rounded-md flex justify-center items-center">
                      {history.type === "DEPOT" || history.type === "GAINS" ? (
                        <ArrowDownLeft className="text-green-500" />
                      ) : (
                        <ArrowUpRight className="text-red-500" />
                      )}
                    </div>
                  </div>

                  <div className="col-span-9 flex flex-col">
                    <p className=" text-[11px]">
                      Motif : {history?.motif_paiement} <br />
                      {history?.detail?.fraisBancaireTotal ? (
                        <>
                          Frais bancaires :{" "}
                          {history?.detail?.fraisBancaireTotal.toFixed(2)}
                          € <br />
                        </>
                      ) : (
                        ""
                      )}
                      {history.commande[0]
                        ? history.commande[0]?.numero_commande
                        : history.type}
                      <br />
                      {getTypePaiement() ? (
                        ""
                      ) : (
                        <>
                          Montant demandé :{" "}
                          {history?.transaction_amount.toFixed(2)} € <br />
                        </>
                      )}
                      <span className="text-[9px]">
                        {history?.numero_paiement}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-span-3 flex flex-col space-y-1 justify-start">
                  <div className="flex flex-col">
                    <p
                      className={`text-[10px] ${
                        history.type === "DEPOT" || history.type === "GAINS"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {history.type === "DEPOT" || history.type === "GAINS"
                        ? "+"
                        : "-"}{" "}
                      {history?.transaction_amount.toFixed(2)} €
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-span-9 grid grid-cols-12 gap-4">
                  <div className="col-span-2 flex justify-start items-center">
                    <div className="w-7 h-7 bg-white rounded-md flex justify-center items-center">
                      {history.type === "DEPOT" || history.type === "GAINS" ? (
                        <ArrowDownLeft className="text-green-500" />
                      ) : (
                        <ArrowUpRight className="text-red-500" />
                      )}
                    </div>
                  </div>

                  <div className="col-span-9 flex flex-col">
                    <p className=" text-[11px]">
                      Motif : {history?.motif_paiement}
                      <br />
                      {/* Paiement methode : {history?.paiement_methode} */}
                      {history.commande[0]
                        ? history.commande[0]?.numero_commande
                        : history.type}
                      <br />
                      {history?.detail?.fraisBancaireTotal ? (
                        <>
                          Frais bancaires :{" "}
                          {history?.detail?.fraisBancaireTotal.toFixed(2) +
                            " " +
                            getDeviseSymbole(history.currency)}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {getTypePaiement() ? (
                        ""
                      ) : (
                        <>
                          Montant payée : {history?.transaction_amount} € <br />
                          Montant chargé : {history?.detail?.recharge} € <br />
                        </>
                      )}
                      <span className="text-[9px]">
                        {history?.numero_paiement}
                      </span>
                    </p>
                    {/* 
          
          <p className="text-sm text-gray-400">
            {history.tarifTrajet[0]?.point_depart.formatted_address.substr(
              0,
              25
            )}
          </p> */}
                  </div>
                </div>
                <div className="col-span-3 flex flex-col space-y-1 justify-start">
                  <div className="flex flex-col">
                    <p
                      className={`text-[10px] ${
                        history.type === "DEPOT" || history.type === "GAINS"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {history.type === "DEPOT" || history.type === "GAINS"
                        ? "+"
                        : "-"}{" "}
                      {getTypePaiement()
                        ? history?.transaction_amount.toFixed(2)
                        : history?.detail?.recharge}{" "}
                      €
                    </p>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default HistoryItem;
