import { useNavigate } from "react-router-dom";

const FooterContent = () => {
  return (
    <div className="lg:fixed z-50 bottom-0 w-full">
      <div className="bg-black text-white bg-opacity-[0.7]  lg:px-32 md:px-10 px-10 lg:py-6 md:py-6">
        <div className="grid grid-cols-3 items-center justify-between">
          <div className="flex justify-center">
            <img
              src="/belee-logo-transparent-white.png"
              className="lg:w-[200px] md:w-[100px] w-[100px]"
              alt=""
            />
          </div>

          <div className="lg:text-xl md:text-sm text-base font-medium text-center">
            <h1 className="">2 rue Auguste renoir</h1>
            <h1 className="">94800 Villejuif </h1>
            <h1 className="">Tel: 01 79 73 20 00</h1>
          </div>

          <div className="lg:block md:block hidden text-[#24abbb] font-medium text-center">
            <h1 className="cursor-pointer lg:text-xl md:text-sm text-sm mb-2">
              CGU
            </h1>
            <h1 className="cursor-pointer lg:text-xl md:text-sm text-sm">
              politique de confidentialité
            </h1>
          </div>
        </div>

        <div className="lg:hidden md:hidden block text-[#24abbb] text-base font-medium text-center mt-5">
          <h1 className="mb-2">CGU</h1>
          <h1 className="">politique de confidentialité</h1>
        </div>
      </div>
    </div>
  );
};

export default FooterContent;
