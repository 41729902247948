export const UserRoutes = {
  toUsers: (): string => "/users",
  toAddUser: (): string => "/users/invite",
  // toInviteUser: (): string => "/users/invite",
  toSendInvitationUser: (role?: string): string =>
    role ? "/users/invite/" + role : "/users/invite/:role",
  toQrCodePdf: (role?: string): string =>
    role ? "/users/invite/qrcode/" + role : "/users/invite/qrcode/:role",
  toDetailsUser: (id?: string): string =>
    id ? "/users/details/" + id : "/users/details/:id",
  toAddFavoris: (): string => "/favoris/ajout",
  toListeFavoris: (): string => "/favoris/liste",
};
