import { TransfertRoutes } from "pages";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";

const FeatureTransfert = {
  SaisirTransfertPage: React.lazy(
    () => import("pages/Banques/Transfert/views/SaisirTransfertPage")
  ),
  NouveauSoldePage: React.lazy(
    () => import("pages/Banques/Transfert/views/NouveauSoldePage")
  ),
};

export const TransfertAppRoutes = () => (
  <Routes>
    <Route key={"TransfertAppRoutes"} element={<RequireAuth />}>
      <Route
        path={TransfertRoutes.toSaisirTransfert()}
        element={<FeatureTransfert.SaisirTransfertPage />}
      />
      <Route
        path={TransfertRoutes.toNouveauSolde()}
        element={<FeatureTransfert.NouveauSoldePage />}
      />
    </Route>
  </Routes>
);
