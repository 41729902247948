import { REGEX } from "utils";
import i18n from "i18n";

const resetValidate = (values: any) => {
  const errors: any = {
    phone: "",
    email: "",
  };

  if (values.email && values.email.search(REGEX.EMAIL_REGEXP) === -1) {
    errors.email = i18n.t("forgotPassword.invalidEmail");
  }

  if (values.phone && values.phone.search(REGEX.PHONE_REGEXP) === -1) {
    errors.phone = "Veuillez entrer numero valide";
  }

  return errors;
};

export default resetValidate;
