import { UpdateAuthData } from "api";
import { REGEX } from "utils";

const updateProfileValidate = (values: UpdateAuthData) => {
  const errors: UpdateAuthData = {
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
    birth_date: "",
  };
  if (!values.firstname) {
    errors.firstname = "Le prénom est obligatoire";
  } else if (values.firstname.length < 4) {
    errors.firstname = "Votre prénom est invalide, minimum 4 caractères";
  }

  if (!values.lastname) {
    errors.lastname = "Le nom est obligatoire";
  } else if (values.lastname.length < 4) {
    errors.lastname = "Votre nom est invalide, minimum 4 caractères";
  }

  if (!values.email) {
    errors.email = "L'adresse email est obligatoire";
  } else if (values.email.search(REGEX.EMAIL_REGEXP) === -1) {
    errors.email = "Veuillez entrer un email valide";
  }

  if (!values.phone) {
    errors.phone = "Le numero telephone est obligatoire";
  } else if (values.phone.search(REGEX.PHONE_REGEXP) === -1) {
    errors.phone = "Veuillez entrer numero valide";
  }

  if (!values.birth_date) {
    errors.birth_date = "La date est obligatoire";
  }

  return errors;
};

export default updateProfileValidate;
