import { getAllAccountOneUserAPI } from "api";
import { CodeDevise } from "enums";
import { useEffect, useState } from "react";

export const useWallet = () => {
  const [balance, setBalance] = useState<number>(0);
  const [balanceCFA, setBalanceCFA] = useState<number>(0);
  useEffect(() => {
    const getBalance = async () => {
      const balance = await getAllAccountOneUserAPI();

      if (typeof balance !== "string") {
        // console.log(balance);
        for (let i = 0; i < balance.length; i++) {
          const element = balance[i];
          if (element.currency === CodeDevise.EUR) {
            setBalance(element.balance.toFixed(2));
          } else if (element.currency === CodeDevise.CFA) {
            setBalanceCFA(element.balance.toFixed(2));
          }
        }
        // setBalance(balance.balance.toFixed(2));
      }
    };
    getBalance();
  }, []);
  return { balance, balanceCFA };
};
