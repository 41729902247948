import { VehiculeData } from "api";
import { REGEX } from "utils";

const validationVehicule = (values: VehiculeData) => {
  const errors: any = {
    type_usage: "",
    immatriculation: "",
    marque: "",
    modele: "",
    nombre_places_passagers: "",
    nombre_bagages_transportables: "",
    nombre_rehausseurs_enfants: "",
    nombre_sieges_bebe: "",
    date_premiere_circulation: "",
    date_fin_controle_technique: "",
    date_fin_contrat_assurance: "",
    carte_grise: "",
    controle_technique: "",
    document_assurance: "",
    certificat_acception: "",
  };
  const aujourdHui = new Date();

  if (!values.immatriculation) {
    errors.immatriculation = "Ce champ est obligatoire";
  } else if (
    values.immatriculation.search(REGEX.IMMATRICULATION_REGEXP) === -1
  ) {
    errors.immatriculation = "Veuillez entrer une immatriculation valide";
  }
  if (!values.marque) {
    errors.marque = "Ce champ est obligatoire";
  }
  if (!values.modele) {
    errors.modele = "Ce champ est obligatoire";
  }

  if (values.nombre_places_passagers && values.nombre_places_passagers < 1) {
    errors.nombre_places_passagers = "Valeur invalide";
  }
  if (
    values.nombre_bagages_transportables &&
    values.nombre_bagages_transportables < 0
  ) {
    errors.nombre_bagages_transportables = "Valeur invalide";
  }
  if (
    values.nombre_rehausseurs_enfants &&
    values.nombre_rehausseurs_enfants < 0
  ) {
    errors.nombre_rehausseurs_enfants = "Valeur invalide";
  }
  if (values.nombre_sieges_bebe && values.nombre_sieges_bebe < 0) {
    errors.nombre_sieges_bebe = "Valeur invalide";
  }

  if (!values.date_premiere_circulation) {
    errors.date_premiere_circulation = "Ce champ est obligatoire";
  } else {
    const datePremiereCirculation = new Date(values.date_premiere_circulation);
    if (datePremiereCirculation >= aujourdHui) {
      errors.date_premiere_circulation =
        "La date de première circulation doit être antérieure à aujourd'hui";
    }
  }

  if (!values.date_fin_controle_technique) {
    errors.date_fin_controle_technique = "Ce champ est obligatoire";
  } else {
    const dateFinControleTechnique = new Date(
      values.date_fin_controle_technique
    );
    if (dateFinControleTechnique <= aujourdHui) {
      errors.date_fin_controle_technique =
        "La date doit être postérieure à aujourd'hui";
    }
  }

  if (!values.date_fin_contrat_assurance) {
    errors.date_fin_contrat_assurance = "Ce champ est obligatoire";
  } else {
    const dateFinControleTechnique = new Date(
      values.date_fin_contrat_assurance
    );
    if (dateFinControleTechnique <= aujourdHui) {
      errors.date_fin_contrat_assurance =
        "La date doit être postérieure à aujourd'hui";
    }
  }

  if (!values.carte_grise) {
    errors.carte_grise = "Ce champ est obligatoire";
  }

  if (!values.controle_technique) {
    errors.controle_technique = "Ce champ est obligatoire";
  }

  if (!values.document_assurance) {
    errors.document_assurance = "Ce champ est obligatoire";
  }

  return errors;
};

export default validationVehicule;
