import { IconProps } from "react-feather";

const DollarIcon = ({ width = "22", height = "22" }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.75">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
          fill="white"
        />
        <path
          d="M11.91 13.14C11.49 13.52 10.88 13.73 10.07 13.77V15H9.54V13.79C9.02 13.76 8.53 13.66 8.08 13.49C7.62 13.32 7.26 13.09 7 12.83L7.31 12.22C7.56 12.46 7.88 12.66 8.28 12.83C8.68 12.99 9.1 13.09 9.54 13.12V10.32C9.05 10.2 8.64 10.07 8.32 9.93C7.99 9.79 7.72 9.59 7.51 9.32C7.29 9.05 7.18 8.69 7.18 8.26C7.18 7.71 7.38 7.25 7.78 6.88C8.18 6.51 8.77 6.29 9.55 6.23V5H10.08V6.21C10.48 6.22 10.88 6.29 11.26 6.41C11.64 6.53 11.97 6.68 12.24 6.87L11.97 7.5C11.68 7.32 11.37 7.17 11.04 7.06C10.71 6.95 10.39 6.89 10.07 6.88V9.7C10.59 9.83 11.02 9.96 11.36 10.09C11.69 10.22 11.97 10.42 12.2 10.69C12.43 10.96 12.54 11.31 12.54 11.75C12.54 12.3 12.33 12.76 11.91 13.14ZM8.37 9.07C8.64 9.26 9.03 9.42 9.54 9.56V6.9C9.02 6.95 8.62 7.09 8.35 7.33C8.08 7.58 7.95 7.87 7.95 8.23C7.96 8.6 8.09 8.88 8.37 9.07ZM11.34 12.69C11.62 12.46 11.77 12.16 11.77 11.8C11.77 11.42 11.62 11.13 11.33 10.94C11.04 10.75 10.62 10.59 10.07 10.45V13.11C10.63 13.06 11.05 12.92 11.34 12.69Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default DollarIcon;
