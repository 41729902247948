type Props = {
  compte: any;
  selected?: any;
  setChoice: (choice: object) => void;
};
const CompteItem = ({ compte, setChoice, selected }: Props) => {
  return (
    <div
      onClick={() => {
        setChoice(compte);
      }}
      className={`grid rounded grid-cols-12 border rounded-xl cursor-pointer transition-all duration-200 ${
        selected && selected?.id === compte.id
          ? "border border-white shadow-lg bg-gray-400 text-white "
          : "border-transparent"
      }  `}
    >
      <div className="col-span-2 xs:col-span-2 flex justify-start items-center">
        <div className="w-11 h-11 bg-gray-400 flex justify-center items-center rounded-xl">
          {compte.icon}
        </div>
      </div>

      <div
        className={`col-span-10 xs:col-span-10 ${
          compte.identifiant ? "" : "flex items-center"
        }`}
      >
        <p className="font-semibold">{compte.type}</p>
        <p className="tracking-widest font-sans text-xs overflow-hidden">
          {compte.identifiant}
        </p>
      </div>
    </div>
  );
};

export default CompteItem;
