type Props = {
  width?: string;
  height?: string;
  fillColor?: string;
};

const StartIcon = ({
  width = "11",
  height = "10",
  fillColor = "#f48d26",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.86622 3.03192L5.50926 0.15241C5.41349 -0.0508035 5.11842 -0.0508033 5.02266 0.15241L3.66569 3.03192C3.62667 3.11474 3.54648 3.17176 3.45394 3.1825L0.236715 3.55587C0.00966751 3.58222 -0.0815153 3.8569 0.0863464 4.00884L2.46492 6.16184C2.53334 6.22377 2.56397 6.31603 2.54581 6.40549L1.91441 9.51575C1.86985 9.73525 2.10857 9.90501 2.30808 9.7957L5.13509 8.24682C5.2164 8.20227 5.31552 8.20227 5.39683 8.24682L8.22383 9.7957C8.42334 9.90501 8.66206 9.73525 8.6175 9.51575L7.98611 6.40549C7.96795 6.31603 7.99858 6.22377 8.06699 6.16184L10.4456 4.00884C10.6134 3.8569 10.5222 3.58222 10.2952 3.55587L7.07797 3.1825C6.98544 3.17176 6.90525 3.11474 6.86622 3.03192Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default StartIcon;
