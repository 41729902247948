import i18n from "i18n";

const newPassValidate = (values: any) => {
  const errors: any = {
    password: "",
    password_confirmation: "",
  };

  if (!values.password) {
    errors.password = i18n.t("register.passwordRequired");
  } else if (values.password.length < 8) {
    errors.password = i18n.t("register.invalidPassword");
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = i18n.t("register.confirmPasswordRequired");
  } else if (values.password_confirmation !== values.password) {
    errors.password_confirmation = i18n.t("register.invalidConfirmPassword");
  }

  return errors;
};

export default newPassValidate;
