import { client } from "./client";

export const sendLinkInfoCashToPayeurAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post(
      "/whatsapp/send/link/retrait/cash",
      data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};
