import { REGEX } from "utils";
import i18n from "i18n";

const confirmValidate = (values: any) => {
  const errors: any = {
    nom: "",
    prenom: "",
    phone: "",
    password: "",
    password_confirmation: "",
  };

  if (!values.email) {
    errors.email = i18n.t("register.emailRequired");
  } else if (values.email.search(REGEX.EMAIL_REGEXP) === -1) {
    errors.email = i18n.t("register.invalidEmail");
  }

  if (!values.prenom) {
    errors.prenom = "Le prenom est obligatoire";
  } else if (values.prenom.length < 4) {
    errors.prenom = "Votre prenom est invalide, minimum 4 caractères";
  }

  if (!values.nom) {
    errors.nom = "Le nom est obligatoire";
  } else if (values.nom.length < 4) {
    errors.nom = "Votre nom est invalide, minimum 4 caractères";
  }

  if (!values.phone) {
    errors.phone = "Le numero téléphone est obligatoire";
  } else if (values.phone.length > 15) {
    errors.phone = "Veuillez entrer numero valide";
  }

  if (!values.password) {
    errors.password = "Le mot de passe est obligatoire";
  } else if (values.password.length < 8) {
    errors.password = "Votre mot de passe est invalide, minimum 8 caractères";
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = "Ce champ est obligatoire";
  } else if (values.password_confirmation !== values.password) {
    errors.password_confirmation =
      "Les deux mot de passe ne sont pas identique";
  }

  return errors;
};

export default confirmValidate;
