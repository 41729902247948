import LineIcon from "components/elements/icons/LineIcon";
import { CommandeType } from "interfaces";
import { Eye } from "react-feather";
import { useNavigate } from "react-router-dom";
import { CommandeRoutes } from "../CommandeRoutes";
import {
  capitalizeFirstLetter,
  getAddressFormatVilleWithCodePostale,
} from "helpers";
import { useEffect, useState } from "react";

type Props = {
  commande: CommandeType;
  isFirst?: boolean;
  isLast?: boolean;
};
const CommandeItem = ({ commande, isFirst, isLast }: Props) => {
  const [codePostalDepart, setCodePostalDepart] = useState<any>();
  const [villeDepart, setVilleDepart] = useState<any>();
  const [codePostalArrivee, setCodePostalArrivee] = useState<any>();
  const [villeArrivee, setVilleArrivee] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (commande.trajet) {
      if (commande.trajet.point_depart) {
        setCodePostalDepart(
          getAddressFormatVilleWithCodePostale(
            commande.trajet.point_depart.address_components,
            "code_postale"
          )
        );
        setVilleDepart(
          getAddressFormatVilleWithCodePostale(
            commande.trajet.point_depart.address_components,
            "ville"
          )
        );
      }
      if (commande.trajet.point_arrivee) {
        setCodePostalArrivee(
          getAddressFormatVilleWithCodePostale(
            commande.trajet.point_arrivee.address_components,
            "code_postale"
          )
        );
        setVilleArrivee(
          getAddressFormatVilleWithCodePostale(
            commande.trajet.point_arrivee.address_components,
            "ville"
          )
        );
      }
    }
  }, []);

  return (
    <div className="grid grid-cols-12 gap-3 mt-2 mb-2">
      {/* <div
      className={`grid grid-cols-12 text-[#80A9C0] flex justify-center items-center col-span-6 text-[10px] xs:text-xs pt-3 bg-[#2B2B2B] ${
        isFirst ? "rounded-t-lg " : " "
      } ${isLast ? "rounded-b-lg pb-5" : " "}`}
    > */}
      <div className="grid grid-cols-12 text-dark-belee rounded-lg flex justify-center pb-3 pt-3 items-center col-span-6 text-[10px] xs:text-xs bg-gray-300">
        <div className="col-span-5 flex justify-center items-center text-center">
          <p>
            {villeDepart} <br />
            {codePostalDepart}{" "}
          </p>
        </div>
        <div className="col-span-2 flex justify-center items-center">
          <LineIcon />
        </div>

        <div className="col-span-5 flex justify-center items-center text-center">
          <p>
            {villeArrivee} <br />
            {codePostalArrivee}{" "}
          </p>
        </div>
      </div>
      {/* <div
        className={`gap-0 text-[10px] xs:text-xs col-span-4 pt-3 bg-[#2B2B2B] px-2 ${
          isFirst ? "rounded-t-lg" : ""
        } ${isLast ? "rounded-b-lg pb-5" : ""}`}
      > */}
      <div className="gap-0 flex text-center items-center justify-center rounded-lg text-[10px] xs:text-xs col-span-4 bg-gray-300 px-2">
        <div className="text-center">
          <p>
            {capitalizeFirstLetter(commande.evolution.replaceAll("_", " "))}
          </p>
        </div>
      </div>
      {/* <div
        className={`flex justify-center items-center col-span-2 pt-3 bg-[#2B2B2B] cursor-pointer ${
          isFirst ? "rounded-t-lg" : ""
        } ${isLast ? "rounded-b-lg pb-5" : ""}`}
      > */}
      <div className="flex justify-center items-center col-span-2 bg-gray-300 cursor-pointer rounded-lg">
        <Eye
          size={18}
          onClick={() => navigate(CommandeRoutes.toCommandeDetal(commande._id))}
        />
      </div>
    </div>
  );
};

export default CommandeItem;
