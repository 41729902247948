import { EntrepriseData } from "./ApiInterfaces";
import { client, clientFile } from "./client";

export const createEntreprise = async (
  userId: string,
  data: EntrepriseData
) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/auth/signup/entreprise/" + userId,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const creerEntrepriseAPI = async (
  userId: string,
  data: EntrepriseData
) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/entreprise/creation/" + userId,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const updateEntreprise = async (
  entrepriseSlug: string,
  data: EntrepriseData
) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.put(
      "/entreprises/update/" + entrepriseSlug,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getEntrepriseDetailByIdAPI = async (entrepriseId: string) => {
  let err = "";
  try {
    const response = await client.get("/entreprises/get/" + entrepriseId);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getEntrepriseEncaissementMethodeAPI = async (
  entrepriseId: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/paiement/encaissement/get/" + entrepriseId
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setEntrepriseEncaissementMethodeAPI = async (
  entreprise: string,
  iban: string,
  bicswift: string,
  beneficiaire: string,
  paypal: string
) => {
  let err = "";
  let data = {
    entreprise,
    iban,
    bicswift,
    beneficiaire,
    paypal,
  };
  try {
    const response = await client.post("/paiement/encaissement/set/", data);

    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getDispatchingEntrepriseAPI = async (entrepriseId: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/get/dispatching-commandes/" + entrepriseId
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setDispatchingEntrepriseAPI = async (
  entreprise: string,
  disptachEntreprise: string
) => {
  let err = "";
  let data = {
    entreprise,
    disptachEntreprise,
  };
  try {
    const response = await client.post(
      "/entreprise/set/dispatching-commandes",
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
