import { t } from "i18next";
import { REGEX } from "utils";

const validateInfoClient = (values: any) => {
  const errors: any = {
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
  };
  // if (!values.firstname) {
  //   errors.firstname = "Le prénom est obligatoire";
  // } else if (values.firstname.length < 4) {
  //   errors.firstname = "Votre prénom est invalide, minimum 4 caractères";
  // }

  if (!values.lastname) {
    errors.lastname = "Le nom est obligatoire";
  } else if (values.lastname.length < 4) {
    errors.lastname = "Votre nom est invalide, minimum 4 caractères";
  }

  if (values.email && values.email.search(REGEX.EMAIL_REGEXP) === -1) {
    errors.email = t("login.invalidEmail");
  }

  // if (!values.phone) {
  //   errors.phone = "Le numero telephone est obligatoire";
  // }
  // else if (values.phone.search(REGEX.PHONE_REGEXP) === -1) {
  //   errors.phone = "Veuillez entrer numero valide";
  // }

  return errors;
};

export default validateInfoClient;
