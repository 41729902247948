import { ForfaitType } from "interfaces/ForfaitType";
import { client } from "./client";
import { ParametreDeviseData } from "./ApiInterfaces";

export const GetParametreEntrepriseAPI = async (
  entrepriseId?: any
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/zones/entreprise/setting/" + entrepriseId
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllForfaitsAPI = async (): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/zones/pays/forfait/liste");
    if (response.status === 200 || response.status === 201) {
      return response.data as ForfaitType[];
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const supprimerForfaitAPI = async (forfait_id: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/pays/forfait/delete/" + forfait_id
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const setParametreAPI = async (data: ParametreDeviseData) => {
  let err = "";
  let link = "";
  if (data.pays) {
    link = "/zones/pays/setting/" + data.pays;
  } else {
    link = "/zones/pays/setting/";
  }

  try {
    const response = await client.post(link, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};
