const VisaIcon = () => {
  return (
    <svg
      width="40"
      height="20"
      viewBox="0 0 40 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6127 5.94141L13.8848 16.0816H16.6483L18.3777 5.94141H15.6127Z"
        fill="#B2D0CE"
      />
      <path
        d="M11.5697 5.95135L8.863 12.8666L8.57442 11.8225C8.04061 10.5644 6.52521 8.75755 4.74609 7.61887L7.22107 16.0752L10.1453 16.0702L14.4974 5.94922L11.5697 5.95135Z"
        fill="#B2D0CE"
      />
      <path
        d="M7.52427 6.67732C7.36363 6.05965 6.89806 5.87555 6.32019 5.85352H2.03554L2 6.05538C5.33432 6.86426 7.54062 8.81325 8.45612 11.1567L7.52427 6.67732Z"
        fill="#B2D0CE"
      />
      <path
        d="M23.9799 7.88378C24.8841 7.86957 25.5394 8.06717 26.0483 8.27187L26.2978 8.38916L26.6717 6.19139C26.1244 5.98597 25.2665 5.76562 24.196 5.76562C21.4652 5.76562 19.5403 7.14101 19.5254 9.11203C19.5076 10.5684 20.8965 11.3816 21.9457 11.8671C23.0225 12.3646 23.3836 12.6809 23.3786 13.1252C23.3701 13.804 22.52 14.1153 21.726 14.1153C20.6193 14.1153 20.0315 13.9625 19.1238 13.5843L18.7677 13.4223L18.3789 15.6918C19.0257 15.9754 20.2191 16.2192 21.4581 16.232C24.3631 16.232 26.2509 14.873 26.2708 12.7669C26.2829 11.6147 25.5458 10.7355 23.9487 10.014C22.982 9.54348 22.3906 9.23073 22.3963 8.75592C22.3963 8.33442 22.8981 7.88378 23.9799 7.88378Z"
        fill="#B2D0CE"
      />
      <path
        d="M33.7646 5.95312H31.6294C30.9662 5.95312 30.4729 6.13367 30.1815 6.7947L26.0781 16.0876H28.9803C28.9803 16.0876 29.4537 14.838 29.561 14.5644C29.8787 14.5644 32.6984 14.5686 33.1 14.5686C33.1825 14.9226 33.437 16.0876 33.437 16.0876H36.0008L33.7646 5.95312ZM30.3564 12.4889C30.5838 11.9067 31.4574 9.65493 31.4574 9.65493C31.4425 9.68265 31.6827 9.06852 31.8256 8.68683L32.0118 9.5611C32.0118 9.5611 32.5414 11.9821 32.6522 12.4889H30.3564Z"
        fill="#B2D0CE"
      />
    </svg>
  );
};

export default VisaIcon;
