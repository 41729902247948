/* eslint-disable react-hooks/exhaustive-deps */
import { getVehiculeDetails, VehiculeData } from "api";
import { createVehicule } from "api/vehicule";
import Button from "components/elements/buttons/Button";
import Input from "components/elements/inputs/Input";
import InputFile from "components/elements/inputs/InputFile";
import Modal from "components/elements/modals/Modal";
import { useAuth, useForm, useLocalStorage } from "hooks";
import { useEffect, useState } from "react";
import { Check, Edit2, Info } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import validationVehicule from "../utils/validationVehicule";
import { VehiculeRoutes } from "../VehiculeRoutes";
import { AccountRoutes } from "pages/Accounts";
import { AccueilRoutes } from "pages/Accueil";

type Props = {
  setIsLoading: (isLoading: boolean) => void;
};

const VehiculeActionForm = ({ setIsLoading }: Props) => {
  const { user, userEntreprise } = useAuth();
  const { type_usage } = useParams();
  const navigate = useNavigate();
  const [isShow, setIshow] = useState<boolean>(false);
  const [isShowError, setIshowError] = useState<boolean>(false);
  const [notEditable, setNotEditable] = useState<boolean>(false);

  const [error, setError] = useState<string | undefined>();

  const [isClear, setIsClear] = useState<boolean>(false);
  let initialisations: VehiculeData = {
    type_usage: type_usage + "",
    immatriculation: "",
    marque: "",
    modele: "",
    couleur: "",
    nombre_places_passagers: 0,
    nombre_bagages_transportables: 0,
    nombre_rehausseurs_enfants: 0,
    nombre_sieges_bebe: 0,
    date_premiere_circulation: "",
    date_fin_controle_technique: "",
    date_fin_contrat_assurance: "",
    carte_grise: "",
    controle_technique: "",
    document_assurance: "",
    certificat_acception: "",
  };

  const [defaultValues, setDefaultValues] = useLocalStorage<VehiculeData>(
    "vehicule_option_" + (user ? user.id : ""),
    initialisations
  );

  let initialValues: VehiculeData = {
    type_usage: defaultValues.type_usage,
    immatriculation: defaultValues.immatriculation,
    marque: defaultValues.marque,
    modele: defaultValues.modele,
    couleur: "",
    nombre_places_passagers: defaultValues.nombre_places_passagers,
    nombre_bagages_transportables: defaultValues.nombre_bagages_transportables,
    nombre_rehausseurs_enfants: defaultValues.nombre_rehausseurs_enfants,
    nombre_sieges_bebe: defaultValues.nombre_sieges_bebe,
    date_premiere_circulation: defaultValues.date_premiere_circulation,
    date_fin_controle_technique: defaultValues.date_fin_controle_technique,
    date_fin_contrat_assurance: defaultValues.date_fin_contrat_assurance,
    carte_grise: defaultValues.carte_grise,
    controle_technique: defaultValues.controle_technique,
    document_assurance: defaultValues.document_assurance,
    certificat_acception: defaultValues.certificat_acception,
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setTouched,
    setValues,
    valid,
    pristine,
    submitting,
  } = useForm<any>({
    initialValues,
    validate: validationVehicule,
    // type_usage === "TAXI_VTC"
    //     ? validationVehiculeVTC
    //     : validationVehicule,
  });

  const onsubmit = async (values: VehiculeData) => {
    setIsLoading(true);
    if (values.nombre_places_passagers === null) {
      values.nombre_places_passagers = 0;
    }
    if (values.nombre_bagages_transportables === null) {
      values.nombre_bagages_transportables = 0;
    }
    if (values.nombre_rehausseurs_enfants === null) {
      values.nombre_rehausseurs_enfants = 0;
    }
    if (values.nombre_sieges_bebe === null) {
      values.nombre_sieges_bebe = 0;
    }
    let userStringify = localStorage.getItem("bappauth");
    console.log("user", user);
    console.log("userStringify", userStringify);

    let entreprise = null;
    if (user && user.entreprise) {
      entreprise = user.entreprise;
    }

    if (userEntreprise && userEntreprise.slug) {
      entreprise = userEntreprise;
    }

    if (userStringify) {
      let user = JSON.parse(userStringify);
      if (user && user.entreprise) {
        if (user.entreprise && user.entreprise.slug) {
          entreprise = user.entreprise;
        }
      }
    }

    if (entreprise) {
      const response = await createVehicule(entreprise.slug, values);
      if (typeof response !== "string") {
        setValues(initialValues);
        setTouched({});
        setError(undefined);
        setIshow(true);
        setDefaultValues(initialisations);
        setTimeout(() => {
          navigate(VehiculeRoutes.toSuccessVehicule());
        }, 1000);
        setIsLoading(false);
        return;
      } else {
        setError(response);
        setIshowError(true);
        setDefaultValues(values);
        setIsLoading(false);
        return;
      }
    } else {
      setError("Vous n'avez pas d'entreprise valide");
      setDefaultValues(values);
      setIsLoading(false);
      return;
    }
  };

  const getFileName = (value: any) => {
    if (value) {
      return value.name;
    }
    return "";
  };

  const formatImmatriculation = (value: any) => {
    if (value) {
      if (value.length == 2 && !isClear) {
        value = value.concat("-");
      }
      if (value.length == 6 && !isClear) {
        value = value.concat("-");
      }

      return value.toUpperCase();
    }
    return "";
  };

  const getVehiculeDetail = async (immatriculation: string) => {
    setIsLoading(true);

    const response = await getVehiculeDetails(immatriculation);

    if (response.data) {
      setError(undefined);
      setValues((prevValues: any) => ({
        ...prevValues,
        marque: response.data.AWN_modele + " " + response.data.AWN_marque,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        modele: response.data.AWN_modele_etude,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        couleur: response.data.AWN_couleur,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        nombre_places_passagers: response.data.AWN_nbr_de_places - 1,
      }));
      setValues((prevValues: any) => ({
        ...prevValues,
        date_premiere_circulation: response.data.AWN_date_mise_en_circulation
          ? new Date(
              response.data.AWN_date_mise_en_circulation.split("-")
                .reverse()
                .join("-")
            ).toLocaleDateString("en-CA")
          : "",
      }));

      setNotEditable(true);
    } else {
      setNotEditable(false);
      setError(response.message);
    }
    setIsLoading(false);
  };

  const handleImmatriculation = (event: any) => {
    handleChange(event);
    let numero = formatImmatriculation(event.target.value);

    setValues((prevValues: any) => ({
      ...prevValues,
      immatriculation: numero,
    }));
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode == 8 || event.keyCode == 46) {
      setIsClear(true);
    } else {
      setIsClear(false);
    }
  };

  useEffect(() => {
    if (!errors.immatriculation && values.immatriculation) {
      getVehiculeDetail(values.immatriculation);
    }
  }, [values.immatriculation, errors.immatriculation]);

  return (
    <form className="mt-5 lg:grid grid-cols-2 gap-12 ">
      {/* gauche */}
      <div className="space-y-2 ">
        <Input
          label="Immatriculation"
          name="immatriculation"
          type="text"
          required
          onChange={handleImmatriculation}
          onBlur={handleBlur}
          value={values.immatriculation}
          error={errors.immatriculation}
          touched={touched.immatriculation}
          onKeyDown={handleKeyDown}
          bgColor="bg-gray-300 border-gray-300"
        />
        <Input
          label="Marque"
          name="marque"
          type="text"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.marque}
          error={errors.marque}
          touched={touched.marque}
          bgColor="bg-gray-300 border-gray-300"
        />
        <Input
          label="Modèle"
          name="modele"
          type="text"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.modele}
          error={errors.modele}
          touched={touched.modele}
          bgColor="bg-gray-300 border-gray-300"
        />

        <Input
          label="Couleur"
          name="couleur"
          type="text"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.couleur}
          error={errors.couleur}
          touched={touched.couleur}
          bgColor="bg-gray-300 border-gray-300"
        />

        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-1 ">
            <Input
              label={<div className="h-8">Nombre de places passagers</div>}
              type="number"
              name="nombre_places_passagers"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.nombre_places_passagers}
              error={errors.nombre_places_passagers}
              touched={touched.nombre_places_passagers}
              bgColor="bg-gray-300 border-gray-300"
            />
          </div>
          <div className="col-span-1 ">
            <Input
              label={
                <div className="h-8">Nombre de bagages transportables</div>
              }
              type="number"
              name="nombre_bagages_transportables"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.nombre_bagages_transportables}
              error={errors.nombre_bagages_transportables}
              touched={touched.nombre_bagages_transportables}
              bgColor="bg-gray-300 border-gray-300"
            />
          </div>
        </div>
      </div>

      {/* droite */}
      <div className="space-y-2 lg:mt-0 md:mt-0 mt-4 ">
        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-1">
            <Input
              label={<div>Nombre de réhausseurs enfants</div>}
              type="number"
              name="nombre_rehausseurs_enfants"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.nombre_rehausseurs_enfants}
              error={errors.nombre_rehausseurs_enfants}
              touched={touched.nombre_rehausseurs_enfants}
              bgColor="bg-gray-300 border-gray-300"
            />
          </div>
          <div className="col-span-1">
            <Input
              label={
                <div className="h-10 lg:h-5 md:h-5">Nombre de sièges bébé</div>
              }
              type="number"
              name="nombre_sieges_bebe"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.nombre_sieges_bebe}
              error={errors.nombre_sieges_bebe}
              touched={touched.nombre_sieges_bebe}
              bgColor="bg-gray-300 border-gray-300"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-1">
            <Input
              label={<div>Date de première circulation</div>}
              type="date"
              name="date_premiere_circulation"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.date_premiere_circulation}
              error={errors.date_premiere_circulation}
              touched={touched.date_premiere_circulation}
              bgColor="bg-gray-300 border-gray-300"
            />
          </div>
          <div className="col-span-1">
            <InputFile
              label={
                <div className="h-10 lg:h-5 md:h-5">Document carte grise</div>
              }
              rightIcon={<Edit2 size={15} />}
              id="carte_grise"
              name="carte_grise"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={getFileName(values.carte_grise)}
              error={errors.carte_grise}
              touched={touched.carte_grise}
              bgColor="bg-gray-300 border-gray-300"
              noShadow
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-1">
            <Input
              label={<div>Date de fin du contrôle technique</div>}
              type="date"
              name="date_fin_controle_technique"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.date_fin_controle_technique}
              error={errors.date_fin_controle_technique}
              touched={touched.date_fin_controle_technique}
              bgColor="bg-gray-300 border-gray-300"
            />
          </div>
          <div className="col-span-1">
            <InputFile
              label={<div className="h-10">Document contrôle technique</div>}
              rightIcon={<Edit2 size={15} />}
              id="controle_technique"
              name="controle_technique"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={getFileName(values.controle_technique)}
              error={errors.controle_technique}
              touched={touched.controle_technique}
              bgColor="bg-gray-300 border-gray-300"
              noShadow
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-3">
          <div className="col-span-1">
            <Input
              label={
                <div className="h-8">Date de fin du contrat d’assurance</div>
              }
              type="date"
              name="date_fin_contrat_assurance"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.date_fin_contrat_assurance}
              error={errors.date_fin_contrat_assurance}
              touched={touched.date_fin_contrat_assurance}
              bgColor="bg-gray-300 border-gray-300"
              noShadow
            />
          </div>
          <div className="col-span-1">
            <InputFile
              label={<div className="h-8">Document d'assurance</div>}
              rightIcon={<Edit2 size={15} />}
              id="document_assurance"
              name="document_assurance"
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={getFileName(values.document_assurance)}
              error={errors.document_assurance}
              touched={touched.document_assurance}
              bgColor="bg-gray-300 border-gray-300"
              noShadow
            />
          </div>
        </div>

        {type_usage === "TAXI_VTC" && (
          <div className="mt-4">
            <InputFile
              label="Certificat d'acception au registre des vtc/taxi"
              rightIcon={<Edit2 size={15} />}
              id="certificat_acception"
              name="certificat_acception"
              required={type_usage === "TAXI_VTC"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={getFileName(values.certificat_acception)}
              error={errors.certificat_acception}
              touched={touched.certificat_acception}
              bgColor="bg-gray-300 border-gray-300"
            />
          </div>
        )}

        <div className="flex justify-center pt-5">
          {/* <Button
            disabled={pristine || !valid || submitting}
            onClick={handleSubmit(onsubmit)}
            addStyle="text-black h-12 bg-orange-belee w-fit px-8 border border-orange-belee font-medium rounded-md hover:bg-dark-green-belee bg-opacity-10"
          >
            Ajouter
          </Button>
 */}
          <button
            className="w-[150px] text-sm md:text-lg lg:text-lg font-base md:font-medium lg:font-medium text-white rounded-full bg-orange-belee py-3 shadow-xl hover:shadow-none"
            onClick={handleSubmit(onsubmit)}
          >
            Valider
          </button>
        </div>
      </div>

      <Modal
        isShow={isShowError}
        closeModal={() => setIshowError(false)}
        onClose={setIshowError}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-16 h-16 bg-red-400 text-white rounded-full">
            <Info size={35} />
          </div>

          <p className="text-sm text-red-500 mt-5">{error}</p>
        </div>
      </Modal>

      {/* <Modal
        isShow={isShow}
        closeModal={() => setIshow(false)}
        onClose={setIshow}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="flex justify-center items-center w-16 h-16 bg-white text-green-belee rounded-full">
            <Check size={35} />
          </div>
          <p className="text-xl mt-2">
            Merci d’avoir fourni le nécessaire pour la validation de votre
            véhicule Nous vérifions au plus vite.
          </p>
        </div>
      </Modal> */}
    </form>
  );
};

export default VehiculeActionForm;
