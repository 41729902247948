import { Service } from "enums";
import { useAuth } from "hooks";
import { MenuType } from "interfaces";
import { useState } from "react";
import { ChevronDown, ChevronRight } from "react-feather";
import { useNavigate } from "react-router-dom";

type Props = {
  menu: MenuType;
  addClass?: string;
};

const MenuItem = ({ menu, addClass }: Props) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [showedChild, setShowedChild] = useState<MenuType | undefined>();
  const handleMenuClick = () => {
    if (menu.child) {
      if (showedChild) {
        setShowedChild(undefined);
      } else {
        setShowedChild(menu);
      }
    } else {
      if (menu.text === "Déconnexion") {
        logout();
        window.location.href = "/belee";
        return;
      } else {
        navigate(menu.link);
      }
    }
  };
  return (
    <div>
      <div
        className={`grid grid-cols-12 justify-center items-center cursor-pointer ${
          showedChild && showedChild.id === menu.id
            ? "border border-green-belee h-12 bg-[#212122] -ml-3 rounded-lg px-2"
            : ""
        } ${menu.icon ? "space-x-8" : ""}  `}
        onClick={handleMenuClick}
      >
        <div className="col-span-2">{menu.icon && menu.icon}</div>
        <div
          className={`text-[#6d6d6d] text-md col-span-10 items-center hover:text-orange-belee ${
            menu.child ? "grid grid-cols-12" : ""
          } `}
        >
          <p className={`${menu.child ? "col-span-10" : "w-full"}`}>
            {menu.text}
          </p>
          {menu.child && (
            <>
              {showedChild ? (
                <ChevronDown
                  size={18}
                  onClick={() => setShowedChild(undefined)}
                />
              ) : (
                <ChevronRight size={18} onClick={() => setShowedChild(menu)} />
              )}
            </>
          )}
        </div>
      </div>
      <div
        className={` ${
          showedChild && showedChild.id === menu.id
            ? "flex transition-all"
            : "hidden"
        }  flex-col space-y-2 mt-3 pl-[60px]`}
      >
        {showedChild &&
          showedChild.child &&
          showedChild.child.map((item) => (
            <div
              key={item.id}
              className="cursor-pointer hover:text-orang-belee"
              onClick={() => navigate(item.link)}
            >
              {item.text}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MenuItem;
