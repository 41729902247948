import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotRequireAuth, RequireAuth } from "routes";
import { AccountRoutes } from "./AccountRoutes";

const FeatureAuth = {
  // HomePage: React.lazy(() => import("pages/Accounts/views/HomePage")),
  HomePage: React.lazy(() => import("pages/Accounts/views/LoginPage")),
  LoginPage: React.lazy(() => import("pages/Accounts/views/LoginPage")),
  RegisterPage: React.lazy(() => import("pages/Accounts/views/RegisterPage")),
  RegisterUserPage: React.lazy(
    () => import("pages/Accounts/views/RegisterUserPage")
  ),
  ResendValidation: React.lazy(
    () => import("pages/Accounts/views/ResendCodeValidationWithNewParamsPage")
  ),

  ChoiceAccountPage: React.lazy(
    () => import("pages/Accounts/views/ChoiceAccountPage")
  ),
  VerifyPage: React.lazy(() => import("pages/Accounts/views/VerifyPage")),
  SuccessPage: React.lazy(() => import("pages/Accounts/views/SuccessPage")),
  ResetPasswordPage: React.lazy(
    () => import("pages/Accounts/views/ResetPasswordPage")
  ),
  VerifyResetPassPage: React.lazy(
    () => import("pages/Accounts/views/VerifyResetPassPage")
  ),
  NewPassPage: React.lazy(() => import("pages/Accounts/views/NewPassPage")),
  SuccedNewPassPage: React.lazy(
    () => import("pages/Accounts/views/SuccedNewPassPage")
  ),
  InformationEntreprise: React.lazy(
    () => import("pages/Accounts/views/InformationEntreprise")
  ),
  GuideInstallationAdroidPage: React.lazy(
    () => import("pages/Accounts/views/GuideInstallationAndroidPage")
  ),
  GuideInstallationIPhonePage: React.lazy(
    () => import("pages/Accounts/views/GuideInstallationIPhonePage")
  ),
  InfoEntrepriseSaved: React.lazy(
    () => import("pages/Accounts/views/InfoEntrepriseSavedPage")
  ),
  ConfirmerInvitationPage: React.lazy(
    () => import("pages/Accounts/views/ConfirmerInvitationPage")
  ),
  ConfirmationInvitationPage: React.lazy(
    () => import("pages/Accounts/views/ConfirmationInvitationPage")
  ),

  toUpdateEntreprise: React.lazy(
    () => import("pages/Accounts/views/ModifierEntreprise")
  ),
  toEntrepriseValidated: React.lazy(
    () => import("pages/Accounts/views/EntrepriseValidatedPage")
  ),
  NotificationActivationInvitationPage: React.lazy(
    () => import("pages/Accounts/views/NotificationActivationInvitationPage")
  ),
  toNotification: React.lazy(
    () => import("pages/Accounts/views/NotificationPage")
  ),
  CreateEntreprisePage: React.lazy(
    () => import("pages/Accounts/views/CreateEntreprisePage")
  ),
};

export const AccountAppRoutes = () => (
  <Routes>
    <Route key={"AccountRoutes"} element={<NotRequireAuth />}>
      <Route path={AccountRoutes.toHome()} element={<FeatureAuth.HomePage />} />
      <Route
        path={AccountRoutes.toLogin()}
        element={<FeatureAuth.LoginPage />}
      />
      <Route
        path={AccountRoutes.toRegister()}
        element={<FeatureAuth.RegisterPage />}
      />
      <Route
        path={AccountRoutes.toResendValidation()}
        element={<FeatureAuth.ResendValidation />}
      />
      <Route
        path={AccountRoutes.toRegisterUser()}
        element={<FeatureAuth.RegisterUserPage />}
      />
      <Route
        path={AccountRoutes.toChoiceAccount()}
        element={<FeatureAuth.ChoiceAccountPage />}
      />
      <Route
        path={AccountRoutes.toVerify()}
        element={<FeatureAuth.VerifyPage />}
      />
      <Route
        path={AccountRoutes.toSuccess()}
        element={<FeatureAuth.SuccessPage />}
      />
      <Route
        path={AccountRoutes.toResetPassword()}
        element={<FeatureAuth.ResetPasswordPage />}
      />
      <Route
        path={AccountRoutes.toVerifyResetPass()}
        element={<FeatureAuth.VerifyResetPassPage />}
      />
      <Route
        path={AccountRoutes.toNewPassword()}
        element={<FeatureAuth.NewPassPage />}
      />
      <Route
        path={AccountRoutes.toNewPassSuccess()}
        element={<FeatureAuth.SuccedNewPassPage />}
      />
      <Route
        path={AccountRoutes.toInfoEntreprise()}
        element={<FeatureAuth.InformationEntreprise />}
      />
      <Route
        path={AccountRoutes.toCreateEntreprise()}
        element={<FeatureAuth.CreateEntreprisePage />}
      />
      <Route
        path={AccountRoutes.toUpdateEntreprise()}
        element={<FeatureAuth.toUpdateEntreprise />}
      />
      <Route
        path={AccountRoutes.toConfirmerInvitation()}
        element={<FeatureAuth.ConfirmerInvitationPage />}
      />
      <Route
        path={AccountRoutes.toConfirmationInvitation()}
        element={<FeatureAuth.ConfirmationInvitationPage />}
      />
      <Route
        path={AccountRoutes.toGuideInstallationAndroid()}
        element={<FeatureAuth.GuideInstallationAdroidPage />}
      />
      <Route
        path={AccountRoutes.toGuideInstallationIPhonePage()}
        element={<FeatureAuth.GuideInstallationIPhonePage />}
      />
    </Route>

    <Route key={"CompteRoutes"} element={<RequireAuth />}>
      <Route
        path={AccountRoutes.toInfoEntrepriseSaved()}
        element={<FeatureAuth.InfoEntrepriseSaved />}
      />
      <Route
        path={AccountRoutes.toEntrepriseValidated()}
        element={<FeatureAuth.toEntrepriseValidated />}
      />
      <Route
        path={AccountRoutes.toNotificationActivationInvitation()}
        element={<FeatureAuth.NotificationActivationInvitationPage />}
      />
      <Route
        path={AccountRoutes.toNotification()}
        element={<FeatureAuth.toNotification />}
      />
    </Route>
  </Routes>
);
