import { client } from "./client";

export const getAgendaAPI = async (userId: any) => {
  let err = "";
  try {
    const response = await client.get("/agenda/get/" + userId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};
