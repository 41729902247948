import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { BanqueRoutes } from "./BanqueRoutes";

const FeatureBanque = {
  BanquePage: React.lazy(() => import("pages/Banques/views/BanquePage")),
  HistoriquePage: React.lazy(
    () => import("pages/Banques/views/HistoriquePage")
  ),
  MesSoldesPage: React.lazy(() => import("pages/Banques/views/MesSoldesPage")),
};

export const BanqueAppRoutes = () => (
  <Routes>
    <Route key={"BanqueRoutes"} element={<RequireAuth />}>
      <Route
        path={BanqueRoutes.toBanquePage()}
        element={<FeatureBanque.BanquePage />}
      />
      <Route
        path={BanqueRoutes.toHistoriquePage()}
        element={<FeatureBanque.HistoriquePage />}
      />
      <Route
        path={BanqueRoutes.toMesSoldesPage()}
        element={<FeatureBanque.MesSoldesPage />}
      />
    </Route>
  </Routes>
);
