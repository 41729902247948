export const AccueilRoutes = {
  toHomePage: (): string => "/",
  toBloquer: (): string => "/bloquer/acces-refuser",
  toHomePageClient: (): string => "/client",
  toSaveHomePage: (): string => "/save-home",
  toSaisirAdresse: (type?: string): string =>
    type ? "/cmd-address/" + type : "cmd-address/:type",
  toSaisirHeure: (): string => "/cmd-heure",
  toSaisirTrajet: (): string => "/cmd-trajet",
  toInformationClient: (commandeId?: string): string =>
    commandeId
      ? "/cmd-info-client/" + commandeId
      : "/cmd-info-client/:commandeId",
  toModeDiffusion: (commandeId?: string): string =>
    commandeId
      ? "/cmd-mode-diffusion/" + commandeId
      : "/cmd-mode-diffusion/:commandeId",
  toSearchDriver: (type?: string): string =>
    type ? "/cmd-search-driver/" + type : "/cmd-search-driver/:type",
  toSearchDriverImmediat: (
    commandeId?: string,
    notificationId?: string
  ): string =>
    commandeId && notificationId
      ? "/cmd-search-driver/immediat/" + commandeId + "/" + notificationId
      : "/cmd-search-driver/immediat/:commandeId/:notificationId",
  toCmdAcceptedByDriver: (
    commandeId?: string,
    notificationId?: string
  ): string =>
    commandeId && notificationId
      ? "/cmd-accepted/" + commandeId + "/" + notificationId
      : "/cmd-accepted/:commandeId/:notificationId",
  toTrajetEnCours: (commandeId?: string): string =>
    commandeId
      ? "/cmd-trajet/en-cours/" + commandeId
      : "/cmd-trajet/en-cours/:commandeId",
  toChoiceGroupes: (): string => "/cmd-choice-groupes",
  toChoiceService: (): string => "/cmd-choice-service",
  toBlocageManuel: (): string => "/blocage",
  toCommandeAnnuler: (commandeId?: string): string =>
    commandeId ? "/cmd-annuler/" + commandeId : "/cmd-annuler/:commandeId",
  toCommandeAbandonner: (commandeId?: string, userId?: string): string =>
    commandeId && userId
      ? "/cmd/abandonner/" + commandeId + "/" + userId
      : "/cmd/abandonner/:commandeId/:userId",
};
