const BanqueIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="23" height="23" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_787_2553" transform="scale(0.0104167)" />
        </pattern>
        <image
          id="image0_787_2553"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAADeUlEQVR4nO3cO2gUURTG8e+oCBIVLFQsopjKRsRY2fho7GwEW0stLCwNVpZRK4sgKlhYiFgJVoIgEUQIiYIPtFETRUULRfCBmuRvMRHDotnMzsyeubPn1y65e+/5cnfunt1ZKYQQQgghhBBqDtgAXANuABu959MzAAMOA5/56wtwHFjqPb9GA7YC9/i/CWCH9zwbB1gBnAR+LFD8P34BZ4E+73k3ArAbeLqIwrd6Duzznn+ygDXAeWC2g+LPdw1Y672epAAHgQ8FCz/fR7ILt3mvrdaAAeBmiYVvNQps8V5n7QDLgGNkx8mqfSe7oC/3XnctAIPAeBcK3+ohsNN7/W6APmAYmHYo/h+zZBf61d716CpgP/DKsfCt3gKHvOtSOf72b+qqmX0l/t2/qatm9ZVo37+pq7T7SuTr39RVmn0lOu/f1FXlfaUlZQzCXP9G0m1JTXrHOSDpJhX2lQr3SICDkkYkNb3x9UnSkKSLZkZZg3YcADAg6ZykXmv93pF0xMyelTFY7pcg5vo3kh6q94ovSbskPaCkvlKuHQAMSrogKd1jWrkeKdsN9zodYFE7gLn+jaQxRfHn2yrpLgX6Sm13ALBf2UW2v5Mn6CHvJA2Z2eU8f7SYAEq74vcCM8v1sl7K+4DQuQjAWQTgLAJwFgE4iwCcRQAhhBCCkzI+EcvVK8rbK0l9/HbiFOQsAnAWATiLAJxFAM4iAGcRgLNl3hMo6KekW5KuS3oiaTLvAGWf67su77ddSxr/FzACrKtiTUlxCOAlsK2KtSSpywGMxX99iy4GMAmsr2INnlI5Bc1IOmBm770nUrZUArhkZve9J1GFFNrR05I2mdmbXBNLRAo7YLRd8YHVwBmym8CL/sxNlWaBKeAUsEpKYwccNbORBR5fqeyule15xq2BCUm7U9gBj9s8fkLpFV/K7rMYSmEH9JvZ6wUefyFpc54xa+R57QNYxPNPS0r15wVmUngJaifV4kvS0iYEkLQIwFnqnwck38+PHeAsAnAWAYQQQghOav9bEe3O+d7zKypOQc4iAGcRgLMIwFkE4CwCcBYBOIsAnEUAziIAZxGAs6Q/T5WiFxQKigCcNSGAGe8JFNCIryZOeU+ggMkmBHDVewIFXGnCKahP2Q0ag95zyWlcidygsSAz+yppj6TTyl6O6nwsRdkchyXtNbNvvwFZIRHtG8Wj6QAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  );
};

export default BanqueIcon;
