import { ReactNode } from "react";
export enum AlertKind {
  success = "bg-green-500",
  warning = "bg-orange-700",
  info = "bg-blue-400",
  error = "bg-red-500",
}
type AlertProps = {
  children: ReactNode;
  kind: AlertKind;
};

const Alert = ({ children, kind }: AlertProps) => {
  return (
    <div className={`${kind} my-4 p-3 rounded-md`}>
      <p className="text-sm text-white text-center">{children}</p>
    </div>
  );
};

export default Alert;
