import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { AccueilRoutes } from "./AccueilRoutes";

const FeatureAccueil = {
  HomePage: React.lazy(() => import("pages/Accueil/views/HomePage")),
  HomePageClient: React.lazy(
    () => import("pages/Accueil/views/HomePageClient")
  ),
  SaveHomePage: React.lazy(() => import("pages/Accueil/views/SaveOldHomePage")),
  CmdSaisirAdressePage: React.lazy(
    () => import("pages/Accueil/views/CmdSaisirAdressePage")
  ),
  CmdSaisirHeurePage: React.lazy(
    () => import("pages/Accueil/views/CmdSaisirHeurePage")
  ),
  CmdAddTrajetPage: React.lazy(
    () => import("pages/Accueil/views/CmdAddTrajetPage")
  ),
  CmdInformationClient: React.lazy(
    () => import("pages/Accueil/views/CmdInformationClient")
  ),
  CmdModeDeDiffusion: React.lazy(
    () => import("pages/Accueil/views/CmdModeDeDiffusion")
  ),
  CmdSearchDriverPage: React.lazy(
    () => import("pages/Accueil/views/CmdSearchDriverPage")
  ),
  CmdDriverForImmediatPage: React.lazy(
    () => import("pages/Accueil/views/CmdDriverForImmediatPage")
  ),
  CmdTrajetEnCours: React.lazy(
    () => import("pages/Accueil/views/CmdTrajetEnCours")
  ),
  CmdChoiceGroupes: React.lazy(
    () => import("pages/Accueil/views/CmdChoiceGroupes")
  ),
  CmdAcceptedByDriver: React.lazy(
    () => import("pages/Accueil/views/CmdAcceptedByDriverPage")
  ),
  BlocageManuelPage: React.lazy(
    () => import("pages/Accueil/views/BlocageManuelPage")
  ),
  CmdChoiceServicePage: React.lazy(
    () => import("pages/Accueil/views/CmdChoiceServicePage")
  ),
  CmdAnnulerCoteClientPage: React.lazy(
    () => import("pages/Accueil/views/CmdAnnulerParPrestataire")
  ),
  CommandeAbandonneePage: React.lazy(
    () => import("pages/Accueil/views/CommandeAbandonneePage")
  ),
  BloquerPage: React.lazy(() => import("pages/Accueil/views/BloquerPage")),
};

export const AccueilAppRoutes = () => (
  <Routes>
    <Route key={"AccueilAppRoutes"} element={<RequireAuth />}>
      <Route
        path={AccueilRoutes.toHomePage()}
        element={<FeatureAccueil.HomePage />}
      />
      <Route
        path={AccueilRoutes.toSaveHomePage()}
        element={<FeatureAccueil.SaveHomePage />}
      />
      <Route
        path={AccueilRoutes.toSaisirAdresse()}
        element={<FeatureAccueil.CmdSaisirAdressePage />}
      />
      <Route
        path={AccueilRoutes.toSaisirHeure()}
        element={<FeatureAccueil.CmdSaisirHeurePage />}
      />
      <Route
        path={AccueilRoutes.toSaisirTrajet()}
        element={<FeatureAccueil.CmdAddTrajetPage />}
      />
      <Route
        path={AccueilRoutes.toInformationClient()}
        element={<FeatureAccueil.CmdInformationClient />}
      />
      <Route
        path={AccueilRoutes.toModeDiffusion()}
        element={<FeatureAccueil.CmdModeDeDiffusion />}
      />
      <Route
        path={AccueilRoutes.toSearchDriver()}
        element={<FeatureAccueil.CmdSearchDriverPage />}
      />
      <Route
        path={AccueilRoutes.toSearchDriverImmediat()}
        element={<FeatureAccueil.CmdDriverForImmediatPage />}
      />
      <Route
        path={AccueilRoutes.toTrajetEnCours()}
        element={<FeatureAccueil.CmdTrajetEnCours />}
      />
      <Route
        path={AccueilRoutes.toChoiceGroupes()}
        element={<FeatureAccueil.CmdChoiceGroupes />}
      />
      <Route
        path={AccueilRoutes.toCmdAcceptedByDriver()}
        element={<FeatureAccueil.CmdAcceptedByDriver />}
      />
      <Route
        path={AccueilRoutes.toBlocageManuel()}
        element={<FeatureAccueil.BlocageManuelPage />}
      />
      <Route
        path={AccueilRoutes.toChoiceService()}
        element={<FeatureAccueil.CmdChoiceServicePage />}
      />
      <Route
        path={AccueilRoutes.toHomePageClient()}
        element={<FeatureAccueil.HomePageClient />}
      />
      <Route
        path={AccueilRoutes.toCommandeAnnuler()}
        element={<FeatureAccueil.CmdAnnulerCoteClientPage />}
      />
      <Route
        path={AccueilRoutes.toCommandeAbandonner()}
        element={<FeatureAccueil.CommandeAbandonneePage />}
      />
      <Route
        path={AccueilRoutes.toBloquer()}
        element={<FeatureAccueil.BloquerPage />}
      />
    </Route>
  </Routes>
);
