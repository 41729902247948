import { VehiculeData } from "api";
import { VehiculeType } from "interfaces/VehiculeType";
import { client, clientFile } from "./client";

export const createVehicule = async (slug: string, data: VehiculeData) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post(
      "/" + slug + "/voiture/create",
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getAllVehiculeAPI = async (
  entrepriseId: string,
  filter?: string,
  keyWord?: string
): Promise<VehiculeType[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/voitures?filter=" +
        filter +
        "&keyWord=" +
        keyWord +
        "&entrepriseId=" +
        entrepriseId
    );
    if (response.status === 200) {
      return response.data as VehiculeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};


export const getAllVehiculeEntrepriseAPI = async (
  entrepriseId: string,
  filter?: string,
  keyWord?: string
): Promise<VehiculeType[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/voitures/listes?filter=" +
        filter +
        "&keyWord=" +
        keyWord +
        "&entrepriseId=" +
        entrepriseId
    );
    if (response.status === 200) {
      return response.data as VehiculeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getVehiculeDetails = async (
  vehiculeId: string
): Promise<VehiculeType | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/voiture/liste/" + vehiculeId
    );
    if (response.status === 200) {
      return response.data as VehiculeType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const asignDriverVehicule = async (
  vehiculeId: string,
  userId: string
): Promise<VehiculeType | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/driver/assignation/" + vehiculeId + "/" + userId
    );
    if (response.status === 200) {
      return response.data as VehiculeType;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getDriverByVehiculeAPI = async (
  vehiculeId: string
): Promise<VehiculeType | string> => {
  let err = "";
  try {
    const response = await client.get("/driver/vehicule/" + vehiculeId);
    if (response.status === 200) {
      return response.data as any;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};


export const getVehiculeByDriver = async (
  userId: string
): Promise<VehiculeType | string> => {
  let err = "";
  try {
    const response = await client.get("/driver/user/" + userId);
    if (response.status === 200) {
      return response.data as any;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const LibererVehicule = async (vehiculeId: string) => {
  let err = "";
  try {
    const response = await client.get("/driver/unlink/voiture/" + vehiculeId);
    if (response.status === 200) {
      return response.data as any;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

