import AppLayout from "components/layouts/AppLayout";
import { ReactNode } from "react";
import { Check } from "react-feather";
import { useNavigate } from "react-router-dom";
import { AccountRoutes } from "../AccountRoutes";
import { AccueilRoutes } from "pages/Accueil";
import FooterContent from "components/layouts/FooterContent";
import { useTranslation } from "react-i18next";

type Props = {
  title: ReactNode;
  button?: string;
};
const SuccessContent = ({ title, button }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  localStorage.setItem("premiereEntre", "1");
  return (
    <AppLayout bgColor="bg-home font-sans">
      <div className="bg-opacity-80 bg-white lg:mt-36 mt-28 lg:px-12 md:px-12 px-5 lg:py-12 py-5 lg:w-[500px] md:w-[500px] lg:mx-auto md:mx-auto mx-3 shadow-xl rounded-3xl ">
        <img
          src="/images/assets/checked.svg"
          className="lg:w-[150px] w-[100px] mx-auto"
          alt=""
        />
        <h1 className="text-gray-500 text-center text-lg">{title}</h1>

        <div className="flex justify-center py-8">
          <button
            className="px-8 font-semibold text-white rounded-full bg-orange-belee xl:px-6 lg:px-8 px-3 py-3 shadow-xl hover:shadow-none"
            onClick={() => navigate(AccountRoutes.toLogin())}
          >
            {t("successNewAccountPage.login")}
          </button>
        </div>
      </div>

      {/* <div className="hidden lg:block md:block text-black md:mt-10 mt-32 xl:fixed xl:bottom-0 xl:w-full">
        <FooterContent></FooterContent>
      </div> */}
    </AppLayout>
  );
};

export default SuccessContent;
