import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { EnvoyerRoutes } from "./EnvoyerRoutes";

const FeatureBanqueEnvoyer = {
  EnvoieHomePage: React.lazy(
    () => import("pages/Banques/Envoyer/views/EnvoieHomePage")
  ),
  AjoutBeneficiairePage: React.lazy(
    () => import("pages/Banques/Envoyer/views/AjoutBeneficiairePage")
  ),
  SaisirSommeEnvoiePage: React.lazy(
    () => import("pages/Banques/Envoyer/views/SaisirEnvoiePage")
  ),
  ChoixModePaiementPage: React.lazy(
    () => import("pages/Banques/Envoyer/views/ChoixModeEnvoiPage")
  ),
  EnvoieAvecCartePage: React.lazy(
    () => import("pages/Banques/Envoyer/views/EnvoieAvecCartePage")
  ),
};

export const EnvoyerAppRoutes = () => (
  <Routes>
    <Route key={"EnvoyerRoutes"} element={<RequireAuth />}>
      <Route
        path={EnvoyerRoutes.toEnvoyer()}
        element={<FeatureBanqueEnvoyer.EnvoieHomePage />}
      />
      <Route
        path={EnvoyerRoutes.toAjoutBeneficiaire()}
        element={<FeatureBanqueEnvoyer.AjoutBeneficiairePage />}
      />
      <Route
        path={EnvoyerRoutes.toSaisirEnvoiSomme()}
        element={<FeatureBanqueEnvoyer.SaisirSommeEnvoiePage />}
      />
      <Route
        path={EnvoyerRoutes.toChoiceModeEnvoi()}
        element={<FeatureBanqueEnvoyer.ChoixModePaiementPage />}
      />
      <Route
        path={EnvoyerRoutes.toEnvoieAvecCarte()}
        element={<FeatureBanqueEnvoyer.EnvoieAvecCartePage />}
      />
    </Route>
  </Routes>
);
