import { isValidateUser } from "helpers";
import { User, VehiculeType } from "interfaces";
import Cookies from "js-cookie";
import { REGEX } from "utils";
import {
  InvitationAPIResponse,
  signUpData,
  UpdateAddressData,
  UpdateAuthData,
  UserByRoleAPIResponse,
} from "./ApiInterfaces";
import { client, clientFile } from "./client";

export const getAuthUserApi = () => {};
export const logUserByPass = () => {};

export const signIn = async (
  email: string,
  password: string
): Promise<User | string> => {
  let errorResponse = {};
  try {
    const response = await client.post("/auth/signin", {
      email: email,
      password: password,
    });

    let today = new Date();
    today.setHours(
      today.getHours() + Number(response.data.accessToken.tokenExpiration)
    );

    if (response.status === 200 && response.data) {
      if (isValidateUser(response.data)) {
        Cookies.set("beleeJwt", response.data.accessToken, {
          expires: today,
          path: "/",
        });
      }
      return response.data as User;
    }
  } catch (error: any) {
    errorResponse = error.response.data.message;
  }
  return errorResponse as string;
};

export const signInPhone = async (phone: string): Promise<User | string> => {
  let errorResponse = {};
  try {
    const response = await client.post("/auth/signinphone", {
      phone: phone,
    });

    let today = new Date();
    today.setHours(
      today.getHours() + Number(response.data.accessToken.tokenExpiration)
    );

    if (response.status === 200 && response.data) {
      if (isValidateUser(response.data)) {
        Cookies.set("beleeJwt", response.data.accessToken, {
          expires: today,
          path: "/",
        });
      }
      return response.data as User;
    }
  } catch (error: any) {
    errorResponse = error.response.data.message;
  }
  return errorResponse as string;
};

export const getResetConfirmCode = async (email?: string, phone?: string) => {
  let err = "";
  let data: any = {};
  if (email && email.search(REGEX.PHONE_REGEXP) === -1) {
    data.email = email;
  } else if (email && email.search(REGEX.EMAIL_REGEXP) === -1) {
    data.phone = email;
  } else if (phone && phone.search(REGEX.EMAIL_REGEXP) === -1) {
    data.phone = phone;
  } else {
    data = {
      email: email,
      phone: phone,
    };
  }
  try {
    const response = await client.post(
      "/users/validation/send-code-validation",
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (e: any) {
    err = e.response.data.message;
  }
  return err as string;
};

export const verifyCode = async (code: string) => {
  let err = "";
  try {
    const response = await client.post(
      "/users/validation/verify-code-validation",
      { code: code }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const testAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/test");
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const retrieveValidation = async (id: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/users/validation/verify-code-validation/" + id
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const resetPassword = async (id: string, password: string) => {
  let err = "";
  try {
    const response = await client.post("/auth/reset-password/" + id, {
      password: password,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getUserByPhoneAPI = async (data: any) => {
  let err = "";
  try {
    const response = await client.get("/get/user/" + data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const signUp = async (data: signUpData) => {
  let err = "";
  try {
    const response = await client.post("/auth/signup", data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const resendCodeValidationWithNewParams = async (data: any) => {
  let err = "";
  try {
    const response = await client.post(
      "/users/validation/resend/new/verify-code-validation",
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const updateAuth = async (
  data: UpdateAuthData
): Promise<User | string> => {
  let err = "";
  try {
    const response = await client.post("/auth/update-profile", data);
    if (response.status === 200) {
      return response.data as User;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const updateAuthAddressApi = async (
  data: UpdateAddressData
): Promise<User | string> => {
  let err = "";
  try {
    const response = await client.post("/auth/update-address", data);
    if (response.status === 200) {
      return response.data as User;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const refreshAuthApi = async (): Promise<User | string> => {
  let err = "";
  try {
    const response = await client.get("/auth/refresh");
    if (response.status === 200) {
      let today = new Date();
      today.setHours(
        today.getHours() + Number(response.data.accessToken.tokenExpiration)
      );
      Cookies.set("beleeJwt", response.data.accessToken, {
        expires: today,
        path: "/",
      });
      return response.data as User;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllUsersByEntrepriseAPI = async (
  entrepriseId: string,
  filter?: string,
  keyWord?: string
): Promise<User[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/users/" +
        entrepriseId +
        "?filter=" +
        filter +
        "&keyWord=" +
        keyWord
    );
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getUserDetails = async (
  userId: string
): Promise<User | string> => {
  let err = "";
  try {
    const response = await client.get("/users/" + userId);
    if (response.status === 200) {
      return response.data as User;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const inviterUser = async (
  id: string,
  nom: string,
  prenom: string,
  email: string,
  phone: string,
  role: string
): Promise<string | InvitationAPIResponse> => {
  let errorResponse = "";
  try {
    const response = await client.post("/users/" + id + "/invite", {
      email: email,
      phone: phone,
      lastname: nom,
      firstname: prenom,
      role: role,
    });

    if (response.status === 200 && response.data) {
      return response.data as InvitationAPIResponse;
    }
  } catch (error: any) {
    errorResponse = error.response.data.message;
  }
  return errorResponse as string;
};

export const retrieveInvitedUser = async (id: string) => {
  let errorResponse = "";
  try {
    const response = await client.get("users/invitations/liste/" + id);
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error: any) {
    errorResponse = error.response.data;
  }
  return errorResponse as string;
};

export const confirmerInvitation = async (
  id: string,
  email: string,
  lastname: string,
  firstname: string,
  genre: string,
  password: string,
  phone: string,
  whatsapp: string
): Promise<User | string> => {
  let errorResponse = "";
  try {
    const response = await client.post("users/invitations/liste/" + id, {
      email: email,
      lastname: lastname,
      firstname: firstname,
      password: password,
      phone: phone,
      genre: genre,
      whatsapp: whatsapp,
    });
    if (response.status === 200 && response.data) {
      return response.data as User;
    }
  } catch (error: any) {
    errorResponse = error.response.data.message;
  }
  return errorResponse as string;
};

export const confirmerInvitationQrCode = async (
  email: string,
  lastname: string,
  firstname: string,
  genre: string,
  password: string,
  phone: string,
  entreprise: string,
  role: string,
  whatsapp: string
): Promise<User | string> => {
  let errorResponse = "";
  try {
    const response = await client.post(
      "users/entreprise/confirmation/invitation/",
      {
        email: email,
        lastname: lastname,
        firstname: firstname,
        password: password,
        phone: phone,
        genre: genre,
        entreprise: entreprise,
        role: role,
        whatsapp: whatsapp,
      }
    );
    if (response.status === 200 && response.data) {
      return response.data as User;
    }
  } catch (error: any) {
    errorResponse = error.response.data.message;
  }
  return errorResponse as string;
};

export const getAllPrestatairesAPI = async (
  entrepriseId: string,
  keyWord?: string
): Promise<User[] | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/prestataires/users/" + entrepriseId + "?keyWord=" + keyWord
    );
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setBeneficiaireAPI = async (
  beneficiaireId: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.post("/beneficiaire/set", {
      beneficiaire: beneficiaireId,
    });
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllBeneficiaireUserAPI = async (): Promise<any> => {
  let err = "";

  try {
    const response = await client.get("/beneficiaire/get");
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllFavorisUserAPI = async (): Promise<any> => {
  let err = "";

  try {
    const response = await client.get("/favoris/get");
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const deleteFavorisUserAPI = async (favorisId: any): Promise<any> => {
  let err = "";

  try {
    const response = await client.post("/favoris/delete", favorisId);
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setFavorisAPI = async (favorisId: string): Promise<any> => {
  let err = "";
  try {
    const response = await client.post("/favoris/set", {
      favoris: favorisId,
    });
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const searchAllBeneficiaireAvailableAPI = async (
  keyword: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/beneficiaire/search/" + keyword);
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const searchAllFavorisAvailableAPI = async (
  keyword: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/favoris/search/" + keyword);
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getUserBeleeWithServiceActiveRandomAPI =
  async (): Promise<any> => {
    let err = "";
    try {
      const response = await client.get("/favoris/user-randomly");
      if (response.status === 200) {
        return response.data as User[];
      } else {
        err = response.data.message;
        return err;
      }
    } catch (error: any) {
      err = error.response.data.message;
    }
    return err;
  };

export const getAllUserBeleeRandomAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/beneficiaire/user-randomly");
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllUserBeleeAPI = async (): Promise<User[] | string> => {
  let err = "";
  try {
    const response = await client.get("/users");
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllUserEntrepriseAPI = async (
  entrepriseId: string
): Promise<User[] | string> => {
  let err = "";
  try {
    const response = await client.get("/entreprise/users/get/" + entrepriseId);
    if (response.status === 200) {
      return response.data as User[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getUserEntrepriseByRoleApi = async (
  entrepriseId: string
): Promise<UserByRoleAPIResponse | string> => {
  let err = "";
  try {
    const response = await client.get(
      "/entreprise/users/roles/" + entrepriseId
    );
    if (response.status === 200) {
      return response.data as UserByRoleAPIResponse;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getUserVehiculeApi = async (
  userId: string
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.get("/driver/user/" + userId);
    if (response.status === 200) {
      return response.data as any;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getVehiculeCreatedByUserAPI = async (
  userId: string
): Promise<VehiculeType[] | string> => {
  let err = "";
  try {
    const response = await client.get("/entreprise/voitures/" + userId);
    if (response.status === 200) {
      return response.data as VehiculeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setPdpAPI = async (data: any) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await clientFile.post("/user/setpdp/", formData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const setUserOnLineAPI = async () => {
  let err = "";

  try {
    const response = await clientFile.post("/auth/user/connect/set/");
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const blockUserPayAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post("/user/block/must/pay/", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const blockUserAPI = async (data: any) => {
  let err = "";
  try {
    const response = await client.post("/prestataire/locked/", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getRaisonBloquageAPI = async (userId: string) => {
  let err = "";
  try {
    const response = await client.get("/prestataire/raison/blocage/" + userId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const checkMailPhoneAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post(
      "/auth/signup/site/check/isset/user/",
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const checkInfoUserMailPhoneAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post("/auth/check/isset/info-user", data);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const checkIfUserIsBlockedAPI = async () => {
  let err = "";

  try {
    const response = await client.get("/users/check/blocage");
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
