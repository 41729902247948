import { capitalizeFirstLetter } from "helpers";
import { User } from "interfaces";
import { EnumToStringArray } from "utils";

type Props = {
  user: User;
};
const ListItem = ({ user }: Props) => {
  return (
    <div className="px-1 text-xs lg:text-sm col-span-10 xs:col-span-11 grid grid-cols-10 xs:grid-cols-11 bg-orange-belee text-white justify-center items-center rounded-md xs:mr-3">
      <div className="col-span-2 xs:col-span-3 text-center ">
        {user.lastname}
      </div>
      <div className="col-span-2 text-center ">{user.firstname}</div>
      <div className="col-span-2 text-center  ">
        {EnumToStringArray.find((v) => v.key === user.etat)?.valeur}
      </div>
      <div className="col-span-2 text-center ">
        {capitalizeFirstLetter(user.statut.toLocaleLowerCase())}
      </div>
      <div className="col-span-2 text-center ">0</div>
    </div>
  );
};

export default ListItem;
