import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { RechargerRoutes } from ".";

const FeatureBanqueRecharger = {
  RechargerMonComptePage: React.lazy(
    () => import("pages/Banques/Recharger/views/RechargerMonComptePage")
  ),
  SaisirSommePage: React.lazy(
    () => import("pages/Banques/Recharger/views/SaisirSommePage")
  ),
  RechargerSoldeParCarte: React.lazy(
    () => import("pages/Banques/Recharger/views/RechargerSoldeParCartePage")
  ),
  IbanInfoBeleePage: React.lazy(
    () => import("pages/Banques/Recharger/views/IbanInfoBeleePage")
  ),
  ChoixRechargementVirement: React.lazy(
    () =>
      import("pages/Banques/Recharger/views/EtapeUnRechargementVirementPage")
  ),
  VirementEnAttentPage: React.lazy(
    () => import("pages/Banques/Recharger/views/IbanVirementEnAttentePage")
  ),
  InfoCartePaiement: React.lazy(
    () => import("pages/Banques/Recharger/views/InfoCartePaiementPage")
  ),
};

export const RechargerAppRoutes = () => (
  <Routes>
    <Route key={"RechargerRoutes"} element={<RequireAuth />}>
      <Route
        path={RechargerRoutes.toRecharger()}
        element={<FeatureBanqueRecharger.RechargerMonComptePage />}
      />
      <Route
        path={RechargerRoutes.toSaisirSomme()}
        element={<FeatureBanqueRecharger.SaisirSommePage />}
      />
      <Route
        path={RechargerRoutes.toRechargerSoldeParCarte()}
        element={<FeatureBanqueRecharger.RechargerSoldeParCarte />}
      />
      <Route
        path={RechargerRoutes.toGetInfoCartePaiement()}
        element={<FeatureBanqueRecharger.InfoCartePaiement />}
      />
      <Route
        path={RechargerRoutes.toInfoIbanBelee()}
        element={<FeatureBanqueRecharger.IbanInfoBeleePage />}
      />
      <Route
        path={RechargerRoutes.toChoixRechargementVirement()}
        element={<FeatureBanqueRecharger.ChoixRechargementVirement />}
      />
      <Route
        path={RechargerRoutes.toVirementIbanEnAttente()}
        element={<FeatureBanqueRecharger.VirementEnAttentPage />}
      />
    </Route>
  </Routes>
);
