import { CommandeRoutes } from "pages/Commande";
import { AccueilRoutes } from "../AccueilRoutes";

export const menuNavList = [
  {
    id: "1",
    name: "Consulter mes commandes",
    link: CommandeRoutes.toCommande(),
    isActive: true,
  },
  {
    id: "2",
    name: "Commander un article",
    link: "",
    isActive: false,
  },
  {
    id: "3",
    name: "Suivre mes livraisons",
    link: "",
    isActive: false,
  },
  // {
  //   id: "4",
  //   name: "Partenaire",
  //   link: "",
  //   isActive: false,
  // },
  // {
  //   id: "5",
  //   name: "À propos de Nous",
  //   link: "",
  //   isActive: false,
  // },
  // {
  //   id: "6",
  //   name: "Politique de confidentialité",
  //   link: "",
  //   isActive: false,
  // },
  // {
  //   id: "7",
  //   name: "CGU",
  //   link: "",
  //   isActive: false,
  // },
];
