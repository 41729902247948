import { PrixTrajetData } from "api";
import { GammeType, SupplementType } from "interfaces";
import { client } from "./client";

export const getAllSupplementAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/entreprise/voiture/supplement");
    if (response.status === 200) {
      return response.data as SupplementType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const saveConformiteVehiculeAPI = async (
  commandeId: string,
  conformite: string
) => {
  let err = "";
  let data = {
    conformite,
  };
  try {
    const response = await client.post(
      "/commande/save-conformite-vehicule/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePrestationSiJeSuisAvecAPI = async (
  commandeId: string,
  prestataireId: string
) => {
  let err = "";

  try {
    const response = await client.get(
      "/commande/save-commande/reset/to/" + commandeId + "/" + prestataireId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getCommandeForRatingAPI = async (commandeId: string) => {
  let err = "";
  try {
    const response = await client.get("/commande/for-rating/" + commandeId);
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getConformiteVehiculeAPI = async (commandeId: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/commande/get/conformite-vehicule/" + commandeId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllGammeAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/entreprise/voiture/gamme/");
    if (response.status === 200) {
      return response.data as GammeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getPrixTrajet = async (data: PrixTrajetData) => {
  let err = "";
  try {
    const response = await client.post("/zones/parametre/tarification", data);
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createCommande = async (data: any) => {
  let err = "";
  try {
    const response = await client.post("/commande/create", data);
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const saveInfoClient = async (commande_id: string, data: any) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/info-client/" + commande_id,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const saveDiffusionCommandeAPI = async (
  commande_id: string,
  data: any
) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/save-diffusion/" + commande_id,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const GetUserCommandeAPI = async (
  userId: string,
  filter?: string,
  dayFilter?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commande/list/user/all/commandes/" +
        userId +
        "?filter=" +
        filter +
        "&dayfilter=" +
        dayFilter
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getPrestationDetailAPI = async (
  prestationId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/prestation/detail/" + prestationId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getPrestationCashDetailAPI = async (
  prestationId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/prestation/cash/retrait/detail/" + prestationId
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getPrestationDetailPublicAPI = async (
  prestationId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/prestation/detail/public/" + prestationId
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const GetUserPrestationsAPI = async (
  userId: string,
  filter?: string,
  keyWord?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "prestations/user/listes/" +
        userId +
        "?filter=" +
        filter +
        "&keyWord=" +
        keyWord
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const SetPrestataireCommandeAPI = async (
  evolution: object,
  userId: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.post(
      "/prestations/setPrestataire/" + userId + "/" + commandeId,
      evolution
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const confirmClientAbsentAPI = async (
  evolution: object,
  userId: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.post(
      "/prestations/confirmClientAbsent/" + userId + "/" + commandeId,
      evolution
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const compteAReboursEcouleClientAbsentAPI = async (
  evolution: object,
  userId: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.post(
      "/prestations/temps-attente-ecoule/client-absent/" +
        userId +
        "/" +
        commandeId,
      evolution
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const ReSetPrestataireCommandeAPI = async (
  evolution: object,
  userId: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.post(
      "/prestations/reSetPrestataire/" + userId + "/" + commandeId,
      evolution
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getCommandeAPI = async (
  commande_id: any
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      commande_id ? "/commande/" + commande_id : "/commande/"
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllCommandeAPI = async (
  filter?: string,
  keyWord?: string,
  filterJour?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commandes/all/listes/?filter=" +
        filter?.toUpperCase() +
        "&keyWord=" +
        keyWord +
        "&filterJour=" +
        filterJour
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getCommandeCreatedAPI = async (
  commandeId: any
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/commande/created/" + commandeId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllPrestationUserAPI = async (
  userId: string,
  filter?: string,
  keyWord?: string,
  filterJour?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commandes/prestation/listes/" +
        userId +
        " ?filter=" +
        filter?.toUpperCase() +
        "&keyWord=" +
        keyWord +
        "&filterJour=" +
        filterJour
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllPrestation = async (
  userId: string,
  filter?: string,
  keyWord?: string,
  filterJour?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commandes/prestation/listes/all/" +
        userId +
        " ?filter=" +
        filter?.toUpperCase() +
        "&keyWord=" +
        keyWord +
        "&filterJour=" +
        filterJour
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getPrestationDisponibleAPI = async (
  filter?: string,
  keyWord?: string,
  filterJour?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/prestation/disponible/liste/?filter=" +
        filter?.toUpperCase() +
        "&keyWord=" +
        keyWord +
        "&filterJour=" +
        filterJour
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const saveInterrupionMessageAPI = async (
  userId: string,
  commande_id: string,
  message: object
) => {
  let err = "";
  try {
    const response = await client.post(
      "/prestation/interruption-message/" + userId + "/" + commande_id,
      message
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};

export const saveAnnulationMessageAPI = async (
  userId: string,
  commande_id: string,
  message: object
) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/annulation/" + userId + "/" + commande_id,
      message
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};

export const RembourserCommandePrepaidAPI = async (
  userId: string,
  commande_id: string
) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/rembourser/" + userId + "/" + commande_id
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};

export const getTarifCommissionAPI = async (
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commandes/prestation/tarif/commission/" + commandeId
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const saveLitigeAPI = async (
  commandeId: string,
  data: object
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.post(
      "/commande/save-litige/" + commandeId,
      data
    );

    if (response.status === 2000 || response.status === 201) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
};

export const checkCommissionPaidAPI = async (
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/prestation/check-commission/" + commandeId
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const checkPrestationEnCoursAPI = async (): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/check/prestations/en-cours");

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const saveNoteUserAndVehiculeCommandeAPI = async (
  notant: string,
  userId: string,
  vehiculeId: string,
  noteUser: number,
  noteVehicule: number,
  commandeId: string,
  commentaire: string
) => {
  let err = "";
  let data = {
    notant,
    userId,
    vehiculeId,
    noteUser,
    noteVehicule,
    commandeId,
    commentaire,
  };
  try {
    const response = await client.post("/commande/rating/user/vehicule", data);
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};

export const prestataireNeedToPayCommmission = async (commandeId: string) => {
  let err = "";
  try {
    const response = await client.post(
      "/prestataire/paie/commission/" + commandeId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};

export const retributionGainAuPrestataire = async (commandeId: string) => {
  let err = "";
  try {
    const response = await client.post(
      "/retribution/gain/prestataire/" + commandeId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};

export const setPrestataireCommandeCashAPI = async (
  commandeId: string,
  data: any
) => {
  let err = "";
  try {
    const response = await client.post(
      "/cash/prestation/set/prestataire/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};
