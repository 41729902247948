import BanqueIcon from "components/elements/icons/BanqueIcon";
import DollarIcon from "components/elements/icons/DollarIcon";
import MastercardIcon from "components/elements/icons/MastercardIcon";
import PaypalIcon from "components/elements/icons/PaypalIcon";
import { User } from "interfaces";

export const comptes = (user: User | null) => {
  return [
    {
      id: "14",
      type: "Carte bancaire",
      identifiant: "",
      icon: <MastercardIcon />,
    },
    {
      id: "13",
      type: "Paypal",
      identifiant: user && user.email,
      icon: <PaypalIcon />,
    },
    {
      id: "12",
      type: "Virement",
      identifiant: "",
      icon: <BanqueIcon />,
    },
  ];
};

export const modeEnvoi = (user: User | null) => {
  return [
    {
      id: "14",
      type: "Carte bancaire",
      identifiant: "",
      icon: <MastercardIcon />,
    },
    {
      id: "13",
      type: "Paypal",
      identifiant: user && user.email,
      icon: <PaypalIcon />,
    },
    {
      id: "12",
      type: "Solde",
      identifiant: "",
      icon: <BanqueIcon />,
    },
  ];
};

export const retraitChoice = (user: User | null) => {
  return [
    {
      id: "13",
      type: "Paypal",
      identifiant: user && user.email,
      icon: <PaypalIcon />,
    },
    {
      id: "14",
      type: "Virement",
      identifiant: "",
      icon: <BanqueIcon />,
    },
    {
      id: "15",
      type: "Par espèce",
      identifiant: "",
      icon: <DollarIcon />,
    },
  ];
};
