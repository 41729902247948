import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="flex items-center col-span-1">
      <img
        src="/images/icons/Icone langue FR.png"
        className={`object-cover rounded-full w-7 h-7 mr-1`}
        alt="icone langue"
        onClick={() => changeLanguage("fr")}
      />
      <img
        src="/images/icons/Icone langue ENG.png"
        className={`object-cover rounded-full w-7 h-7 mr-1`}
        alt="icone langue"
        onClick={() => changeLanguage("en")}
      />
      <img
        src="/images/icons/Icone langue ESP.png"
        className={`object-cover rounded-full w-7 h-7`}
        alt="icone langue"
        onClick={() => changeLanguage("esp")}
      />
    </div>
  );
};

export default LanguageSelector;
