import { CompteRoutes } from "pages/Compte/CompteRoutes";
import { UserRoutes } from "pages/Utilisateur/UserRoutes";
import { VehiculeRoutes } from "pages/Vehicule/VehiculeRoutes";
import { Bookmark, Home, Truck, Users } from "react-feather";
import ServiceDetails from "../components/ServiceDetails";
import VehiculeDetails from "../components/VehiculeDetails";
import { AccountRoutes } from "pages/Accounts";

let userHaveEntreprise = false,
  entrepriseValide = false;

let storeAuth = localStorage.getItem("bappauth");
if (storeAuth) {
  userHaveEntreprise = storeAuth ? JSON.parse(storeAuth).entreprise : null;

  let auth = JSON.parse(storeAuth);
  if (auth.entreprise) {
    if (auth.entreprise.slug === "belee") {
      userHaveEntreprise = false;
    }
  }
  // console.log(userHaveEntreprise.slug);

  if (userHaveEntreprise == true) {
    // entrepriseValide = userHaveEntreprise;
    if (entrepriseValide) {
    }
  } else {
    entrepriseValide = false;
  }
}

export const shorcutMenus = [
  {
    id: 8,
    title:
      userHaveEntreprise && userHaveEntreprise != null
        ? "Mon Entreprise ..."
        : "Mon Entreprise.",
    link:
      userHaveEntreprise && userHaveEntreprise != null
        ? AccountRoutes.toUpdateEntreprise()
        : AccountRoutes.toInfoEntreprise(),
    icon: <Home size={28} />,
  },
];

export const shorcutMenusEntrepriseValide = [
  {
    id: 8,
    title: userHaveEntreprise ? "Mon Entreprise!!" : "Mon Entreprise!",
    link: userHaveEntreprise
      ? AccountRoutes.toUpdateEntreprise()
      : AccountRoutes.toInfoEntreprise(),
    icon: <Home size={28} />,
  },
  {
    id: 11,
    title: "Véhicules",
    link: VehiculeRoutes.toAddActionUsageVehicule(),
    icon: <Truck size={28} />,
    details: <VehiculeDetails />,
  },
  {
    id: 10,
    title: "Activer un service",
    link: CompteRoutes.toMesService(),
    icon: <Bookmark size={28} />,
    details: <ServiceDetails />,
  },
  {
    id: 12,
    title: "Ajouter un utilisateur",
    link: UserRoutes.toAddUser(),
    icon: <Users size={28} />,
  },
];
