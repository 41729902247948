import MenuSpIcon from "components/elements/icons/MenuSpIcon";
import { AccueilRoutes } from "pages";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "components/elements/buttons/Button";
import Menu from "components/elements/menus/Menu";
import MenuNavBar from "pages/Accueil/components/MenuNavBarComponent";
import FooterContent from "./FooterContent";
import { useAuth } from "hooks";
import LanguageSelector from "./LanguageSelector";
import IconButton from "@material-tailwind/react/components/IconButton";

type Props = {
  children: ReactNode;
  bgColor?: string;
  noPadding?: boolean;
  spaceY?: string;
  addClass?: string;
  withMenu?: boolean;
  positionIcon?: string;
  showIcon?: boolean;
  bandeau?: boolean;
};
const AppLayout = ({
  children,
  bgColor = "belee-gradient-color",
  noPadding = false,
  spaceY,
  addClass,
  withMenu = false,
  positionIcon = "top-4",
  showIcon = true,
  bandeau = true,
}: Props) => {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { user } = useAuth();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useState(() => {
    let auth = localStorage.getItem("bappauth");
    if (user) {
      setIsAuthenticated(true);
    }
  });

  return (
    <div
      className={`w-full xs:w-[100%] sm:w-[100%]  relative flex flex-col min-h-screen mx-auto ${addClass} ${spaceY} ${
        noPadding ? "" : "px-0 xs:px-0"
      }  ${bgColor}`}
    >
      {bandeau && (
        <>
          {/* menubar left */}
          <Menu showMenu={showMenu} setShowMenu={setShowMenu} />

          {/* menubar mobile */}
          <div className="lg:hidden md:hidden">
            <div
              className={`fixed w-full ${
                user?.role === "ADMIN" || user?.role === "COLLABORATEUR"
                  ? "bg-[#000000]"
                  : "bg-gris-belee"
              } grid grid-cols-3 px-4 py-6 rounded-b-[30px] z-10`}
            >
              <LanguageSelector />

              {showIcon && showIcon ? (
                <div className="col-span-1 flex justify-center">
                  <img
                    src={`${
                      user?.role === "ADMIN" || user?.role === "COLLABORATEUR"
                        ? "/logo-pro-navbar.png"
                        : "/logo-pro-navbar.png"
                    }`}
                    className="relative bottom-1 w-[150px]"
                    alt="logo Belee"
                    onClick={() => navigate(AccueilRoutes.toHomePage())}
                  />
                </div>
              ) : (
                <div></div>
              )}

              {isAuthenticated && (
                <div className="col-span-1 flex justify-end">
                  <IconButton
                    placeholder={""}
                    variant="text"
                    // className="col-span-1 flex border-2 border-orange-belee px-7 items-center text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent text-orange-belee"
                    className="border-2 border-orange-belee text-orange-belee px-7 flex items-center justify-center"
                    ripple={false}
                    onClick={() => setShowMenu(true)}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    {showMenu ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        className="h-7 w-7"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-7 w-7"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h22M4 12h22M4 18h22"
                        />
                      </svg>
                    )}
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {/* fin menubar mobile */}
        </>
      )}

      <div className="lg:block md:block xl:block hidden">
        <MenuNavBar addClass="fixed"></MenuNavBar>
      </div>

      {children}

      <div className="hidden">
        <FooterContent></FooterContent>
      </div>
    </div>
  );
};

export default AppLayout;
