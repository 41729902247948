type Props = {
  choices: any[];
  addClasse?: string;
  selected?: string;
  setChoice: (choice: string) => void;
};
const ChoiceBox = ({ choices, addClasse, selected, setChoice }: Props) => {
  return (
    <div
      className={`flex justify-center items-center space-x-6 text-black ${addClasse}`}
    >
      {choices.map((choice) => (
        <div
          key={choice}
          className={`w-[500px] h-12  ${
            selected === choice
              ? "text-orange-belee border-4 border-orange-belee"
              : ""
          } flex justify-center items-center rounded-2xl cursor-pointer hover:text-orange-belee shadow-lg bg-white`}
          onClick={() => setChoice(choice)}
        >
          {choice}
        </div>
      ))}
    </div>
  );
};

export default ChoiceBox;
