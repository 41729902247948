import { capitalizeFirstLetter } from "helpers";
import { useUserServcie } from "hooks";
import { useEffect, useState } from "react";

const ServiceDetails = () => {
  const { activesServices } = useUserServcie();
  const [nombreService, setNombreService] = useState<number>();

  
  useEffect(() => {
    if (activesServices) {
      setNombreService(activesServices?.length);
    }
  }, [activesServices, nombreService]);

  return (
    <div className="flex flex-col px-3 space-y-2 text text-xs">
      {activesServices ? (
        activesServices.map((service) => (
          <div className="text text-sm">
            {/* {capitalizeFirstLetter(
              service.type_service.toLocaleLowerCase().replaceAll("_", " ")
            )} */}
            {nombreService == 0 ? (
              ""
            ) : (
              <p className="text-center">{nombreService} service activé </p>
            )}
          </div>
        ))
      ) : (
        <div>
          {nombreService == 0 || nombreService == undefined ? (
            <p className="text-center">Aucun service activé </p>
          ) : (
            <p className="text-center">{nombreService} service activé </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ServiceDetails;
